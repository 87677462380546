<template>
    <v-virtual-scroll :bench="8" :items="filteredConditions" :item-height="64" :max-height="416" v-tournoscroll>
        <template #default="{ item: condition }">
            <v-list-item :key="condition.id" @click="click(condition)" style="height: 64px">
                <v-list-item-action class="my-0 mr-3" id="tour-conditions-info">
                    <v-btn icon small color="primary" :class="{ hidden: !hasSnomed(condition) && !infoLink(condition) }" target="_blank" :href="infoLink(condition)" @click.stop="!infoLink(condition) && $root.$medlinePlus.show(condition)">
                        <v-icon small>fas fa-info-circle</v-icon>
                    </v-btn>
                </v-list-item-action>
                <v-list-item-content :class="{ disabled: !canAdd(person, condition) }">
                    <v-list-item-title class="primary--text">{{ $t(`conditions.${condition.id}.name`) }}</v-list-item-title>
                    <v-list-item-subtitle class="wrap" v-html="$t(`conditions.${condition.id}.hint`)"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text :class="{ disabled: !canAdd(person, condition) }" v-if="familyConditions[condition.id]">
                    <v-chip label small v-if="1 === familyConditions[condition.id].length">
                        {{ familyConditions[condition.id][0].nickname || $t(`relations.${familyConditions[condition.id][0].relation}`) }}
                    </v-chip>
                    <v-chip label small v-else-if="familyConditions[condition.id].length > 1">
                        {{ $tc('common.family_members', familyConditions[condition.id].length) }}
                    </v-chip>
                </v-list-item-action-text>
                <v-list-item-action class="my-0" v-if="canRemove(person, condition)">
                    <v-btn icon small color="primary" @click.stop="remove(person, findCondition(person, condition))"><v-icon small>fas fa-trash</v-icon></v-btn>
                </v-list-item-action>
                <v-list-item-action-text v-if="showScores">
                    <v-chip label small color="info">{{ condition.score }}</v-chip>
                </v-list-item-action-text>
            </v-list-item>
            <v-divider></v-divider>
        </template>
    </v-virtual-scroll>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';

    export default {
        mixins: [ConditionsMixin],
        props: {
            person: {
                type: Object,
                default: {}
            },
            familyConditions: {
                type: Object,
                default: {}
            },
            filteredConditions: {
                type: Array,
                default: []
            },
            showScores: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            click(condition) {
                if (this.canAdd(this.person, condition)) {
                    this.add(this.person, condition);
                }
                this.$emit('click', condition);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .wrap {
        white-space: normal;
    }
</style>
