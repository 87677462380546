<template>
    <v-card flat>
        <div class="my-6" v-if="error">
            <v-alert prominent outlined border="left" type="info">
                <v-row align="center">
                    <v-col class="grow">
                        <span v-html="$t('errors.riskProfile')"></span>
                    </v-col>
                    <v-col class="shrink">
                        <v-btn color="info" @click="load()">{{ $t('labels.tryAgain') }}</v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </div>
        <v-skeleton-loader v-if="!cds" :boilerplate="!!error" tile type="article@4"></v-skeleton-loader>
        <div v-if="none">
            <p>{{ $t('resource.none') }}</p>
        </div>
        <div class="d-flex align-center mb-3" v-else-if="filteredConditionIds.length > 0 || filteredCdsResources.length > 0">
            <v-spacer></v-spacer>
            <Hint :text="$t('hints.share')" :title="$t('labels.share')" class="mx-2"></Hint>
            <v-btn color="primary" @click="openShare()" id="tour-resource-share">
                <v-icon left small>fas fa-share</v-icon>
                {{ $t('labels.share') }}
            </v-btn>
        </div>
        <div v-if="filteredConditionIds && filteredConditionIds.length">
            <ConditionResource v-for="(conditionId, index) in filteredConditionIds" :key="index" :conditionId="conditionId" :public="false" class="mb-3" v-elclick="{ selector: 'a', fn: anchorClick }"></ConditionResource>
        </div>
        <div v-if="filteredCdsResources && filteredCdsResources.length">
            <template v-for="cdsResource in filteredCdsResources">
                <CdsResource v-for="(topic, index) in cdsResource.topics" :key="index" :topic="topic" class="mb-3" v-elclick="{ selector: 'a', fn: anchorClick }"></CdsResource>
            </template>
        </div>
        <v-dialog v-model="shareDialog" :max-width="400">
            <v-card v-if="person">
                <v-toolbar dense dark color="primary">
                    <v-toolbar-title>{{ $t('labels.share') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeShare()">
                        <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5 pb-0">
                    <v-text-field dense outlined v-model="shareEmail" :label="$t('fields.email')" :hint="$t('hints.shareEmail')" persistent-hint autofocus></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" :href="mailto" @click="shareEmailClick" :disabled="!isEmail(shareEmail)">
                        <v-icon left>fas fa-paper-plane</v-icon>
                        {{ $t('labels.generateEmail') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Tour :steps="tourSteps"></Tour>
    </v-card>
</template>

<script>
    import CdsResource from '@/components/CdsResource';
    import ConditionResource from '@/components/ConditionResource';
    import { cdsResources } from '@/common';
    import { ElementClick } from '@/directives';
    import { get } from 'vuex-pathify';

    import isEmail from 'validator/lib/isEmail';
    import { v4 as uuidv4 } from 'uuid';
    import { intersection, uniq } from 'lodash';

    export default {
        name: 'Resource',
        components: { CdsResource, ConditionResource },
        directives: {
            elclick: ElementClick
        },
        data: () => ({
            cdsResources,
            shareDialog: false,
            shareEmail: '',
            refid: null,
            cds: null,
            error: null
        }),
        computed: {
            person: get('family@0'),
            patient: get('patient/patient'),
            origin() {
                return window.location.origin;
            },
            shareLink() {
                return `${this.origin}/p/resource/${this.patient.hashid}/${this.refid}`;
            },
            mailto() {
                return `mailto:${this.shareEmail}?subject=${encodeURIComponent(this.$i18n.t('resource.share.subject'))}&body=${encodeURIComponent(this.$i18n.t('resource.share.body', { link: this.shareLink }))}`;
            },
            filteredConditionIds() {
                return uniq(this.person.conditions.filter((condition) => this.$i18n.t(`conditions.${condition.id}.md.blurb`)).map((condition) => condition.id));
            },
            outcomes() {
                return this.cds?.outcomes;
            },
            filteredCdsResources() {
                return this.cdsResources.filter((cdsResource) => intersection(cdsResource.triggers, this.outcomes).length > 0);
            },
            none() {
                return this.cds && 0 === this.filteredConditionIds.length && 0 === this.filteredCdsResources.length;
            },
            tourSteps() {
                const steps = [{ text: this.$i18n.t('tour.resource-intro') }];
                if (this.filteredConditionIds.length > 0 || this.filteredCdsResources.length > 0) {
                    steps.push({ attach: '#tour-resource-share', text: this.$i18n.t('tour.resource-share'), left: true });
                }
                return steps;
            }
        },
        async mounted() {
            await this.load();
        },
        methods: {
            async load() {
                this.error = null;
                try {
                    const { data: cds } = await this.$api.get('/patient/cds');
                    this.cds = cds;
                } catch (error) {
                    this.cds = null;
                    this.error = error.message;
                }
            },
            anchorClick(el) {
                const href = el.getAttribute('href');
                this.$api.post(`/patient/log`, { type: 'resource:click', payload: { href } });
            },
            openShare() {
                this.shareDialog = true;
                this.shareEmail = '';
                this.refid = uuidv4();
            },
            closeShare() {
                this.shareDialog = false;
            },
            shareEmailClick() {
                this.$api.post(`/patient/log`, { type: 'resource:share', payload: { refid: this.refid } });
                this.closeShare();
            },
            isEmail
        }
    };
</script>
