import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VueYoutube from 'vue-youtube';
import App from './App.vue';
import './filters';
import store from './store';
import router from './router';
import api from './api';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/default.css';
hljs.registerLanguage('json', json);

Vue.config.productionTip = false;

Vue.prototype.$api = api;

Vue.use(VueTheMask);
Vue.use(VueYoutube);
Vue.use(hljs.vuePlugin);

import Hint from '@/components/Hint';
Vue.component('Hint', Hint);
import Tour from '@/components/Tour';
Vue.component('Tour', Tour);

Vue.directive('tournoscroll', {
    inserted: (el) => {
        const options = { passive: false };
        const handler = (e) => {
            if (store.state.tourActive) {
                e.preventDefault();
            }
        };
        el.addEventListener('wheel', handler, options);
        el.addEventListener('touchmove', handler, options);
        el._tournoscroll = { handler, options };
    },
    unbind: (el) => {
        if (el._tournoscroll) {
            const { handler, options } = el._tournoscroll;
            el.removeEventListener('wheel', handler, options);
            el.removeEventListener('touchmove', handler, options);
            delete el._tournoscroll;
        }
    }
});

router.beforeEach(async (to, from, next) => {
    if (!store.state.user) {
        if (to.path !== '/login') {
            return next({ path: '/login', replace: true });
        }
        return next();
    }

    if (/\/family|\/risk|\/report/.test(to.path)) {
        if (!store.getters.isFamilyCreated) {
            return next({ path: '/profile', replace: true });
        }
    } else if (/\/risk|\/report/.test(to.path)) {
        if (!store.getters.allFamilyMembersValid) {
            return next({ path: '/family', replace: true });
        }
    } else if ('editPatientData' === store.state.mode && !/\/profile|\/family|\/risk/.test(to.path)) {
        return next({ path: '/profile', replace: true });
    } else if ('editPatientData' !== store.state.mode && 'patient' !== store.getters.userType && '/' !== to.path) {
        return next({ path: '/', replace: true });
    }

    next();
});

store.dispatch('init').then(() => {
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: (h) => h(App)
    }).$mount('#app');
});
