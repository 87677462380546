const get = (prop) => {
    return {
        get() {
            return this.$store.get(`family@[${this.index}].${prop}`);
        }
    };
};

const sync = (prop) => {
    return {
        get() {
            return this.$store.get(`family@[${this.index}].${prop}`);
        },
        set(value) {
            return this.$store.set(`family@[${this.index}].${prop}`, value);
        }
    };
};

const FamilyMemberMixin = {
    props: {
        uuid: {
            type: String,
            required: true
        }
    },
    computed: {
        index() {
            return this.$store.get('familyMemberIndex', this.uuid);
        },
        person() {
            return this.$store.get('familyMember', this.uuid);
        },
        relation: get('relation'),
        isSelf() {
            return 'SELF' === this.relation;
        }
    }
};

export { get, sync, FamilyMemberMixin };
