<template>
    <v-dialog v-if="patient" v-model="dialog" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
        <v-card tile>
            <v-card-title class="denser pa-0">
                <v-toolbar dense dark color="primary">
                    <div class="d-flex justify-space-between flex-wrap align-center full-width">
                        <div class="d-flex flex-wrap align-center">
                            <v-toolbar-title>{{ patient.fullName }}</v-toolbar-title>
                            <v-divider vertical class="mx-3"></v-divider>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn text dark @click.stop :href="`/api/researcher/patient/pedigree?patientId=${patient.id}&scale=2&filename=pedigree.${patient.id}.png`" :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-file-image</v-icon>
                                        <v-icon small color="secondary">fas fa-tree-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.pedigreePng') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn text dark @click.stop :href="`/api/researcher/patient/pedigree?patientId=${patient.id}&filename=pedigree.${patient.id}.pdf&type=pdf`" :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-file-pdf</v-icon>
                                        <v-icon small color="secondary">fas fa-tree-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.pedigreePdf') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        text
                                        dark
                                        @click.stop
                                        :href="`/api/researcher/patient/cds?patientId=${patient.id}&pdf=patient_report_en&filename=patient_report.${patient.id}.pdf`"
                                        :disabled="!patient.reportAvailable"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>fas fa-file-pdf</v-icon>
                                        <v-icon small color="secondary">fas fa-user</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.patientReport') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        text
                                        dark
                                        @click.stop
                                        :href="`/api/researcher/patient/cds?patientId=${patient.id}&pdf=physician_report&filename=provider_report.${patient.id}.pdf`"
                                        :disabled="!patient.reportAvailable"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>fas fa-file-pdf</v-icon>
                                        <v-icon small color="secondary">fas fa-user-md</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.providerReport') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        text
                                        dark
                                        @click.stop
                                        :href="`/api/researcher/patient/cds?patientId=${patient.id}&pdf=patient_no_md_report_en&filename=patient_no_md_report.${patient.id}.pdf`"
                                        :disabled="!patient.reportAvailable"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>fas fa-ban</v-icon>
                                        <v-icon small color="secondary">fas fa-user-md</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.noProviderReport') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn text dark @click.stop :href="`/api/researcher/patient/cds?patientId=${patient.id}&filename=json_report.${patient.id}.json`" :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-brackets-curly</v-icon>
                                        <v-icon small color="secondary">fas fa-users</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.jsonReport') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn text dark @click.stop :href="`/api/researcher/patient/data?patientId=${patient.id}&filename=raw_data.${patient.id}.json`" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-brackets-curly</v-icon>
                                        <v-icon small color="secondary">fas fa-database</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.rawData') }}</span>
                            </v-tooltip>
                            <v-divider vertical class="mx-3"></v-divider>
                            <v-tooltip bottom v-if="patient.user">
                                <template #activator="{ on, attrs }">
                                    <v-btn text dark @click.stop="clipboard(url(`/login?patient=${patient.user.username}`))" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-external-link</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.copyLoginLink') }}<br />{{ url(`/login?patient=${patient.user.username}`) }}</span>
                            </v-tooltip>
                        </div>
                        <div class="d-flex align-center">
                            <v-toolbar-title class="mx-1">
                                <span class="caption label-text mr-1">{{ $t('admin.columns.id') }}</span>
                                {{ patient.id }}
                            </v-toolbar-title>
                            <v-toolbar-title class="mx-1">
                                <span class="caption label-text mr-1">{{ $t('admin.columns.identifier') }}</span>
                                {{ patient.identifier }}
                            </v-toolbar-title>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <v-divider vertical class="mx-3"></v-divider>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn text dark @click="close()" v-bind="attrs" v-on="on"><v-icon>fas fa-times</v-icon></v-btn>
                            </template>
                            <span>{{ $t('common.close') }}</span>
                        </v-tooltip>
                    </div>
                </v-toolbar>
            </v-card-title>
            <v-card-title class="pa-0">
                <v-card flat tile outlined class="pa-2 mb-1 full-width">
                    <div class="d-flex justify-space-between align-center full-width">
                        <div>
                            <v-btn text color="primary" @click.stop="editPatientTags($refs.tagsDialog)">
                                <v-icon small left>fas fa-edit</v-icon>
                                {{ $t('admin.editTags') }}
                            </v-btn>
                            <v-chip v-for="(tag, index) in patient.tags" :key="index" label pill small class="pr-0 mx-1">
                                {{ tag.name }}
                                <v-chip label pill small color="ml-2 primary white--text">{{ tag.value }}</v-chip>
                            </v-chip>
                            <span class="error--text" v-if="missingTags(patient).length > 0">
                                {{ $t('admin.missing') }}: <em>{{ missingTags(patient).join(', ') }}</em>
                            </span>
                        </div>
                        <div>
                            <v-btn text color="primary" @click.stop="editPatientMeta($refs.studyDialog)">
                                <v-icon small left>fas fa-edit</v-icon>
                                {{ $t('admin.editStudies') }}
                            </v-btn>
                            <v-chip v-for="(study, index) in studies(patient)" :key="index" label pill small class="px-0 ma-1" @click.stop>
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-chip label pill small color="accent black--text" v-bind="attrs" v-on="on">
                                            {{ studyDays(study) }}
                                            <v-icon x-small class="ml-1" v-if="isStudyLocked(study)">fas fa-lock</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>{{ $t('admin.studyDays') }}</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <span class="mx-2">{{ study.id }}</span>
                                            <v-icon x-small class="mr-2" v-if="study.finished">fas fa-check</v-icon>
                                        </div>
                                    </template>
                                    <div>{{ $t('admin.columns.started') }}: {{ study.started | date }}</div>
                                    <div v-if="study.finished">{{ $t('admin.columns.finished') }}: {{ study.finished | date }}</div>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-chip label pill small color="primary white--text" @click.stop="studyPush(patient, study)" :disabled="!canPushData(patient, study)" v-bind="attrs" v-on="on">
                                            <v-icon small v-if="patient.activePush && patient.activePush[study.id]">fas fa-spinner fa-spin</v-icon>
                                            <v-icon small v-else>fas fa-upload</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>{{ $t('admin.pushData') }}</span>
                                </v-tooltip>
                            </v-chip>
                        </div>
                        <div>
                            <v-btn text color="primary" @click.stop="editPatientMeta($refs.notesDialog)">
                                <v-icon small left>fas fa-edit</v-icon>
                                {{ $t('admin.editNotes') }}
                            </v-btn>
                            <v-chip label pill small color="ma-1 accent black--text">{{ notesCount(patient) }}</v-chip>
                        </div>
                    </div>
                    <v-card flat tile class="full-width" v-if="patient.pushErrors && patient.pushErrors[0]">
                        <div class="error--text text-caption text-center full-width">
                            {{ patient.pushErrors[0].errors_txt }}
                        </div>
                    </v-card>
                </v-card>
            </v-card-title>
            <v-card-title class="px-3 py-0 mb-1">
                <v-row no-gutters align="center">
                    <v-col cols="auto" v-if="0 === tab">
                        <div class="d-flex align-center">
                            <v-btn color="primary" @click="editData(patient)">
                                <v-icon small left>fas fa-user-edit</v-icon>
                                {{ $t('admin.editData') }}
                            </v-btn>
                            <v-btn text color="primary" class="mx-2" @click="refreshData()" :disabled="refreshingData">
                                <v-icon left :class="{ 'fa-spin': refreshingData }" small>fas fa-sync</v-icon>
                                {{ $t('admin.refreshData') }}
                            </v-btn>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-checkbox class="mx-2 mt-0" color="primary" hide-details v-model="autoRefresh" :label="$t('admin.autoRefresh')"></v-checkbox>
                                    </span>
                                </template>
                                <span>{{ $t('admin.autoRefreshHint') }}</span>
                            </v-tooltip>
                            <v-checkbox class="mx-2 mt-0" color="primary" hide-details v-model="details" :label="$t('admin.showMoreDetails')"></v-checkbox>
                            <v-checkbox class="mx-2 mt-0" color="primary" hide-details v-model="risk" :label="$t('admin.showRiskData')"></v-checkbox>
                        </div>
                    </v-col>
                    <v-col>
                        <v-tabs v-model="tab" right>
                            <v-tab v-for="(tabItem, index) in tabs" :key="index">
                                {{ tabItem.label }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <PatientReadOnly v-for="person in family" :key="person.uuid" :person="person" :family="family" :details="details" :risk="risk" class="mb-2"></PatientReadOnly>
                    </v-tab-item>
                    <v-tab-item>
                        <Pedigree ref="pedigree" :family="family" :diagram-config="{ showConditionLabels: true, showNames: false }"></Pedigree>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card tile flat class="denser" v-if="userLogs && userLogs.length > 0">
                            <v-toolbar dense dark tile flat color="accent">
                                <v-toolbar-title>{{ $t('admin.userLog') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-row no-gutters class="mt-3">
                                <v-col :cols="selectedUserLog ? 4 : 12">
                                    <v-simple-table dense :height="400">
                                        <template #default>
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('admin.columns.timestamp') }}</th>
                                                    <th>{{ $t('admin.columns.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(userLog, userLogIndex) in userLogs"
                                                    :key="userLogIndex"
                                                    class="clickable"
                                                    :class="{ 'grey lighten-3': selectedUserLog && selectedUserLog.id === userLog.id }"
                                                    @click="selectUserLog(userLog)"
                                                >
                                                    <td class="text-no-wrap" style="width: 100px">{{ userLog.timestamp | date }}</td>
                                                    <td class="text-no-wrap">{{ userLog.action }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                                <v-col cols="8" v-if="selectedUserLog">
                                    <v-card flat color="grey lighten-3">
                                        <v-card-text style="height: 400px; overflow-y: auto">
                                            <div>
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.data') }}</span>
                                            </div>
                                            <highlightjs language="json" :code="selectedUserLogData"></highlightjs>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card tile flat class="denser" v-if="auditLogs && auditLogs.length > 0">
                            <v-toolbar dense dark tile flat color="accent">
                                <v-toolbar-title>{{ $t('admin.auditLog') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-row no-gutters class="mt-3">
                                <v-col :cols="selectedAuditLog ? 4 : 12">
                                    <v-simple-table dense :height="400">
                                        <template #default>
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('admin.columns.timestamp') }}</th>
                                                    <th>{{ $t('admin.columns.type') }}</th>
                                                    <th v-if="!selectedAuditLog">{{ $t('admin.columns.user') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(auditLog, auditLogIndex) in auditLogs"
                                                    :key="auditLogIndex"
                                                    class="clickable"
                                                    :class="{ 'grey lighten-3': selectedAuditLog && selectedAuditLog.id === auditLog.id }"
                                                    @click="selectAuditLog(auditLog)"
                                                >
                                                    <td class="text-no-wrap" style="width: 100px">{{ auditLog.timestamp | date }}</td>
                                                    <td class="text-no-wrap">{{ auditLog.type }}</td>
                                                    <td class="text-no-wrap" v-if="!selectedAuditLog && auditLog.user">{{ auditLog.user.name }} ({{ auditLog.user.type }}|{{ auditLog.user.username }})</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                                <v-col cols="8" v-if="selectedAuditLog">
                                    <v-card flat color="grey lighten-3">
                                        <v-card-text style="height: 400px; overflow-y: auto">
                                            <div>
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.type') }}</span
                                                >{{ selectedAuditLog.type }}
                                            </div>
                                            <div v-if="selectedAuditLog.user">
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.user') }} {{ $t('admin.columns.name') }}</span
                                                >{{ selectedAuditLog.user.name }}
                                            </div>
                                            <div v-if="selectedAuditLog.user">
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.user') }} {{ $t('admin.columns.type') }}</span
                                                >{{ selectedAuditLog.user.type }}
                                            </div>
                                            <div v-if="selectedAuditLog.user">
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.user') }} {{ $t('admin.columns.username') }}</span
                                                >{{ selectedAuditLog.user.username }}
                                            </div>
                                            <div>
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.data') }}</span>
                                            </div>
                                            <highlightjs language="json" :code="selectedAuditLogData"></highlightjs>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card tile flat class="denser" v-if="fhirLogs && fhirLogs.length > 0">
                            <v-toolbar dense dark tile flat color="accent">
                                <v-toolbar-title>{{ $t('admin.fhirLog') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-row no-gutters class="mt-3">
                                <v-col :cols="selectedFhirLog ? 6 : 12">
                                    <v-simple-table dense :height="400">
                                        <template #default>
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('admin.columns.timestamp') }}</th>
                                                    <th>{{ $t('admin.columns.query') }}</th>
                                                    <th v-if="!selectedFhirLog">{{ $t('admin.columns.userType') }}</th>
                                                    <th v-if="!selectedFhirLog">{{ $t('admin.columns.user') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(fhirLog, fhirLogIndex) in fhirLogs"
                                                    :key="fhirLogIndex"
                                                    class="clickable"
                                                    :class="{ 'grey lighten-3': selectedFhirLog && selectedFhirLog.id === fhirLog.id }"
                                                    @click="selectFhirLog(fhirLog)"
                                                >
                                                    <td class="text-no-wrap" style="width: 100px">{{ fhirLog.timestamp | date }}</td>
                                                    <td class="text-no-wrap">{{ fhirLog.query }}</td>
                                                    <td class="text-no-wrap" v-if="!selectedFhirLog">{{ fhirLog.userType }}</td>
                                                    <td class="text-no-wrap" v-if="!selectedFhirLog">{{ fhirLog.user }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                                <v-col cols="6" v-if="selectedFhirLog">
                                    <v-card flat color="grey lighten-3">
                                        <v-card-text style="height: 400px; overflow-y: auto">
                                            <div>
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.userType') }}</span
                                                >{{ selectedFhirLog.userType }}
                                            </div>
                                            <div>
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.user') }}</span
                                                >{{ selectedFhirLog.user }}
                                            </div>
                                            <div>
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.iss') }}</span
                                                >{{ selectedFhirLog.iss }}
                                            </div>
                                            <div>
                                                <span class="caption label-text mr-1">{{ $t('admin.columns.response') }}</span>
                                            </div>
                                            <highlightjs language="json" :code="selectedFhirLogResponse"></highlightjs>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-text v-if="isSuperAdmin && selectedFhirLog.response.identifier">
                                            <v-simple-table dense>
                                                <template #default>
                                                    <thead>
                                                        <tr>
                                                            <th>{{ $t('admin.columns.identifier') }}</th>
                                                            <th>{{ $t('admin.value') }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(identifier, identifierIndex) in selectedFhirLog.response.identifier" :key="identifierIndex">
                                                            <td class="text-no-wrap">{{ identifier.type && identifier.type.text ? identifier.type.text : identifier.system }}</td>
                                                            <td class="text-no-wrap">{{ identifier.value }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-row class="denser">
                            <v-col cols="4">
                                <v-card tile flat>
                                    <v-toolbar dense dark tile flat color="accent">
                                        <v-toolbar-title>{{ $t('admin.ehrConditions') }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <highlightjs language="json" :code="ehrConditions"></highlightjs>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card tile flat>
                                    <v-toolbar dense dark tile flat color="accent">
                                        <v-toolbar-title>{{ $t('admin.ehrObservations') }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <highlightjs language="json" :code="ehrObservations"></highlightjs>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card tile flat>
                                    <v-toolbar dense dark tile flat color="accent">
                                        <v-toolbar-title>{{ $t('admin.ehrFamilyMemberHistory') }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <highlightjs language="json" :code="ehrFamilyMemberHistory"></highlightjs>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <NotesDialog ref="notesDialog"></NotesDialog>
        <StudyDialog ref="studyDialog"></StudyDialog>
        <TagsDialog ref="tagsDialog"></TagsDialog>
    </v-dialog>
</template>

<script>
    import PatientReadOnly from '@/components/PatientReadOnly';
    import Pedigree from '@/components/Pedigree';
    import { ResearcherMixin } from '@/components/researcher/ResearcherMixin';
    import { get } from 'vuex-pathify';
    import { includes } from 'lodash';

    let autoRefreshInterval = null;

    export default {
        components: { PatientReadOnly, Pedigree },
        mixins: [ResearcherMixin],
        data() {
            return {
                tabs: [{ label: this.$i18n.t('admin.data') }, { label: this.$i18n.t('labels.pedigree') }, { label: this.$i18n.t('admin.logs') }, { label: this.$i18n.t('admin.fhir') }],
                tab: null,
                refreshingData: false,
                autoRefresh: false,
                details: false,
                risk: false,
                dialog: false,
                patient: null,
                auditLogs: null,
                fhirLogs: null,
                userLogs: null,
                selectedAuditLog: null,
                selectedFhirLog: null,
                selectedUserLog: null
            };
        },
        computed: {
            user: get('user'),
            isSuperAdmin() {
                return includes(this.user.roles, 'superadmin');
            },
            family() {
                return this.patient?.data?.family;
            },
            ehrConditions() {
                return JSON.stringify(this.patient.fhir?.ehrConditions || [], null, 2);
            },
            ehrObservations() {
                return JSON.stringify(this.patient.fhir?.ehrObservations || [], null, 2);
            },
            ehrFamilyMemberHistory() {
                return JSON.stringify(this.patient.fhir?.ehrFamilyMemberHistory || [], null, 2);
            },
            selectedAuditLogData() {
                return JSON.stringify({ payload: this.selectedAuditLog.payload, request: this.selectedAuditLog.request }, null, 2);
            },
            selectedFhirLogResponse() {
                return JSON.stringify(this.selectedFhirLog.response, null, 2);
            },
            selectedUserLogData() {
                return JSON.stringify({ meta: this.selectedUserLog.meta, request: this.selectedUserLog.request }, null, 2);
            }
        },
        watch: {
            dialog(val) {
                if (val) {
                    document.documentElement.classList.add('overflow-y-hidden');
                } else {
                    document.documentElement.classList.remove('overflow-y-hidden');
                    this.close();
                }
            },
            autoRefresh(val) {
                if (val) {
                    autoRefreshInterval = setInterval(() => {
                        this.refreshData(true);
                    }, 60000);
                } else {
                    if (autoRefreshInterval) {
                        clearInterval(autoRefreshInterval);
                    }
                }
            },
            details() {
                setTimeout(() => {
                    document.documentElement.classList.add('overflow-y-hidden');
                }, 0);
            },
            risk() {
                setTimeout(() => {
                    document.documentElement.classList.add('overflow-y-hidden');
                }, 0);
            },
            async tab(val) {
                if (1 === val) {
                    setTimeout(() => this.$refs.pedigree.draw(), 100);
                }
                if (2 === val) {
                    this.getLogs();
                }
                if (3 === val) {
                    this.getFhir();
                }
            }
        },
        methods: {
            async show(patient) {
                this.$store.set('researcher/patient', patient);
                this.dialog = true;
                this.patient = patient;
                this.refreshData();
            },
            close() {
                this.$store.set('researcher/patient', null);
                this.tab = null;
                this.dialog = false;
                this.patient = null;
                this.auditLogs = null;
                this.fhirLogs = null;
                this.userLogs = null;
                this.selectedAuditLog = null;
                this.selectedFhirLog = null;
                this.selectedUserLog = null;

                if (autoRefreshInterval) {
                    clearInterval(autoRefreshInterval);
                }
            },
            async refreshData(auto) {
                this.refreshingData = true;
                const { data: patientData } = await this.$api.get(`/researcher/patient?patientId=${this.patient.id}${auto ? '&noroll' : ''}`);
                const { data: pushErrors } = await this.$api.get(`/researcher/patient/pushErrors?patientId=${this.patient.id}${auto ? '&noroll' : ''}`);
                patientData.pushErrors = pushErrors;
                this.patient = patientData;
                this.refreshingData = false;
            },
            async getLogs() {
                const { data } = await this.$api.get(`/researcher/patient/logs?patientId=${this.patient.id}`);
                this.auditLogs = data.auditLogs;
                this.userLogs = data.userLogs;
            },
            async getFhir() {
                const { data } = await this.$api.get(`/researcher/patient/fhir?patientId=${this.patient.id}`);
                this.fhirLogs = data.fhirLogs;
            },
            async studyPush(patient, study) {
                this.pushData(patient, study, true).finally(this.getLogs);
            },
            async editPatientTags(dialog) {
                this.patient = await this.editTags(this.patient, dialog, true);
            },
            async editPatientMeta(dialog) {
                (this.patient = await this.editMeta(this.patient, dialog)), true;
            },
            selectAuditLog(auditLog) {
                if (this.selectedAuditLog && this.selectedAuditLog.id === auditLog.id) {
                    this.selectedAuditLog = null;
                } else {
                    this.selectedAuditLog = auditLog;
                }
            },
            selectFhirLog(fhirLog) {
                if (this.selectedFhirLog && this.selectedFhirLog.id === fhirLog.id) {
                    this.selectedFhirLog = null;
                } else {
                    this.selectedFhirLog = fhirLog;
                }
            },
            selectUserLog(userLog) {
                if (this.selectedUserLog && this.selectedUserLog.id === userLog.id) {
                    this.selectedUserLog = null;
                } else {
                    this.selectedUserLog = userLog;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .label-text {
        opacity: 0.75;
    }
    .clickable {
        cursor: pointer;
    }
    ::v-deep {
        .denser {
            .v-toolbar,
            .v-toolbar__content {
                height: unset !important;
            }
            .v-toolbar__content {
                padding: 4px 12px;
            }
            .v-toolbar__title {
                font-size: 1rem;
            }
        }
        code {
            line-height: 1;
            background-color: inherit !important;
            color: inherit !important;
            padding: 0.5em !important;
        }
    }
</style>
