<template>
    <div v-resize="updateDisplay" v-if="tourActive">
        <v-overlay :opacity="0"></v-overlay>
        <div class="highlight" :style="cssVars"></div>
        <v-menu
            v-model="menu"
            class="tour-menu"
            :activator="attach"
            :absolute="!attach"
            :position-x="x"
            :position-y="y"
            :max-width="400"
            :min-width="400"
            offset-y
            :left="left"
            :top="top"
            :nudge-bottom="nudgeBottom"
            :nudge-top="nudgeTop"
            :close-on-click="false"
            :close-on-content-click="false"
        >
            <v-card ref="card" v-tournoscroll>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text color="primary" @click="exit()">
                        {{ $t('tour.exit') }}
                        <v-icon small right>fas fa-sign-out-alt</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text class="text-body-1 pa-6" v-html="text"></v-card-text>
                <v-card-actions class="mt-n6 mb-3" v-if="steps.length > 1">
                    <v-spacer></v-spacer>
                    <v-item-group v-model="step" class="text-center" mandatory>
                        <v-item v-for="(step, index) in steps" :key="index" v-slot="{ active }">
                            <v-btn :input-value="active" icon x-small color="primary" @click="goto(index)">
                                <v-icon x-small>fas fa-circle</v-icon>
                            </v-btn>
                        </v-item>
                    </v-item-group>
                    <v-spacer></v-spacer>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn small outlined @click="goto(step - 1)" v-if="step > 0">
                        <v-icon small left>fas fa-arrow-left</v-icon>
                        {{ $t('tour.back') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" @click="goto(step + 1)" v-if="step < steps.length - 1">
                        {{ $t('tour.next') }}
                        <v-icon small right>fas fa-arrow-right</v-icon>
                    </v-btn>
                    <v-btn small color="primary" @click="done()" v-else>
                        {{ $t('tour.done') }}
                        <v-icon small right>fas fa-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import { isFunction } from 'lodash';
    import { get, sync } from 'vuex-pathify';

    const defaultCssVars = {
        '--highlight-width': '0px',
        '--highlight-height': '0px',
        '--highlight-top': 0,
        '--highlight-left': 0,
        '--highlight-border': '0px 0px 0px 0px'
    };

    export default {
        props: {
            steps: {
                type: Array,
                required: true
            },
            delay: {
                type: Number,
                default: 750
            },
            onDone: {
                type: Function,
                default: () => null
            }
        },
        data: () => ({
            menu: false,
            step: 0,
            x: 0,
            y: 0,
            cssVars: defaultCssVars
        }),
        computed: {
            tourEnabled: get('tourEnabled'),
            tourActive: sync('tourActive'),
            currentStep() {
                return this.steps[this.step];
            },
            attach() {
                return this.currentStep.attach;
            },
            left() {
                return this.currentStep.left;
            },
            top() {
                return this.currentStep.top;
            },
            nudgeBottom() {
                if (this.attach && !this.top) {
                    return 8;
                }
                return 0;
            },
            nudgeTop() {
                if (this.attach && this.top) {
                    return 8;
                }
                return 0;
            },
            text() {
                return this.currentStep.text;
            }
        },
        watch: {
            tourEnabled: {
                handler() {
                    this.tourActive = this.tourEnabled;
                    if (this.tourActive) {
                        this.goto(0);
                    }
                },
                immediate: true
            }
        },
        methods: {
            exit() {
                this.tourActive = false;
                this.$store.set('preferences@tour', false);
            },
            goto(step) {
                this.menu = false;
                this.cssVars = defaultCssVars;
                this.step = step;
                if (isFunction(this.currentStep.setup)) {
                    this.currentStep.setup();
                }
                setTimeout(() => {
                    const show = () => {
                        this.menu = true;
                        setTimeout(this.updateDisplay, this.attach ? 0 : 100);
                    };
                    if (this.attach) {
                        const target = document.querySelector(this.attach);
                        if (target) {
                            const rect = target.getBoundingClientRect();
                            return this.$vuetify.goTo(target, { offset: rect.height }).then(show);
                        }
                    }
                    show();
                }, this.delay);
            },
            done() {
                this.tourActive = false;
                this.onDone();
            },
            updateDisplay() {
                this.$nextTick(() => {
                    if (this.$refs.card?.$el) {
                        const width = this.$refs.card.$el.clientWidth;
                        const height = this.$refs.card.$el.clientHeight;
                        this.x = window.innerWidth / 2 - width / 2;
                        this.y = window.innerHeight / 2 - height / 2;
                    }
                    this.cssVars = defaultCssVars;
                    if (this.attach) {
                        const target = document.querySelector(this.attach);
                        if (target) {
                            const rect = target.getBoundingClientRect();
                            this.cssVars = {
                                '--highlight-width': `${rect.width + 8}px`,
                                '--highlight-height': `${rect.height + 8}px`,
                                '--highlight-top': `${rect.top - 4}px`,
                                '--highlight-left': `${rect.left - 4}px`,
                                '--highlight-border': '0px 0px 1px 2px'
                            };
                        }
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .highlight {
        position: fixed;
        width: var(--highlight-width);
        height: var(--highlight-height);
        top: var(--highlight-top);
        left: var(--highlight-left);
        z-index: 9999;
        background: transparent;
        border-radius: 4px;
        box-shadow: var(--v-error-base) var(--highlight-border), rgb(33 33 33 / 46%) 0px 0px 0px 10000px;
    }
    .tour-menu {
        z-index: 10000;
    }
</style>
