<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <div class="d-flex justify-space-between">
                <div class="text-h6 d-flex align-center">
                    <v-icon small left>fas fa-vial fa-fw</v-icon>
                    {{ $t('labels.labs') }}
                </div>
                <v-icon small class="mx-2 secondary--text" v-if="isValid()">fas fa-check</v-icon>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2 mb-n2 py-3">
            <v-row>
                <v-col class="py-0">
                    <div class="my-3">
                        <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.bloodPressure') }}</div>
                        <v-row no-gutters justify="center" align="center" class="pb-2">
                            <v-col cols="3">
                                <v-text-field dense outlined persistent-placeholder class="ma-0" hide-details type="tel" v-mask="'###'" :label="$t('fields.bpSystolic')" v-model.number="bpSystolic"></v-text-field>
                            </v-col>
                            <span class="mx-2"> / </span>
                            <v-col cols="3">
                                <v-text-field dense outlined persistent-placeholder class="ma-0" hide-details type="tel" v-mask="'###'" :label="$t('fields.bpDiastolic')" v-model.number="bpDiastolic"></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <div class="my-3">
                        <v-row>
                            <v-col cols="12" md="6" class="py-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.ldl')"
                                    :hint="$t('hints.ldl')"
                                    :factor="MGPERDL_TO_MMOLPERL"
                                    :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                                    :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                                    v-model="ldl"
                                    value-unit="unit1"
                                    :mode.sync="ldlMode"
                                    :precision="1"
                                    type="number"
                                    :mask="null"
                                ></ConvertField>
                            </v-col>
                            <v-col cols="12" md="6" class="py-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.hdl')"
                                    :hint="$t('hints.hdl')"
                                    :factor="MGPERDL_TO_MMOLPERL"
                                    :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                                    :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                                    v-model="hdl"
                                    value-unit="unit1"
                                    :mode.sync="hdlMode"
                                    :precision="1"
                                    type="number"
                                    :mask="null"
                                ></ConvertField>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" class="py-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.triglycerides')"
                                    :factor="MGPERDL_TO_MMOLPERL"
                                    :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                                    :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                                    v-model="triglycerides"
                                    value-unit="unit1"
                                    :mode.sync="triglyceridesMode"
                                    :precision="1"
                                    type="number"
                                    :mask="null"
                                ></ConvertField>
                            </v-col>
                            <v-col cols="12" md="6" class="py-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.totalCholesterol')"
                                    :factor="MGPERDL_TO_MMOLPERL"
                                    :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                                    :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                                    v-model="totalCholesterol"
                                    value-unit="unit1"
                                    :mode.sync="totalCholesterolMode"
                                    :precision="1"
                                    type="number"
                                    :mask="null"
                                ></ConvertField>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" class="py-2">
                                <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.cholesterolTestYear') }}</div>
                                <v-text-field dense outlined class="ma-0 mb-2" hide-details type="tel" v-mask="'####'" v-model.number="cholesterolTestYear"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="py-2">
                                <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.cholesterolMedication') }}</div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox
                                        v-for="(value, index) in yesNo"
                                        :key="index"
                                        class="mt-1 mb-2 mx-2"
                                        hide-details
                                        v-model="cholesterolMedication"
                                        :label="valueLabel(value)"
                                        :value="value"
                                        on-icon="$radioOn"
                                        off-icon="$radioOff"
                                    ></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <div class="my-3">
                        <v-row no-gutters justify="center">
                            <v-col cols="6" class="mt-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.hsCRP')"
                                    :hint="$t('hints.hsCRP')"
                                    :factor="MGPERDL_TO_MGPERL"
                                    :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                                    :unit2="{ value: 'mg/L', label: $t('units.mg_per_L') }"
                                    v-model="hsCRP"
                                    value-unit="unit2"
                                    :mode.sync="hsCRPMode"
                                ></ConvertField>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <div class="my-3">
                        <v-row no-gutters justify="center">
                            <v-col>
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.caIMT') }}
                                    <Hint :text="$t('hints.caIMTadditional')" :title="$t('hints.caIMT')" size="medium"></Hint>
                                </div>
                                <div class="text-subtitle-1 text-center hint-text mt-n2">{{ $t('hints.caIMT') }}</div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox
                                        v-for="(value, index) in caIMTs"
                                        :key="index"
                                        class="mt-0 mb-2 mx-2"
                                        hide-details
                                        v-model="caIMT"
                                        :label="$t(`caIMTs.${value}`)"
                                        :value="value"
                                        on-icon="$radioOn"
                                        off-icon="$radioOff"
                                    ></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <div class="my-3">
                        <v-row no-gutters justify="center">
                            <v-col>
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.cacCT') }}
                                    <Hint :text="$t('hints.cacCTadditional')" :title="$t('hints.cacCT')" size="medium"></Hint>
                                </div>
                                <div class="text-subtitle-1 text-center hint-text mt-n2">{{ $t('hints.cacCT') }}</div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox
                                        v-for="(value, index) in cacCTs"
                                        :key="index"
                                        class="mt-0 mb-2 mx-2"
                                        hide-details
                                        v-model="cacCT"
                                        :label="valueLabel(value)"
                                        :value="value"
                                        on-icon="$radioOn"
                                        off-icon="$radioOff"
                                    ></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';
    import ConvertField from '@/components/ConvertField';
    import { yesNo, caIMTs, cacCTs, MGPERDL_TO_MMOLPERL, MGPERDL_TO_MGPERL } from '@/common';

    export default {
        mixins: [ConditionsMixin, FamilyMemberMixin],
        components: { ConvertField },
        data: () => ({
            yesNo,
            caIMTs,
            cacCTs,
            MGPERDL_TO_MMOLPERL,
            MGPERDL_TO_MGPERL
        }),
        computed: {
            bpSystolic: sync('bpSystolic'),
            bpDiastolic: sync('bpDiastolic'),
            ldl: sync('ldl'),
            hdl: sync('hdl'),
            triglycerides: sync('triglycerides'),
            totalCholesterol: sync('totalCholesterol'),
            cholesterolTestYear: sync('cholesterolTestYear'),
            cholesterolMedication: sync('cholesterolMedication'),
            hsCRP: sync('hsCRP'),
            caIMT: sync('caIMT'),
            cacCT: sync('cacCT'),

            ldlMode: sync('ldlMode'),
            hdlMode: sync('hdlMode'),
            triglyceridesMode: sync('triglyceridesMode'),
            totalCholesterolMode: sync('totalCholesterolMode'),
            hsCRPMode: sync('hsCRPMode')
        },
        methods: {
            isValid() {
                return this.$store.get('isLabsValid', this.uuid);
            }
        }
    };
</script>
