<template>
    <v-data-table :headers="headers" :items="patients" item-key="id" :item-class="rowClass" @click:row="rowClick" dense class="mt-3">
        <template #header.fullName>
            <v-icon small left class="hidden">fas fa-female fa-fw</v-icon>
            {{ $t('provider.columns.name') }}
        </template>
        <template #item.fullName="{ item: patient }">
            <v-icon small left>fas fa-{{ patient.gender }} fa-fw</v-icon>
            {{ patient.fullName }}
        </template>
        <template #item.age="{ item: patient }">
            {{ $tc('common.years', patient.age) }}
        </template>
        <template #item.reports="{ item: patient }">
            <v-tooltip top>
                <template #activator="{ on, attrs }">
                    <v-btn small text :href="`/api/provider/patient/cds?patientId=${patient.id}&pdf=patient_report_en`" target="_blank" @click.stop :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                        <v-icon small>fas fa-file-pdf</v-icon>
                        <v-icon small color="primary">fas fa-user</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('admin.patientReport') }}</span>
            </v-tooltip>
            <v-tooltip top>
                <template #activator="{ on, attrs }">
                    <v-btn small text :href="`/api/provider/patient/cds?patientId=${patient.id}&pdf=physician_report`" target="_blank" @click.stop :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                        <v-icon small>fas fa-file-pdf</v-icon>
                        <v-icon small color="primary">fas fa-user-md</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('admin.providerReport') }}</span>
            </v-tooltip>
        </template>
    </v-data-table>
</template>

<script>
    import { get } from 'vuex-pathify';

    export default {
        data() {
            return {
                headers: [
                    { text: this.$i18n.t('provider.columns.name'), value: 'fullName' },
                    { text: this.$i18n.t('provider.columns.age'), value: 'age' },
                    { text: this.$i18n.t('provider.columns.reports'), value: 'reports', align: 'center', sortable: false }
                ]
            };
        },
        computed: {
            selectedPatient: get('provider/selectedPatient'),
            patients: get('provider/patients')
        },
        methods: {
            isSelected(patient) {
                return patient.id === this.selectedPatient?.id;
            },
            rowClass(patient) {
                if (this.isSelected(patient)) {
                    return 'clickable accent';
                }
                return 'clickable';
            },
            async rowClick(patient) {
                await this.$store.dispatch('provider/selectPatient', { patientId: patient.id });
            }
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .clickable {
        cursor: pointer;
    }
</style>
