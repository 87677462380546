var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":800,"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('admin.editStudies')))])],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.studies,"item-key":"id","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var study = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary","disabled":!_vm.canEdit(study)},on:{"click":function($event){$event.stopPropagation();return _vm.removeStudy(study)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('admin.deleteStudy')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary","disabled":!_vm.canEdit(study)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleLock(study)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas "+_vm._s(study.locked || _vm.isStudyLocked(study) ? 'fa-lock' : 'fa-unlock'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(study.locked || _vm.isStudyLocked(study) ? _vm.$t('admin.unlock') : _vm.$t('admin.lock')))])])]}},{key:"item.id",fn:function(ref){
var study = ref.item;
return [_c('span',{class:{ 'font-weight-bold': _vm.meta.selectedStudyId === study.id }},[_vm._v(_vm._s(study.id))])]}},{key:"item.started",fn:function(ref){
var study = ref.item;
return [(study.started)?_c('span',[_vm._v(_vm._s(_vm._f("date")(study.started)))]):_vm._e()]}},{key:"item.finished",fn:function(ref){
var study = ref.item;
return [(study.finished)?_c('span',[_vm._v(_vm._s(_vm._f("date")(study.finished)))]):_vm._e()]}},{key:"item.studyDays",fn:function(ref){
var study = ref.item;
return [_c('v-chip',{attrs:{"label":"","pill":"","small":"","color":"accent black--text"}},[_vm._v(" "+_vm._s(_vm.studyDays(study))+" "),(_vm.isStudyLocked(study))?_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v("fas fa-lock")]):_vm._e()],1)]}}],null,false,2125108075)}),_c('v-card-actions',[_c('v-select',{staticClass:"action-select",attrs:{"dense":"","outlined":"","items":_vm.availableStudies,"hide-details":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"primary","disabled":!_vm.newStudyId},on:{"click":function($event){return _vm.addStudy(_vm.newStudyId)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fas fa-plus")]),_vm._v(" "+_vm._s(_vm.$t('admin.addStudy'))+" ")],1)]},proxy:true}],null,false,2981273754),model:{value:(_vm.newStudyId),callback:function ($$v) {_vm.newStudyId=$$v},expression:"newStudyId"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.ok()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-check")]),_vm._v(" "+_vm._s(_vm.$t('common.ok'))+" ")],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-times")]),_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }