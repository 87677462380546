<template>
    <v-list subheader :color="color">
        <v-list-item-group v-model="selectedUuid" color="primary">
            <v-list-item v-for="person in sublist" :key="person.uuid" :value="person.uuid" :class="{ 'error-highlight': !isValid(person) }" id="tour-family-details">
                <v-list-item-avatar :class="`indent-${indent(person.relation)}`">
                    <v-icon :class="iconClass(person)">{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-action-text class="mr-3" v-if="person.multiple">
                    <v-btn small text @click.stop="viewTwins(person.multiple)">
                        <v-icon small v-for="(uuid, index) in person.multiple.fraternal.concat(person.multiple.identical)" :key="index">fas fa-user</v-icon>
                    </v-btn>
                </v-list-item-action-text>
                <v-list-item-content>
                    <v-list-item-title>{{ person.nickname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t(`relations.${person.relation}`) }}<span v-if="requiredRelations.indexOf(person.relation) > -1">*</span></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <div class="d-flex align-center">
                        <div v-if="canAddChild(person.relation)" :id="'SELF' === person.relation ? 'tour-family-add-children' : null">
                            <v-btn small text outlined class="ml-1 btn-outline-solid-black" @click.stop="addChild(person, 'female')"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.femaleChild') }}</v-btn>
                            <v-btn small text outlined class="ml-1 btn-outline-solid-black" @click.stop="addChild(person, 'male')"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.maleChild') }}</v-btn>
                        </div>
                        <v-btn icon small @click.stop="remove(person)" class="mx-2" :class="{ hidden: !canRemove(person) }"><v-icon small>fas fa-trash</v-icon></v-btn>
                        <v-divider vertical class="mx-3"></v-divider>
                        <v-icon small :class="`${iconTextClass(person)} ${!isValid(person) ? 'error--text' : ''}`" id="tour-family-check">{{ isValid(person) ? 'fas fa-user-check' : 'far fa-user-slash' }}</v-icon>
                    </div>
                </v-list-item-action>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
    import { FamilyMixin } from '@/components/FamilyMixin';
    import { requiredRelations } from '@/common';

    export default {
        mixins: [FamilyMixin],
        props: {
            sublist: {
                type: Array,
                required: true
            },
            color: {
                type: String,
                default: 'white'
            }
        },
        data: () => ({
            requiredRelations
        })
    };
</script>

<style lang="scss" scoped>
    .indent-0 {
        margin-left: 0;
    }
    .indent-1 {
        margin-left: 36px;
    }
    .indent-2 {
        margin-left: 72px;
    }
</style>
