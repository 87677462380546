<template>
    <v-card flat>
        <v-expansion-panels v-model="panels">
            <v-expansion-panel id="tour-report-pedigree">
                <v-expansion-panel-header color="grey lighten-3">
                    <div class="d-flex justify-space-between">
                        <div class="text-h6 d-flex align-center">
                            <v-icon small left>fas fa-project-diagram fa-fw</v-icon>
                            <div class="d-flex align-end">
                                {{ $t('labels.pedigree') }}
                                <span class="ml-1 text-subtitle-1 hint-text">({{ $t('hints.pedigree') }})</span>
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="expansion-panel--padless">
                    <Pedigree ref="pedigree" :family="family"></Pedigree>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="studyParameters.cds" id="tour-report-risk">
                <v-expansion-panel-header color="grey lighten-3">
                    <div class="d-flex justify-space-between">
                        <div class="text-h6 d-flex align-center">
                            <v-icon small left>fas fa-clipboard-list fa-fw</v-icon>
                            {{ $t('labels.riskProfile') }}
                        </div>
                        <v-btn
                            text
                            outlined
                            @click.stop
                            :href="`/api/patient/cds?pdf=${reportType}_${language}&lang=${lang}&filename=${$t('labels.pdfFilename')}`"
                            :disabled="!report"
                            color="primary"
                            class="mr-3 btn-outline-solid-primary"
                            id="tour-report-download"
                            ><v-icon left small>fas fa-file-pdf</v-icon>{{ $t('labels.download') }}</v-btn
                        >
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="expansion-panel--padless">
                    <div class="primary white--text text-caption text-center px-6" v-if="timestamp">
                        <span class="hint-text">{{ $t('common.last_updated') }}:</span>
                        {{ timestamp }}
                    </div>
                    <div class="ma-6" v-if="error">
                        <v-alert prominent outlined border="left" type="info">
                            <v-row align="center">
                                <v-col class="grow">
                                    <span v-html="$t('errors.riskProfile')"></span>
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn color="info" @click="load()">{{ $t('labels.tryAgain') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </div>
                    <div class="ma-6">
                        <v-skeleton-loader v-if="!report" :boilerplate="!!error" tile type="article@4"></v-skeleton-loader>
                        <div v-else class="risk-report" v-html="report" v-collapse></div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-alert dense text outlined color="primary" class="my-6" v-if="study">
            <div class="d-flex align-center">
                <div>
                    <div class="font-weight-bold">{{ $t(`studies.${study.id}`) }}</div>
                    <div v-if="!study.finished">{{ $t('prompts.studyCompletion') }}</div>
                </div>
                <v-spacer class="mx-3"></v-spacer>
                <v-btn outlined color="primary" @click="completeCurrentStudy()" v-if="!study.finished">
                    <v-icon small left>fas fa-check</v-icon>
                    {{ $t('labels.imFinished') }}
                </v-btn>
                <div v-else>
                    <v-icon small left class="primary--text">fas fa-check</v-icon>
                    {{ $t('labels.completed') }}
                </div>
            </div>
        </v-alert>
        <Tour :steps="tourSteps"></Tour>
    </v-card>
</template>

<script>
    import { FamilyMixin } from '@/components/FamilyMixin';
    import Pedigree from '@/components/Pedigree';
    import { get } from 'vuex-pathify';

    import moment from 'moment';

    const headerClickListener = (el) => {
        const elClass = el.getAttribute('class');
        const siblingClass = el.nextElementSibling.getAttribute('class');
        if (/ collapsed/.test(elClass) || / collapsed/.test(siblingClass)) {
            el.setAttribute('class', elClass.replace(/ collapsed/, ''));
            el.nextElementSibling.setAttribute('class', siblingClass.replace(/ collapsed/, ''));
        } else {
            el.setAttribute('class', `${elClass} collapsed`);
            el.nextElementSibling.setAttribute('class', `${siblingClass} collapsed`);
        }
    };

    export default {
        name: 'Report',
        components: { Pedigree },
        mixins: [FamilyMixin],
        directives: {
            collapse: {
                inserted: (el) => {
                    const headers = el.querySelectorAll('.header');
                    headers.forEach((header) => {
                        const icon = document.createElement('I');
                        icon.setAttribute('class', 'fas fa-chevron-up expand-icon');
                        header.appendChild(icon);
                        const handler = headerClickListener.bind(null, header);
                        header.addEventListener('click', handler);
                        header._handler = handler;

                        const headerClass = header.getAttribute('class');
                        const siblingClass = header.nextElementSibling.getAttribute('class');
                        header.setAttribute('class', `${headerClass} collapsed`);
                        header.nextElementSibling.setAttribute('class', `${siblingClass} collapsed`);
                    });
                },
                unbind: (el) => {
                    const headers = el.querySelectorAll('.header');
                    headers.forEach((header) => {
                        header.removeEventListener('click', header._handler);
                        delete header._handler;
                    });
                }
            }
        },
        data: () => ({
            panels: null,
            drawn: false,
            cds: null,
            error: null
        }),
        computed: {
            study: get('patient/study'),
            studyParameters: get('patient/studyParameters'),
            lang() {
                return this.$store.get('language');
            },
            language() {
                return 'es' === this.lang ? 'sp' : this.lang; // TODO: fix this in what is returned by CDS
            },
            reportType() {
                return this.studyParameters?.cdsReportType || 'patient_report';
            },
            report() {
                if (this.cds) {
                    return unescape(this.cds[`${this.reportType}_${this.language}`]);
                }
                return null;
            },
            timestamp() {
                if (this.cds && this.cds.timestamp) {
                    return moment(this.cds.timestamp).format('LLL');
                }
                return null;
            },
            tourSteps() {
                const setupFn = (panel) => {
                    return () => {
                        this.panels = panel;
                    };
                };
                const steps = [
                    { text: this.$i18n.t('tour.report-intro'), setup: setupFn(null) },
                    { attach: '#tour-report-pedigree', text: this.$i18n.t('tour.report-pedigree'), setup: setupFn(0) },
                    { attach: '#tour-report-pedigree-controls', text: this.$i18n.t('tour.report-pedigree-controls'), setup: setupFn(0) },
                    { attach: '#tour-report-pedigree-list', text: this.$i18n.t('tour.report-pedigree-list'), top: true, setup: setupFn(0) }
                ];
                if (this.studyParameters.cds) {
                    steps.push({ attach: '#tour-report-risk', text: this.$i18n.t('tour.report-risk'), setup: setupFn(1) });
                    steps.push({ attach: '#tour-report-download', text: this.$i18n.t('tour.report-download'), left: true, setup: setupFn(1) });
                }
                if (this.studyParameters.resource) {
                    steps.push({ attach: '#tour-nav-resources', text: this.$i18n.t('tour.nav-resources'), left: true, setup: setupFn(null) });
                }
                return steps;
            }
        },
        watch: {
            panels(val) {
                if (0 === val && !this.drawn) {
                    this.drawn = true;
                    setTimeout(() => this.$refs.pedigree.draw(), 100);
                }
            }
        },
        methods: {
            async load() {
                this.error = null;
                if (this.studyParameters.cds) {
                    try {
                        const { data: cds } = await this.$api.get('/patient/cds');
                        this.cds = cds;
                    } catch (error) {
                        this.cds = null;
                        this.error = error.message;
                    }
                }
            },
            async completeCurrentStudy() {
                await this.$store.dispatch('patient/completeCurrentStudy');
            }
        },
        async mounted() {
            await this.load();
        }
    };
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    .risk-report {
        font-size: 14px;

        ::v-deep .header {
            color: white;
            background-color: var(--v-primary-base);
            font-size: 20px;
            margin-top: 20px;
            padding: 5px 10px;
            border-radius: $border-radius-root;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.collapsed .expand-icon {
                transform: scale(1, -1);
            }
        }
        ::v-deep .content,
        ::v-deep .reference {
            margin: 10px 15px;
            a {
                text-decoration: none;
            }
            a[href^="//"]:after,
            a[href^="http://"]:after,
            a[href^="https://"]:after
            {
                font-family: 'Font Awesome 5 Pro', sans-serif;
                content: ' \f35d ';
                font-size: small;
                margin-right: 5px;
            }
            h4 {
                color: var(--v-primary-base);
                font-weight: bold;
                margin-top: 10px;
            }
            ul {
                margin-left: 20px;
            }
            sup {
                color: var(--v-accent-darken1);
            }
            table {
                border-collapse: collapse;
                width: 100%;
                td {
                    border: 1px solid var(--v-primary-base);
                    padding: 0 5px;
                }
            }
            .addl-information {
                .more-information {
                    color: var(--v-accent-darken1);
                    font-weight: bold;
                }
                p {
                    margin: 10px 0;
                }
            }
            &.collapsed {
                display: none;
            }
        }
    }
</style>
