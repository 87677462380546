<template>
    <v-app>
        <v-app-bar app v-if="showAppBar">
            <v-toolbar-title>
                <v-img :width="imageWidth" :height="50" contain :src="require('@/assets/logo.png')" :alt="$t('common.metree')"></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items v-if="user && ('patient' === user.type || 'editPatientData' === mode)" id="tour-intro-nav">
                <v-btn color="primary" to="/" v-if="'patient' === user.type">
                    <v-icon :left="!$vuetify.breakpoint.mdAndDown" small>fas fa-home</v-icon>
                    <span class="hidden-md-and-down">{{ $t('menu.home') }}</span>
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn color="primary" to="/profile" class="overlay-nav-btn" :disabled="studyLocked">
                    <v-icon :left="!$vuetify.breakpoint.mdAndDown" small>fas fa-user</v-icon>
                    <span class="hidden-md-and-down">{{ $t('menu.profile') }}</span>
                    <v-overlay absolute :value="studyLocked">
                        <v-icon small>fas fa-lock</v-icon>
                    </v-overlay>
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn color="primary" to="/family" class="overlay-nav-btn" :disabled="studyLocked || !isFamilyCreated">
                    <v-icon :left="!$vuetify.breakpoint.mdAndDown" small>fas fa-users</v-icon>
                    <span class="hidden-md-and-down">{{ $t('menu.family') }}</span>
                    <v-overlay absolute :value="studyLocked || !isFamilyCreated">
                        <v-icon small>fas fa-lock</v-icon>
                    </v-overlay>
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn color="primary" to="/risk" class="overlay-nav-btn" :disabled="studyLocked || !isFamilyCreated || !allFamilyMembersValid" v-if="studyParameters.risk">
                    <v-icon :left="!$vuetify.breakpoint.mdAndDown" small>fas fa-calculator</v-icon>
                    <span class="hidden-md-and-down">{{ $t('menu.risk') }}</span>
                    <v-overlay absolute :value="studyLocked || !isFamilyCreated || !allFamilyMembersValid">
                        <v-icon small>fas fa-lock</v-icon>
                    </v-overlay>
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn color="primary" to="/report" class="overlay-nav-btn" :disabled="!isFamilyCreated || !allFamilyMembersValid" v-if="'patient' === user.type">
                    <v-icon :left="!$vuetify.breakpoint.mdAndDown" small>fas fa-clipboard-list</v-icon>
                    <span class="hidden-md-and-down">{{ $t('menu.report') }}</span>
                    <v-overlay absolute :value="!isFamilyCreated || !allFamilyMembersValid">
                        <v-icon small>fas fa-lock</v-icon>
                    </v-overlay>
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn color="primary" to="/resource" v-if="'patient' === user.type && studyParameters.resource" id="tour-nav-resources">
                    <v-icon :left="!$vuetify.breakpoint.mdAndDown" small>fas fa-books-medical</v-icon>
                    <span class="hidden-md-and-down">{{ $t('menu.resource') }}</span>
                </v-btn>
            </v-toolbar-items>
            <v-spacer class="hidden-md-and-down"></v-spacer>
            <v-toolbar-items>
                <v-btn :width="imageWidth" text color="primary" @click="logout()">
                    <v-icon :left="!$vuetify.breakpoint.mdAndDown" small>fas fa-sign-out</v-icon>
                    <span class="hidden-md-and-down">{{ $t('menu.logout') }}</span>
                </v-btn>
            </v-toolbar-items>
        </v-app-bar>
        <v-main v-tournoscroll>
            <v-toolbar dense dark color="accent" class="expandable-toolbar mb-2" v-if="showAppBar && user">
                <v-row no-gutters class="align-center">
                    <v-col>
                        <div class="d-flex align-center" v-if="('practitioner' === user.type || 'researcher' === user.type) && selectedPatient">
                            <v-icon left small class="primary--text">fas fa-user</v-icon>
                            <span class="mr-2 font-weight-bold primary--text">{{ $t('common.patient') }}:</span>
                            <span class="white--text">{{ selectedPatientName }}</span>
                        </div>
                        <div class="d-flex align-center" v-if="'patient' === user.type && organization && study">
                            <v-menu v-model="studyMenu" offset-y v-if="studies && studies.length > 1">
                                <template #activator="{ on, attrs }">
                                    <v-btn text color="primary" class="plain-btn" v-bind="attrs" v-on="on">
                                        <v-icon left small class="primary--text">fas fa-flask</v-icon>
                                        <v-card flat outlined class="white black--text pa-1">
                                            <div class="d-flex">
                                                <img :height="20" :src="organization.logo" :alt="organization.name" />
                                            </div>
                                        </v-card>
                                        <span class="mx-2 white--text">{{ $t(`studies.${study.id}`) }}</span>
                                        <v-icon small class="mr-2" v-if="isStudyLocked(study)">fas fa-lock</v-icon>
                                        <v-icon small>fas {{ `fa-caret-${studyMenu ? 'up' : 'down'}` }}</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-list dense>
                                        <v-list-item-group v-model="selectedStudyId" mandatory color="primary">
                                            <v-list-item v-for="(study, index) in studies" :key="index" :value="study.id">
                                                <v-list-item-content>
                                                    <div class="d-flex align-center">
                                                        <v-icon left small class="primary--text" :class="{ hidden: study.id !== selectedStudyId }">fas fa-flask</v-icon>
                                                        <v-card flat outlined class="white black--text pa-1">
                                                            <div class="d-flex">
                                                                <img :height="20" :src="organization.logo" :alt="organization.name" />
                                                            </div>
                                                        </v-card>
                                                        <span class="mx-2">{{ $t(`studies.${study.id}`) }}</span>
                                                        <v-icon small class="mr-2 pull-right" v-if="isStudyLocked(study)">fas fa-lock</v-icon>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-menu>
                            <template v-else>
                                <v-icon left small class="primary--text">fas fa-flask</v-icon>
                                <v-card flat outlined class="white black--text pa-1">
                                    <div class="d-flex">
                                        <img :height="20" :src="organization.logo" :alt="organization.name" />
                                    </div>
                                </v-card>
                                <span class="mx-2 white--text">{{ $t(`studies.${study.id}`) }}</span>
                            </template>
                            <div class="d-flex align-center" v-if="helpMailto">
                                <v-divider vertical class="mx-1"></v-divider>
                                <v-btn text color="primary" :href="helpMailto">
                                    <v-icon left small>fas fa-question</v-icon>
                                    {{ $t('common.help') }}
                                </v-btn>
                            </div>
                        </div>
                        <div class="d-flex align-center" v-if="'patient' === user.type && organization && !study">
                            <v-icon left small class="primary--text">fas fa-link</v-icon>
                            <v-card flat outlined class="white black--text pa-1">
                                <div class="d-flex">
                                    <img :height="20" :src="organization.logo" :alt="organization.name" />
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn small outlined color="primary" @click="exitEditData()" v-if="'editPatientData' === mode">
                            <v-icon small left>fas fa-times</v-icon>
                            {{ $t('admin.exitEditData') }}
                        </v-btn>
                        <div class="d-flex align-center appbar-controls" v-if="'patient' === user.type" id="tour-intro-controls">
                            <v-switch class="mx-3" color="primary" :label="$t('common.tour')" hide-details v-model="tour"></v-switch>
                            <v-switch class="mx-3" color="primary" :label="$t('common.hints')" hide-details v-model="hints" :disabled="tour"></v-switch>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="d-flex align-center">
                            <v-spacer></v-spacer>
                            <div class="d-flex align-center mx-2" v-if="error">
                                <div class="error--text nowrap mx-2">
                                    <v-icon small color="error">fas fa-exclamation-triangle</v-icon>
                                    {{ $t('common.unsaved') }}
                                </div>
                                <v-btn small depressed color="error" @click="save()" :disabled="isSaving">
                                    <v-icon small left v-if="isSaving">fas fa-fw fa-spinner fa-spin</v-icon>
                                    <v-icon small left v-else>fas fa-fw fa-save</v-icon>
                                    {{ $t('common.save') }}
                                </v-btn>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon left small class="primary--text" v-if="'patient' === user.type">fas fa-user</v-icon>
                            <v-icon left small class="primary--text" v-if="'practitioner' === user.type">fas fa-user-md</v-icon>
                            <span class="white--text">{{ user.name }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-container fluid>
                <v-row no-gutters justify="center">
                    <v-col :xs="cols[viewWidth].xs" :sm="cols[viewWidth].sm" :md="cols[viewWidth].md" :lg="cols[viewWidth].lg" :xl="cols[viewWidth].xl">
                        <v-fade-transition hide-on-leave>
                            <router-view></router-view>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <AddFamilyMember ref="addFamilyMember"></AddFamilyMember>
        <ConditionDialog ref="conditionDialog"></ConditionDialog>
        <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
        <MedlinePlus ref="medlinePlus"></MedlinePlus>
        <Twins ref="twins"></Twins>
        <v-overlay :value="busy">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
    import AddFamilyMember from '@/components/AddFamilyMember';
    import ConditionDialog from '@/components/ConditionDialog';
    import ConfirmationDialog from '@/components/ConfirmationDialog';
    import MedlinePlus from '@/components/MedlinePlus';
    import Twins from '@/components/Twins';
    import { isStudyLocked } from '@/common';
    import { get, sync } from 'vuex-pathify';
    import { io } from 'socket.io-client';
    import moment from 'moment';

    const cols = {
        default: {
            xs: 12,
            sm: 10,
            md: 8,
            lg: 6,
            xl: 6
        },
        wide: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }
    };

    export default {
        name: 'App',
        components: { AddFamilyMember, ConditionDialog, ConfirmationDialog, MedlinePlus, Twins },
        data: () => ({
            socket: io({ transports: ['websocket'], autoConnect: false }),
            sessionExpiresInterval: null,
            sessionExpiresWarning: null,
            sessionExpiresTimeout: null,
            isSaving: false,
            studyMenu: false
        }),
        computed: {
            cols() {
                return this.showAppBar ? cols : { default: cols.default, wide: cols.default };
            },
            viewWidth() {
                return 'researcher' === this.user?.type && 'editPatientData' !== this.mode ? 'wide' : 'default';
            },
            error: sync('error'),
            sessionExpires: get('sessionExpires'),
            busy: get('busy'),
            user: get('user'),
            selectedStudyId: {
                get() {
                    return this.$store.get('patient/selectedStudyId');
                },
                set(value) {
                    return this.$store.dispatch('patient/selectStudy', { studyId: value, router: this.$router });
                }
            },
            study: get('patient/study'),
            studies: get('patient/studies'),
            studyParameters: get('patient/studyParameters'),
            studyLocked() {
                if ('researcher' === this.user?.type && 'editPatientData' === this.mode) {
                    return false;
                }
                return this.$store.get('patient/studyLocked');
            },
            organization: get('organization'),
            helpMailto() {
                if (this.study && this.organization && this.organization.meta) {
                    const email = this.organization.meta[`email.${this.study.id}`];
                    if (email) {
                        return `mailto:${email}?subject=${encodeURIComponent(`[${this.$i18n.t(`studies.${this.study.id}`)}] ${this.$i18n.t('email.help.subject')}`)}`;
                    }
                }
                return null;
            },
            mode: sync('mode'),
            hints: sync('preferences@hints'),
            tour: sync('preferences@tour'),
            selectedPatient() {
                if ('practitioner' === this.user?.type) {
                    return this.$store.get('provider/selectedPatient');
                } else if ('researcher' === this.user?.type) {
                    return this.$store.get('researcher/selectedPatient');
                }
                return null;
            },
            selectedPatientName() {
                if ('practitioner' === this.user?.type) {
                    return this.$store.get('provider/selectedPatientName');
                } else if ('researcher' === this.user?.type) {
                    return this.$store.get('researcher/selectedPatientName');
                }
                return null;
            },
            isFamilyCreated: get('isFamilyCreated'),
            allFamilyMembersValid: get('allFamilyMembersValid'),
            showAppBar() {
                return this.$route?.name && 'login' !== this.$route.name;
            },
            imageWidth() {
                return this.$vuetify.breakpoint.mdAndDown ? 80 : 160;
            }
        },
        watch: {
            user: {
                handler() {
                    if (this.user) {
                        this.socket.connect();
                    } else {
                        this.socket.disconnect();
                    }
                },
                immediate: true
            },
            error(err) {
                if (err) {
                    const wasSaving = err.wasSaving;
                    const status = err.error?.response?.status;
                    if (status) {
                        if (401 === status && '/login' === this.$router.history.current.path) {
                            return; // don't show error prompt for 401 errors on login screen
                        }
                        this.$root.$confirmationDialog
                            .show({
                                title: this.$i18n.t(`errors.${status}.title`) || this.$i18n.t(`errors.default${wasSaving ? '_saving' : ''}.title`),
                                message: this.$i18n.t(`errors.${status}.message`) || this.$i18n.t(`errors.default${wasSaving ? '_saving' : ''}.message`),
                                showCancel: false,
                                toolbarColor: 'error',
                                toolbarIcon: 'fas fa-exclamation-triangle'
                            })
                            .finally(async () => {
                                if (401 === status) {
                                    this.$store.set('user', null);
                                    setTimeout(() => {
                                        if (this.$router.history.current.path !== '/login') {
                                            this.$router.push('/login');
                                        }
                                    });
                                } else if (403 === status) {
                                    // do nothing
                                } else if (409 === status) {
                                    await this.$store.dispatch('refreshPatientData', { selectedPatient: this.selectedPatient });
                                    this.$store.set('error', null);
                                }
                            });
                    }
                }
            },
            sessionExpires: {
                handler(val) {
                    if (this.user && val) {
                        this.clearTimeouts();
                        const expiresInMs = moment(val).diff(moment(), 'milliseconds');
                        const warningInSeconds = 60;
                        const warningInMs = warningInSeconds * 1000;
                        const bufferInMs = 5000;
                        this.sessionExpiresWarning = setTimeout(() => {
                            let countdown = warningInSeconds;
                            this.$root.$confirmationDialog
                                .show({
                                    title: this.$i18n.t('prompts.sessionExpiresWarning.title'),
                                    message: this.$i18n.t('prompts.sessionExpiresWarning.message', { when: this.$i18n.tc('common.inSeconds', countdown) }),
                                    showCancel: false,
                                    toolbarColor: 'primary',
                                    toolbarIcon: 'fas fa-hourglass-end'
                                })
                                .then(async () => {
                                    await this.$api.get('/login');
                                })
                                .catch(() => {});
                            this.sessionExpiresInterval = setInterval(() => {
                                this.$root.$confirmationDialog.updateMessage(this.$i18n.t('prompts.sessionExpiresWarning.message', { when: this.$i18n.tc('common.inSeconds', --countdown) }));
                                if (countdown <= 0) {
                                    clearInterval(this.sessionExpiresInterval);
                                }
                            }, 1000);
                        }, expiresInMs - bufferInMs - warningInMs);
                        this.sessionExpiresTimeout = setTimeout(async () => {
                            await this.logout();
                        }, expiresInMs - bufferInMs);
                    }
                },
                immediate: true
            },
            tour(val) {
                if (val && !this.hints) {
                    this.hints = true;
                }
            }
        },
        methods: {
            isStudyLocked,
            clearTimeouts() {
                if (this.sessionExpiresInterval) {
                    clearInterval(this.sessionExpiresInterval);
                }
                if (this.sessionExpiresWarning) {
                    clearTimeout(this.sessionExpiresWarning);
                }
                if (this.sessionExpiresTimeout) {
                    clearTimeout(this.sessionExpiresTimeout);
                }
            },
            async logout() {
                this.clearTimeouts();
                this.$root.$confirmationDialog.cancel();
                await this.$store.dispatch('logout');
                await this.$router.replace('/login');
            },
            async save() {
                try {
                    this.isSaving = true;
                    await this.$store.dispatch('save');
                } catch (error) {
                    this.$store.set('error', { error });
                } finally {
                    this.isSaving = false;
                }
            },
            async exitEditData() {
                try {
                    await this.$store.dispatch('exitEditData', { selectedPatient: this.selectedPatient });
                    await this.$router.replace('/');
                } catch (error) {
                    this.$store.set('error', { error });
                }
            }
        },
        unmounted() {
            this.clearTimeouts();
        },
        mounted() {
            this.$root.$addFamilyMember = this.$refs.addFamilyMember;
            this.$root.$conditionDialog = this.$refs.conditionDialog;
            this.$root.$confirmationDialog = this.$refs.confirmationDialog;
            this.$root.$medlinePlus = this.$refs.medlinePlus;
            this.$root.$twins = this.$refs.twins;

            this.$root.socket = this.socket;
            this.socket.on('logout', this.logout);
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .overlay-btn,
        .overlay-nav-btn {
            .v-btn__content {
                height: 100%;
                .v-overlay {
                    cursor: default;
                    pointer-events: none;
                    .v-icon {
                        color: #ffffff !important;
                    }
                }
            }
        }
        .overlay-btn {
            .v-btn__content {
                margin: 0 -24px;
                padding: 0 24px;
                .v-overlay {
                    border-radius: 4px;
                }
            }
        }
        .overlay-nav-btn {
            .v-btn__content {
                margin: 0 -15px;
                padding: 0 15px;
            }
            &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
                color: #ffffff !important;
                background-color: var(--v-primary-base) !important;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
                .v-icon {
                    color: #ffffff !important;
                }
            }
        }
        .btn-outline-solid-black:not(.v-btn--disabled) {
            border-color: #000000 !important;
        }
        .btn-outline-solid-white:not(.v-btn--disabled) {
            border-color: #ffffff !important;
        }
        .btn-outline-solid-primary:not(.v-btn--disabled) {
            border-color: var(--v-primary-base) !important;
        }
        .appbar-controls label {
            color: var(--v-primary-base);
        }
        .v-expansion-panel--active > .v-expansion-panel-header {
            min-height: 48px;
        }
        .expansion-panel--padless > .v-expansion-panel-content__wrap {
            padding: 0;
        }
        .v-input__icon--prepend-inner > .v-icon {
            font-size: 20px;
        }
        .v-input__append-outer {
            margin-left: 0;
        }
        .v-input__append-outer > * {
            margin-left: 9px;
        }
        .expandable-toolbar {
            height: unset !important;
            min-height: 48px;
            .v-toolbar__content {
                height: unset !important;
                min-height: 48px;
            }
        }
        .primary--border {
            border-color: var(--v-primary-base) !important;
        }
        .condition-list {
            .v-list-item__content {
                flex: 1 0 auto;
            }
            .v-list-item__action-text {
                text-align: right;
            }
        }
        .nowrap {
            white-space: nowrap;
        }
        .hidden {
            visibility: hidden;
        }
        .disabled {
            opacity: 0.6;
        }
        .hint-text {
            opacity: 0.6;
        }
        .error-highlight {
            border: 2px solid var(--v-error-base);
            border-radius: 4px;
        }
        .link-btn,
        .plain-btn {
            text-transform: none;
        }
        .full-width {
            width: 100%;
        }
    }
</style>
