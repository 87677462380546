<template>
    <v-dialog v-model="dialog" :max-width="800" persistent>
        <v-card v-if="dialog">
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t('admin.editNotes') }}</v-toolbar-title>
            </v-toolbar>
            <div class="text-center ma-3">
                <em class="secondary--text">{{ $t('admin.prompts.editNotes') }}</em>
            </div>
            <div class="d-flex align-center ma-3">
                <v-text-field dense outlined hide-details v-model="note"></v-text-field>
                <v-btn color="primary" class="ml-3" @click="addNote()" :disabled="!note">
                    <v-icon left>fas fa-plus</v-icon>
                    {{ $t('admin.addNote') }}
                </v-btn>
            </div>
            <v-data-table :headers="headers" :items="notes" dense hide-default-footer class="mt-3">
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.edit="{ index }">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn small icon color="primary" @click.stop="removeNote(index)" v-bind="attrs" v-on="on">
                                <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('admin.deleteNote') }}</span>
                    </v-tooltip>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.timestamp="{ item: note }">
                    <span>{{ note.timestamp | date }}</span>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.user="{ item: note }">
                    <span>{{ note.user.name }}</span>
                </template>
            </v-data-table>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { get } from 'vuex-pathify';
    import { cloneDeep } from 'lodash';

    export default {
        data() {
            return {
                dialog: false,
                meta: null,
                notes: null,
                note: null,
                headers: [
                    { text: '', value: 'edit', align: 'center', width: '100px', sortable: false, divider: true },
                    { text: this.$i18n.t('admin.columns.timestamp'), value: 'timestamp' },
                    { text: this.$i18n.t('admin.columns.user'), value: 'user' },
                    { text: this.$i18n.t('admin.columns.note'), value: 'note', sortable: false }
                ],
                resolve: null,
                reject: null
            };
        },
        computed: {
            user: get('user')
        },
        methods: {
            show(meta) {
                this.dialog = true;
                this.meta = cloneDeep(meta || {});
                this.notes = cloneDeep(meta.notes || []);
                this.note = null;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            addNote() {
                this.notes.unshift({ user: this.user, timestamp: new Date(), note: this.note });
                this.note = null;
            },
            removeNote(index) {
                this.notes.splice(index, 1);
            },
            ok() {
                this.meta.notes = this.notes;
                this.resolve(this.meta);
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
