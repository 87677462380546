<template>
    <v-dialog v-model="dialog" :max-width="600" persistent>
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t('admin.resetPassword') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0">
                <v-text-field dense outlined v-model="password" :label="$t('admin.columns.password')" :hint="$t('admin.hints.leaveBlankToGenerate')" persistent-hint type="password"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { cloneDeep } from 'lodash';

    export default {
        data: () => ({
            dialog: false,
            password: null,
            resolve: null,
            reject: null
        }),
        methods: {
            show() {
                this.dialog = true;
                this.password = null;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            ok() {
                this.resolve(this.password);
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
