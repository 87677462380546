import NotesDialog from '@/components/researcher/NotesDialog';
import StudyDialog from '@/components/researcher/StudyDialog';
import TagsDialog from '@/components/researcher/TagsDialog';
import { sync } from 'vuex-pathify';
import { studyParameters, studyDays, isStudyLocked, LBS_TO_KG, IN_TO_CM, MGPERDL_TO_MMOLPERL, MGPERDL_TO_MGPERL } from '@/common';
import { filter, find, snakeCase } from 'lodash';
import moment from 'moment';

const ResearcherMixin = {
    components: { NotesDialog, StudyDialog, TagsDialog },
    data() {
        return {
            LBS_TO_KG,
            IN_TO_CM,
            heightMode: 'in',
            weightMode: 'lbs',
            waistMode: 'in',
            MGPERDL_TO_MMOLPERL,
            MGPERDL_TO_MGPERL,
            ldlMode: 'mg/dL',
            hdlMode: 'mg/dL',
            triglyceridesMode: 'mg/dL',
            totalCholesterolMode: 'mg/dL',
            hsCRPMode: 'mg/L'
        };
    },
    computed: {
        mode: sync('mode')
    },
    methods: {
        snakeCase,
        timestamp() {
            return moment().format('YYYY-MM-DD');
        },
        url(path) {
            return `${window.location.origin}${path}`;
        },
        async clipboard(text) {
            await navigator.clipboard.writeText(text);
        },
        async editData(patient) {
            await this.$store.dispatch('researcher/selectPatient', { patientId: patient.id, type: 'edit' });
            this.mode = 'editPatientData';
            this.$router.push('/profile');
        },
        deletePatient(patient) {
            return this.$root.$confirmationDialog
                .show({ title: this.$i18n.t('admin.prompts.deletePatient.title'), message: this.$i18n.t('admin.prompts.deletePatient.message') })
                .then(async () => {
                    await this.$api.delete(`/researcher/patient?patientId=${patient.id}`);
                    this.$store.commit('researcher/deletePatient', patient);
                })
                .catch(() => {});
        },
        editMeta(patient, dialog, inDialog) {
            return dialog
                .show(patient.meta)
                .then(async (meta) => {
                    const { data: _patient } = await this.$api.post(`/researcher/patient/meta?patientId=${patient.id}`, { meta });
                    this.$store.commit('researcher/updatePatient', _patient);
                    return _patient;
                })
                .catch(() => {})
                .finally(() => {
                    if (inDialog) {
                        document.documentElement.classList.add('overflow-y-hidden');
                    }
                });
        },
        editTags(patient, dialog, inDialog) {
            return dialog
                .show(patient.tags)
                .then(async (tags) => {
                    const { data: _patient } = await this.$api.post(`/researcher/patient/tags?patientId=${patient.id}`, { tags });
                    this.$store.commit('researcher/updatePatient', _patient);
                    return _patient;
                })
                .catch(() => {})
                .finally(() => {
                    if (inDialog) {
                        document.documentElement.classList.add('overflow-y-hidden');
                    }
                });
        },
        pushData(patient, study, inDialog) {
            const studyPush = {};
            studyPush[study.id] = true;
            this.$set(patient, 'activePush', studyPush);
            this.$set(patient, 'disablePush', true);
            return this.$api
                .get(`/researcher/patient/push?patientId=${patient.id}&study=${study.id}`)
                .then(() => {
                    this.$root.$confirmationDialog
                        .show({ title: this.$i18n.t('admin.prompts.push.title'), message: this.$i18n.t('admin.prompts.push.success'), showCancel: false })
                        .then(() => {
                            setTimeout(() => {
                                patient.disablePush = false;
                            }, 20000);
                        })
                        .finally(() => {
                            patient.activePush[study.id] = false;
                            if (inDialog) {
                                document.documentElement.classList.add('overflow-y-hidden');
                            }
                        });
                })
                .catch(() => {
                    this.$root.$confirmationDialog
                        .show({ title: this.$i18n.t('admin.prompts.push.title'), message: this.$i18n.t('admin.prompts.push.error'), showCancel: false })
                        .then(() => {
                            setTimeout(() => {
                                patient.disablePush = false;
                            }, 20000);
                        })
                        .finally(() => {
                            patient.activePush[study.id] = false;
                            if (inDialog) {
                                document.documentElement.classList.add('overflow-y-hidden');
                            }
                        });
                });
        },
        getTagValue(patient, key) {
            const tag = find(patient.tags, { name: key });
            if (tag) {
                return tag.value;
            }
            return null;
        },
        canPushData(patient, study) {
            return 0 === this.missingTags(patient, study.id).length && patient.reportAvailable && !patient.disablePush;
        },
        studies(patient) {
            if (patient.meta && patient.meta.studies) {
                return patient.meta.studies;
            }
            return [];
        },
        notesCount(patient) {
            if (patient.meta && patient.meta.notes) {
                return patient.meta.notes.length;
            }
            return 0;
        },
        missingTags(patient, studyId = null) {
            let missingTags = [];
            const patientStudies = this.studies(patient);
            const studies = studyId ? filter(patientStudies, { id: studyId }) : patientStudies;
            for (let study of studies) {
                const parameters = studyParameters(study.id);
                for (let tag of parameters.requiredTags) {
                    if (!this.getTagValue(patient, tag)) {
                        missingTags.push(tag);
                    }
                }
            }
            return missingTags;
        },
        isStudyLocked,
        studyDays,
        displayFeetInches(val) {
            if (val && 'in' === this.heightMode) {
                const feet = Math.floor(val / 12);
                const inches = Math.floor(val % 12);
                return `${feet}’ ${inches}”`;
            }
            return null;
        }
    }
};

export { ResearcherMixin };
