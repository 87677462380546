<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <div class="d-flex justify-space-between">
                <div class="text-h6 d-flex align-center">
                    <v-icon small left>fas fa-weight fa-fw</v-icon>
                    {{ $t('labels.metrics') }}
                </div>
                <v-icon small class="mx-2 secondary--text" v-if="isValid()">fas fa-check</v-icon>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2 mb-n2 py-3">
            <v-row>
                <v-col class="py-0">
                    <div class="my-3">
                        <v-row>
                            <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" class="py-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.height')"
                                    :factor="IN_TO_CM"
                                    :unit1="{ value: 'in', label: $t('units.in') }"
                                    :unit2="{ value: 'cm', label: $t('units.cm') }"
                                    v-model="height"
                                    value-unit="unit1"
                                    :mode.sync="heightMode"
                                    :alt-display-fn="displayFeetInches"
                                ></ConvertField>
                            </v-col>
                            <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" class="py-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.weight')"
                                    :factor="LBS_TO_KG"
                                    :unit1="{ value: 'lbs', label: $t('units.lbs') }"
                                    :unit2="{ value: 'kg', label: $t('units.kg') }"
                                    v-model="weight"
                                    value-unit="unit1"
                                    :mode.sync="weightMode"
                                ></ConvertField>
                            </v-col>
                            <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" class="py-2">
                                <ConvertField
                                    persistent-placeholder
                                    :label="$t('fields.waist')"
                                    :factor="IN_TO_CM"
                                    :unit1="{ value: 'in', label: $t('units.in') }"
                                    :unit2="{ value: 'cm', label: $t('units.cm') }"
                                    v-model="waist"
                                    value-unit="unit1"
                                    :mode.sync="waistMode"
                                ></ConvertField>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <div class="my-3">
                        <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.tobacco') }}</div>
                        <div class="d-flex justify-center">
                            <v-checkbox
                                v-for="(value, index) in yesNo"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="tobacco"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                                @change="checkTobacco()"
                            ></v-checkbox>
                        </div>
                        <div v-if="'Y' === tobacco">
                            <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.tobaccoPacksPerDay') }}</div>
                            <v-row no-gutters justify="center">
                                <v-col cols="3">
                                    <v-text-field dense outlined class="ma-0 mb-2" hide-details type="number" v-model.number="tobaccoPacksPerDay"></v-text-field>
                                </v-col>
                            </v-row>
                            <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.tobaccoYears') }}</div>
                            <v-row no-gutters justify="center">
                                <v-col cols="3">
                                    <v-text-field dense outlined class="ma-0 mb-2" hide-details type="number" v-model.number="tobaccoYears"></v-text-field>
                                </v-col>
                            </v-row>
                            <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.tobaccoQuitYear') }}</div>
                            <v-row no-gutters justify="center">
                                <v-col cols="3">
                                    <v-text-field dense outlined class="ma-0 mb-2" hide-details type="tel" v-mask="'####'" v-model.number="tobaccoQuitYear"></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="my-3">
                        <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.alcohol') }}</div>
                        <div class="d-flex justify-center">
                            <v-checkbox
                                v-for="(value, index) in yesNo"
                                :key="index"
                                class="mt-0 mb-2 mx-2"
                                hide-details
                                v-model="alcohol"
                                :label="valueLabel(value)"
                                :value="value"
                                on-icon="$radioOn"
                                off-icon="$radioOff"
                                @change="checkAlcohol()"
                            ></v-checkbox>
                        </div>
                        <div v-if="'Y' === alcohol">
                            <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.alcoholDrinksPerWeek') }}</div>
                            <v-row no-gutters justify="center">
                                <v-col cols="3">
                                    <v-text-field dense outlined class="ma-0 mb-2" hide-details type="number" v-model.number="alcoholDrinksPerWeek"></v-text-field>
                                </v-col>
                            </v-row>
                            <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.alcoholYears') }}</div>
                            <v-row no-gutters justify="center">
                                <v-col cols="3">
                                    <v-text-field dense outlined class="ma-0 mb-2" hide-details type="number" v-model.number="alcoholYears"></v-text-field>
                                </v-col>
                            </v-row>
                            <div class="text-subtitle-1 text-center primary--text">{{ $t('fields.alcoholQuitYear') }}</div>
                            <v-row no-gutters justify="center">
                                <v-col cols="3">
                                    <v-text-field dense outlined class="ma-0 mb-2" hide-details type="tel" v-mask="'####'" v-model.number="alcoholQuitYear"></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';
    import ConvertField from '@/components/ConvertField';
    import { yesNo, LBS_TO_KG, IN_TO_CM } from '@/common';

    export default {
        mixins: [ConditionsMixin, FamilyMemberMixin],
        components: { ConvertField },
        data: () => ({
            yesNo,
            LBS_TO_KG,
            IN_TO_CM
        }),
        computed: {
            height: sync('height'),
            weight: sync('weight'),
            waist: sync('waist'),

            heightMode: sync('heightMode'),
            weightMode: sync('weightMode'),
            waistMode: sync('waistMode'),

            tobacco: sync('tobacco'),
            tobaccoPacksPerDay: sync('tobaccoPacksPerDay'),
            tobaccoYears: sync('tobaccoYears'),
            tobaccoQuitYear: sync('tobaccoQuitYear'),

            alcohol: sync('alcohol'),
            alcoholDrinksPerWeek: sync('alcoholDrinksPerWeek'),
            alcoholYears: sync('alcoholYears'),
            alcoholQuitYear: sync('alcoholQuitYear')
        },
        methods: {
            isValid() {
                return this.$store.get('isMetricsValid', this.uuid);
            },
            checkTobacco() {
                if ('Y' !== this.tobacco) {
                    this.tobaccoPacksPerDay = null;
                    this.tobaccoYears = null;
                    this.tobaccoQuitYear = null;
                }
            },
            checkAlcohol() {
                if ('Y' !== this.alcohol) {
                    this.alcoholDrinksPerWeek = null;
                    this.alcoholYears = null;
                    this.alcoholQuitYear = null;
                }
            },
            displayFeetInches(val) {
                if (val && 'in' === this.heightMode) {
                    const feet = Math.floor(val / 12);
                    const inches = Math.floor(val % 12);
                    return `${feet}’ ${inches}”`;
                }
                return null;
            }
        }
    };
</script>
