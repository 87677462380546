<template>
    <v-dialog v-model="dialog" :max-width="600" persistent>
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t('admin.configure') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0" v-if="organization">
                <div class="mb-3">ID: <strong>{{ organization.id }}</strong></div>
                <v-text-field dense outlined v-model="organization.name" :label="$t('admin.columns.name')"></v-text-field>
                <v-text-field dense outlined v-model="organization.issPattern" :label="$t('admin.columns.issPattern')"></v-text-field>
                <v-text-field dense outlined v-model="organization.logo" :label="$t('admin.columns.logo')" hide-details></v-text-field>
                <img :height="imageHeight" class="my-2" :src="organization.logo" :alt="organization.name" />
                <div v-for="(study, index) in studies" :key="index">
                    <v-text-field dense outlined v-model="organization.meta[`email.${study}`]" :label="`${$t(`studies.${study}`)} - ${$t('admin.columns.supportEmail')}`"></v-text-field>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { studies } from '@/common';
    import { cloneDeep } from 'lodash';

    export default {
        data: () => ({
            studies,
            imageHeight: 100,
            dialog: false,
            organization: null,
            resolve: null,
            reject: null
        }),
        computed: {

        },
        methods: {
            show(organization) {
                this.dialog = true;
                this.organization = cloneDeep(organization);
                if (!this.organization.meta) {
                    this.organization.meta = {};
                }
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            ok() {
                this.resolve(this.organization);
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
