<template>
    <ConvertField
        :label="$t('fields.height')"
        :factor="IN_TO_CM"
        :unit1="{ value: 'in', label: $t('units.in') }"
        :unit2="{ value: 'cm', label: $t('units.cm') }"
        v-model="modelValue"
        value-unit="unit1"
        :mode.sync="unit"
        :alt-display-fn="displayFeetInches"
    ></ConvertField>
</template>

<script>
    import ConvertField from '@/components/ConvertField';
    import { IN_TO_CM } from '@/common';
    import { round } from 'lodash';

    export default {
        components: { ConvertField },
        props: {
            value: {
                type: Object,
                default: null
            }
        },
        data: () => ({
            IN_TO_CM,
            unit: 'in'
        }),
        computed: {
            modelValue: {
                get() {
                    return this.value?.value;
                },
                set(value) {
                    this.$emit('input', { value: round(value, 0), unit: 'in' });
                }
            }
        },
        methods: {
            displayFeetInches(val) {
                if (val && 'in' === this.unit) {
                    const feet = Math.floor(val / 12);
                    const inches = Math.floor(val % 12);
                    return `${feet}’ ${inches}”`;
                }
                return null;
            }
        }
    }
</script>
