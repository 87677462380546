<template>
    <v-card flat>
        <v-expansion-panels v-model="patientsPanel">
            <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-3">
                    <div class="d-flex justify-space-between">
                        <div class="text-h6 d-flex align-center">
                            <v-icon small left>fas fa-users fa-fw</v-icon>
                            {{ $t('provider.patients') }}
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="expansion-panel--padless">
                    <PatientList></PatientList>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-fab-transition>
            <v-card outlined class="mt-4" v-if="selectedPatient" :key="selectedPatient.id">
                <v-toolbar flat dark color="primary">
                    <v-toolbar-title>
                        <v-icon small left>fas fa-{{ person.gender }} fa-fw</v-icon>
                        {{ selectedPatientName }}
                    </v-toolbar-title>
                    <v-divider vertical inset class="mx-3"></v-divider>
                    <span class="text-subtitle-1">{{ $tc('common.years', person.age) }}</span>
                    <v-spacer></v-spacer>
                    <v-btn small outlined color="accent" @click="editData()">
                        <v-icon small left>fas fa-edit</v-icon>
                        {{ $t('admin.editData') }}
                    </v-btn>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn small text :href="`/api/provider/patient/cds?patientId=${selectedPatient.id}&pdf=patient_report_en`" target="_blank" :disabled="!reportAvailable" v-bind="attrs" v-on="on">
                                <v-icon small>fas fa-file-pdf</v-icon>
                                <v-icon small color="accent">fas fa-user</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('admin.patientReport') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn small text :href="`/api/provider/patient/cds?patientId=${selectedPatient.id}&pdf=physician_report`" target="_blank" :disabled="!reportAvailable" v-bind="attrs" v-on="on">
                                <v-icon small>fas fa-file-pdf</v-icon>
                                <v-icon small color="accent">fas fa-user-md</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('admin.providerReport') }}</span>
                    </v-tooltip>
                    <v-btn icon @click="selectedPatient = null"><v-icon>fas fa-times</v-icon></v-btn>
                </v-toolbar>
                <v-expansion-panels accordion multiple v-model="panels">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="grey lighten-3">
                            <div class="d-flex align-center justify-space-between">
                                <div class="d-flex align-center">
                                    <v-icon small left>fas fa-clipboard-list fa-fw</v-icon>
                                    {{ $t('labels.riskReport') }}
                                </div>
                                <v-spacer></v-spacer>
                                <span class="text-caption mx-3" v-if="refreshing">
                                    <v-icon small left>fas fa-spinner fa-spin</v-icon>
                                    {{ $t('common.refreshing') }}
                                </span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="py-4">
                            <div class="risk-report" v-html="report" v-collapse></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="grey lighten-3">
                            <div class="d-flex align-center justify-space-between">
                                <div class="d-flex align-center">
                                    <v-icon small left>fas fa-project-diagram fa-fw</v-icon>
                                    {{ $t('labels.pedigree') }}
                                </div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="expansion-panel--padless">
                            <Pedigree ref="pedigree" :family="selectedPatient.data.family"></Pedigree>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header color="grey lighten-3">
                            <div class="d-flex align-center justify-space-between">
                                <div class="d-flex align-center">
                                    <v-icon small left>fas fa-database fa-fw</v-icon>
                                    {{ $t('admin.data') }}
                                </div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="expansion-panel--padless">
                            <div class="d-flex align-center my-2">
                                <v-spacer></v-spacer>
                                <v-checkbox class="mx-2 mt-0" color="primary" hide-details v-model="details" :label="$t('admin.showMoreDetails')"></v-checkbox>
                                <v-checkbox class="mx-2 mt-0" color="primary" hide-details v-model="risk" :label="$t('admin.showRiskData')"></v-checkbox>
                                <v-spacer></v-spacer>
                            </div>
                            <PatientReadOnly v-for="person in selectedPatient.data.family" :key="person.uuid" :person="person" :details="details" :risk="risk" class="mb-2"></PatientReadOnly>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
        </v-fab-transition>
    </v-card>
</template>

<script>
    import PatientList from '@/components/provider/PatientList';
    import PatientReadOnly from '@/components/PatientReadOnly';
    import Pedigree from '@/components/Pedigree';

    import { allFamilyMembersValid } from '@/common';
    import { get, sync } from 'vuex-pathify';

    import goTo from 'vuetify/es5/services/goto';

    const headerClickListener = (el) => {
        const elClass = el.getAttribute('class');
        const siblingClass = el.nextElementSibling.getAttribute('class');
        if (/ collapsed/.test(elClass) || / collapsed/.test(siblingClass)) {
            el.setAttribute('class', elClass.replace(/ collapsed/, ''));
            el.nextElementSibling.setAttribute('class', siblingClass.replace(/ collapsed/, ''));
        } else {
            el.setAttribute('class', `${elClass} collapsed`);
            el.nextElementSibling.setAttribute('class', `${siblingClass} collapsed`);
        }
    };

    export default {
        components: { PatientList, PatientReadOnly, Pedigree },
        directives: {
            collapse: {
                inserted: (el) => {
                    const headers = el.querySelectorAll('.header');
                    headers.forEach((header) => {
                        const icon = document.createElement('I');
                        icon.setAttribute('class', 'fas fa-chevron-up expand-icon');
                        header.appendChild(icon);
                        const handler = headerClickListener.bind(null, header);
                        header.addEventListener('click', handler);
                        header._handler = handler;

                        const headerClass = header.getAttribute('class');
                        const siblingClass = header.nextElementSibling.getAttribute('class');
                        header.setAttribute('class', `${headerClass} collapsed`);
                        header.nextElementSibling.setAttribute('class', `${siblingClass} collapsed`);
                    });
                },
                unbind: (el) => {
                    const headers = el.querySelectorAll('.header');
                    headers.forEach((header) => {
                        header.removeEventListener('click', header._handler);
                        delete header._handler;
                    });
                }
            }
        },
        data: () => ({
            patientsPanel: 0,
            drawn: false,
            panels: [],
            risk: false,
            details: false
        }),
        computed: {
            refreshing: sync('provider/refreshing'),
            mode: sync('mode'),
            selectedPatient: sync('provider/selectedPatient'),
            selectedPatientName: get('provider/selectedPatientName'),
            person() {
                return this.selectedPatient?.data?.family[0];
            },
            report() {
                if (this.selectedPatient?.cds) {
                    return unescape(this.selectedPatient.cds.physician_report);
                }
                return null;
            },
            reportAvailable() {
                return this.selectedPatient?.data?.family?.length > 1 && allFamilyMembersValid(this.selectedPatient.data.family);
            }
        },
        watch: {
            panels(val) {
                if (val.indexOf(1) > -1 && !this.drawn) {
                    this.drawn = true;
                    setTimeout(() => this.$refs.pedigree.draw(), 100);
                }
            },
            selectedPatient: {
                handler(val) {
                    if (val) {
                        this.patientsPanel = null;
                    } else {
                        this.patientsPanel = 0;
                    }
                    this.$nextTick(() => {
                        goTo(0);
                        this.drawn = false;
                        this.panels = [];
                    });
                },
                immediate: true
            }
        },
        methods: {
            async editData() {
                await this.$store.dispatch('provider/selectPatient', { patientId: this.selectedPatient.id, type: 'edit' });
                this.mode = 'editPatientData';
                this.$router.push('/profile');
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    .risk-report {
        font-size: 14px;

        ::v-deep .header {
            color: white;
            background-color: var(--v-primary-base);
            font-size: 20px;
            margin-top: 20px;
            padding: 5px 10px;
            border-radius: $border-radius-root;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.collapsed .expand-icon {
                transform: scale(1, -1);
            }
        }
        ::v-deep .content,
        ::v-deep .reference {
            margin: 10px 15px;
            a {
                text-decoration: none;
            }
            a[href^="//"]:after,
            a[href^="http://"]:after,
            a[href^="https://"]:after
            {
                font-family: 'Font Awesome 5 Pro', sans-serif;
                content: ' \f35d ';
                font-size: small;
                margin-right: 5px;
            }
            h4 {
                color: var(--v-primary-base);
                font-weight: bold;
                margin-top: 10px;
            }
            ul {
                margin-left: 20px;
            }
            sup {
                color: var(--v-accent-darken1);
            }
            table {
                border-collapse: collapse;
                width: 100%;
                td {
                    border: 1px solid var(--v-primary-base);
                    padding: 0 5px;
                }
            }
            .addl-information {
                .more-information {
                    color: var(--v-accent-darken1);
                    font-weight: bold;
                }
                p {
                    margin: 10px 0;
                }
            }
            &.collapsed {
                display: none;
            }
        }
    }

    .form-data {
        th {
            width: 30%;
            text-align: right !important;
        }
    }
</style>
