<template>
    <v-card flat>
        <div v-if="'patient' === userType">
            <v-row align="center">
                <v-col cols="8">
                    <div class="text-h5 font-weight-bold primary--text">{{ $t('home.welcome') }}</div>
                </v-col>
                <v-col cols="4">
                    <v-select dense outlined hide-details v-model="language" :items="languages" :item-text="(item) => $t(`languages.${item.value}`)" :label="$t('fields.language')" @change="languageChanged()"></v-select>
                </v-col>
            </v-row>
            <v-card flat outlined class="my-6 pa-6 primary--border" v-if="organization">
                <v-img :max-width="200" :height="50" contain :src="organization.logo" :alt="organization.name" class="float-right"></v-img>
                <vue-markdown :anchorAttributes="anchorAttributes" :source="partnerMarkdown" class="markdown mr-3"></vue-markdown>
            </v-card>
            <p v-html="$t('home.intro1')"></p>
            <p v-html="$t('home.intro2')"></p>
            <p v-html="$t('home.intro3')"></p>
            <p v-html="$t('home.intro4')"></p>
            <div class="text-subtitle-1 font-weight-bold primary--text" v-html="$t('home.learnMore1')"></div>
            <div class="mx-6">
                <v-btn text color="primary" href="/doc/A Guide to Family Health History.pdf" target="_blank">
                    <v-icon small left color="secondary">fas fa-file-pdf</v-icon>
                    {{ $t('pdf.guide') }}
                </v-btn>
                <v-btn text color="primary" href="/doc/Why and How for FHH MeTree.pdf" target="_blank">
                    <v-icon small left color="secondary">fas fa-file-pdf</v-icon>
                    {{ $t('pdf.whyAndHow') }}
                </v-btn>
            </div>
            <div class="text-subtitle-1 font-weight-bold primary--text" v-html="$t('home.watch')"></div>
            <div class="video" v-if="videoId">
                <youtube :video-id="videoId" :player-vars="{ autoplay: 1, modestbranding: 1, rel: 0 }" @ended="ended" ref="youtube"></youtube>
            </div>
            <div class="mx-6">
                <v-btn text color="primary" @click="play('dnhN7Se-I8Y')">
                    <v-icon small left color="secondary">fab fa-youtube</v-icon>
                    {{ $t('video.what') }}
                </v-btn>
                <v-btn text color="primary" @click="play('vgAYeBbp9mQ')">
                    <v-icon small left color="secondary">fab fa-youtube</v-icon>
                    {{ $t('video.how') }}
                </v-btn>
                <v-btn text color="primary" @click="play('CCJ3AjAO3LY')">
                    <v-icon small left color="secondary">fab fa-youtube</v-icon>
                    {{ $t('video.concerns') }}
                </v-btn>
            </div>
            <div class="text-subtitle-1 font-weight-bold primary--text" v-html="$t('home.learnMore2')"></div>
            <div class="mx-6">
                <v-btn text color="primary" href="/doc/Data Collection Instructions MeTree 3.0.pdf" target="_blank">
                    <v-icon small left color="secondary">fas fa-file-pdf</v-icon>
                    {{ $t('pdf.instructions') }}
                </v-btn>
            </div>
            <v-divider class="my-6"></v-divider>
            <p v-html="$t('home.intro5', { href: helpEmailHref, email: helpEmailOptions.to })"></p>
            <div class="my-6 d-flex justify-center">
                <v-btn x-large to="/profile" color="primary" v-if="!studyLocked">
                    {{ $t('common.continue') }}
                    <v-icon right>fas fa-arrow-right</v-icon>
                </v-btn>
            </div>
        </div>
        <div v-else-if="'practitioner' === userType">
            <Dashboard></Dashboard>
        </div>
        <div v-else-if="'researcher' === userType">
            <Patients></Patients>
        </div>
    </v-card>
</template>

<script>
    import Dashboard from '@/components/provider/Dashboard';
    import Patients from '@/components/researcher/Patients';
    import VueMarkdown from '@adapttive/vue-markdown';
    import { languages } from '@/common';
    import { get, sync } from 'vuex-pathify';

    const DEFAULT_HELP_EMAIL = 'support_familyhistory@duke.edu';

    export default {
        name: 'Home',
        components: { Dashboard, Patients, VueMarkdown },
        data: () => ({
            languages: languages.map((item) => ({ value: item })),
            anchorAttributes: {
                target: '_blank'
            },
            videoId: null
        }),
        computed: {
            study: get('patient/study'),
            studyLocked: get('patient/studyLocked'),
            organization: get('organization'),
            helpEmailOptions() {
                if (this.study && this.organization && this.organization.meta) {
                    const email = this.organization.meta[`email.${this.study.id}`];
                    if (email) {
                        return { to: email, subject: encodeURIComponent(`[${this.$i18n.t(`studies.${this.study.id}`)}] ${this.$i18n.t('email.help.subject')}`) };
                    }
                    return { to: DEFAULT_HELP_EMAIL, subject: encodeURIComponent(`[${this.$i18n.t(`studies.${this.study.id}`)}] ${this.$i18n.t('email.help.subject')}`) };
                }
                return { to: DEFAULT_HELP_EMAIL, subject: encodeURIComponent(`${this.$i18n.t('email.help.subject')}`) };
            },
            helpEmailHref() {
                return `mailto:${this.helpEmailOptions.to}?subject=${this.helpEmailOptions.subject}`;
            },
            partnerMarkdown() {
                if (this.study) {
                    const markdown = this.$i18n.t(`studies._intro.${this.study.id}`, { partner: this.organization?.name });
                    if (markdown) {
                        return markdown;
                    }
                }
                return this.$i18n.t('home.partner', { partner: this.organization?.name });
            },
            language: sync('family@0.language'),
            userType: get('userType'),
            organization: get('organization'),
            player() {
                return this.$refs.youtube.player;
            }
        },
        mounted() {
            //
        },
        methods: {
            languageChanged() {
                if ('patient' === this.userType) {
                    this.$root.$i18n.locale = this.language;
                }
            },
            play(videoId) {
                this.videoId = videoId;
                this.$nextTick(() => {
                    this.player.playVideo();
                });
            },
            ended() {
                this.videoId = null;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .markdown {
        ::v-deep p:last-child {
            margin-bottom: 0;
        }
        ::v-deep ul {
            margin-top: -16px;
            margin-bottom: 16px;
        }
    }
</style>
