<template>
    <v-dialog v-model="dialog" :max-width="800" persistent>
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t('admin.editTags') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0">
                <v-row no-gutters class="align-center">
                    <v-col class="mx-2">{{ $t('admin.name') }}</v-col>
                    <v-col class="mx-2">{{ $t('admin.value') }}</v-col>
                    <v-col class="mx-2" cols="auto">
                        <v-btn small icon class="hidden">
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters class="align-start" v-for="(tag, index) in tags" :key="index">
                    <v-col class="mx-2">
                        <v-text-field dense outlined v-model="tag.name" hide-details @change="checkTags()"></v-text-field>
                        <div v-if="!tag.name">
                            <v-chip x-small label class="mr-1" @click="tag.name = 'redcap'; checkTags()">redcap</v-chip>
                            <v-chip x-small label class="mr-1" @click="tag.name = 'participant_lab_id'; checkTags()">participant_lab_id</v-chip>
                            <v-chip x-small label class="mr-1" @click="tag.name = 'classification'; tag.value = 'pediatric'; checkTags()">classification:pediatric</v-chip>
                            <v-chip x-small label class="mr-1" @click="tag.name = 'report_available'; tag.value = 'Y'; checkTags()">report_available:Y</v-chip>
                            <v-chip x-small label class="mr-1" @click="tag.name = 'MRN'; checkTags()">MRN</v-chip>
                        </div>
                    </v-col>
                    <v-col class="mx-2">
                        <v-text-field dense outlined v-model="tag.value" hide-details></v-text-field>
                    </v-col>
                    <v-col class="mx-2" cols="auto">
                        <v-btn small icon :class="{ hidden: index === tags.length - 1 && !tag.name && !tag.value }" @click="removeTag(index)">
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { cloneDeep } from 'lodash';

    export default {
        data: () => ({
            dialog: false,
            tags: null,
            resolve: null,
            reject: null
        }),
        methods: {
            show(tags) {
                this.dialog = true;
                this.tags = cloneDeep(tags || []).concat([{ name: '', value: '' }]);
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            checkTags() {
                if (this.tags.every((tag) => !!tag.name)) {
                    this.tags.push({ name: '', value: '' });
                }
            },
            removeTag(index) {
                this.tags.splice(index, 1);
                this.checkTags();
            },
            ok() {
                this.resolve(this.tags.filter((tag) => !!tag.name));
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
