<template>
    <v-row no-gutters id="tour-family-sides">
        <v-col cols="12">
            <v-card tile>
                <v-toolbar dense flat tile dark color="accent">
                    <div class="text-subtitle-1">{{ $t('labels.mothersSide') }}</div>
                    <v-spacer></v-spacer>
                    <div id="tour-family-side-maternal">
                        <v-btn small text outlined class="ml-1 btn-outline-solid-white" @click="addMaternalAunt()"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.aunt') }}</v-btn>
                        <v-btn small text outlined class="ml-1 btn-outline-solid-white" @click="addMaternalUncle()"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.uncle') }}</v-btn>
                    </div>
                </v-toolbar>
            </v-card>
            <FamilySublist :sublist="maternalFamily" color="accent lighten-3"></FamilySublist>
        </v-col>
        <v-col cols="12">
            <v-card tile>
                <v-toolbar dense flat tile dark color="secondary">
                    <div class="text-subtitle-1">{{ $t('labels.fathersSide') }}</div>
                    <v-spacer></v-spacer>
                    <div id="tour-family-side-paternal">
                        <v-btn small text outlined class="ml-1 btn-outline-solid-white" @click="addPaternalAunt()"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.aunt') }}</v-btn>
                        <v-btn small text outlined class="ml-1 btn-outline-solid-white" @click="addPaternalUncle()"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.uncle') }}</v-btn>
                    </div>
                </v-toolbar>
            </v-card>
            <FamilySublist :sublist="paternalFamily" color="secondary lighten-2"></FamilySublist>
        </v-col>
        <v-col cols="12">
            <v-card tile>
                <v-toolbar dense flat tile dark color="primary">
                    <div class="text-subtitle-1">{{ $t('labels.selfSide') }}</div>
                    <v-spacer></v-spacer>
                    <div id="tour-family-side-self">
                        <v-btn small text outlined class="ml-1 btn-outline-solid-white" @click="addSister()"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.sister') }}</v-btn>
                        <v-btn small text outlined class="ml-1 btn-outline-solid-white" @click="addBrother()"><v-icon left small>fas fa-plus</v-icon>{{ $t('family.brother') }}</v-btn>
                    </div>
                </v-toolbar>
            </v-card>
            <FamilySublist :sublist="selfFamily" color="primary lighten-5"></FamilySublist>
        </v-col>
    </v-row>
</template>

<script>
    import { FamilyMixin } from '@/components/FamilyMixin';
    import FamilySublist from '@/components/FamilySublist';

    export default {
        mixins: [FamilyMixin],
        components: { FamilySublist }
    };
</script>
