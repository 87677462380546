<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <div class="d-flex justify-space-between align-center">
                <div class="text-h6 d-flex align-center">
                    <v-icon small left>fas fa-user-tag fa-fw</v-icon>
                    {{ $t('labels.general') }}
                </div>
                <v-icon small class="mx-2 secondary--text" v-if="_isValid">fas fa-check</v-icon>
                <div class="mx-2 text-right" v-else>
                    <span>{{ $t('common.required') }}:</span>
                    <span v-for="(field, index) in required" :key="index">
                        <em v-if="index > 0">,</em>
                        <span class="ml-1">
                            <span v-if="isArray(field)">
                                <span v-for="(f, fIndex) in field" :key="fIndex">
                                    <em class="secondary--text" v-if="fIndex > 0"> {{ $t('common.or') }} </em>
                                    <span class="error--text">{{ $t(`fields.${f}`) }}</span>
                                </span>
                            </span>
                            <span class="error--text" v-else>{{ $t(`fields.${field}`) }}</span>
                        </span>
                    </span>
                </div>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-6 py-3">
            <v-row v-if="isSelf">
                <v-col class="py-0">
                    <v-text-field dense outlined persistent-placeholder v-model="firstName" :label="$t('fields.firstName')"></v-text-field>
                </v-col>
                <v-col class="py-0">
                    <v-text-field dense outlined persistent-placeholder v-model="lastName" :label="$t('fields.lastName')"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="!isSelf">
                <v-col class="py-0">
                    <v-text-field dense outlined persistent-placeholder v-model="nickname" :label="$t('fields.nickname')"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="isSelf">
                <v-col class="py-0">
                    <v-select
                        dense
                        outlined
                        persistent-placeholder
                        v-model="gender"
                        :items="genders"
                        :item-text="(item) => $t(`genders.${item.value}`)"
                        :label="`${$t('fields.gender')}${required.indexOf('gender') > -1 ? '*' : ''}`"
                        :error="required.indexOf('gender') > -1"
                        @change="genderIdentity = gender"
                    ></v-select>
                </v-col>
                <v-col class="py-0">
                    <v-select dense outlined persistent-placeholder v-model="genderIdentity" :items="genderIdentities" :item-text="(item) => $t(`genderIdentities.${item.value}`)" :label="$t('fields.genderIdentity')"></v-select>
                </v-col>
            </v-row>
            <v-row v-if="isSelf">
                <v-col class="py-0">
                    <DobPicker persistent-placeholder v-model="birthDateAge" :is-self="isSelf" :is-deceased="isDeceased" :label="$t('fields.birthDate')" :error="required.indexOf('birthDate') > -1"></DobPicker>
                </v-col>
                <v-col class="py-0">
                    <v-select dense outlined persistent-placeholder v-model="language" :items="languages" :item-text="(item) => $t(`languages.${item.value}`)" :label="$t('fields.language')" @change="languageChanged()"></v-select>
                </v-col>
            </v-row>
            <v-row v-if="!isSelf">
                <v-col class="py-0">
                    <v-select
                        dense
                        outlined
                        persistent-placeholder
                        v-model="living"
                        :items="livings"
                        :item-text="(item) => $t(`livings.${item.value}`)"
                        :label="`${$t('fields.living')}${required.indexOf('living') > -1 ? '*' : ''}`"
                        :error="required.indexOf('living') > -1"
                        @change="checkLiving()"
                    ></v-select>
                </v-col>
                <v-col class="py-0">
                    <DobPicker
                        persistent-placeholder
                        v-model="birthDateAge"
                        :is-self="isSelf"
                        :is-deceased="isDeceased"
                        :label="$t('fields.birthDate')"
                        :error="!!required.find((element) => Array.isArray(element) && element.indexOf('birthDate') > -1 && element.indexOf('age') > -1)"
                    ></DobPicker>
                </v-col>
            </v-row>
            <div class="text-caption hint-text mt-n6 mb-4" v-if="!isSelf && !isDeceased">{{ $t('hints.birthDateAge') }}</div>
            <v-card outlined class="mb-4 px-6 pt-6 pb-4" v-if="isDeceased">
                <div class="mb-12 text-center">
                    <div class="my-n4" :class="{ 'error-highlight': required.indexOf('ageAtDeath') > -1 }">
                        <v-row no-gutters class="mb-4">
                            <v-col cols="8" offset="2">
                                <div class="text-h6 text-center primary--text">{{ `${$t('fields.ageAtDeath')}${required.indexOf('ageAtDeath') > -1 ? '*' : ''}` }}</div>
                            </v-col>
                            <v-col cols="2" class="text-right">
                                <v-checkbox class="my-0 mx-2" dense hide-details v-model="ageAtDeathUnknown" :label="$t('common.unknown')" @change="ageAtDeathUnknownChange"></v-checkbox>
                            </v-col>
                        </v-row>
                        <AgeRange v-model="ageAtDeath" :max="100"></AgeRange>
                        <div class="text-subtitle-1 hint-text mt-4">{{ $t('hints.ageSlider') }}</div>
                    </div>
                </div>
                <v-select
                    dense
                    outlined
                    persistent-placeholder
                    v-model="causeOfDeath"
                    :hint="$t('hints.causeOfDeath')"
                    persistent-hint
                    :items="causesOfDeathWithConditions"
                    :item-text="causeOfDeathText"
                    :label="`${$t('fields.causeOfDeath')}${required.indexOf('causeOfDeath') > -1 ? '*' : ''}`"
                    :error="required.indexOf('causeOfDeath') > -1"
                ></v-select>
            </v-card>
            <v-row v-if="isSelf">
                <v-col class="col-sm-6 py-0">
                    <v-textarea dense outlined persistent-placeholder v-model="line" :label="$t('fields.line')" hide-details rows="1" auto-grow></v-textarea>
                    <v-row no-gutters class="flex-md-nowrap">
                        <v-col class="mt-2">
                            <v-text-field dense outlined persistent-placeholder v-model="city" :label="$t('fields.city')" hide-details class="address-field city"></v-text-field>
                        </v-col>
                        <v-col class="col-sm-auto mt-2">
                            <v-select
                                dense
                                outlined
                                persistent-placeholder
                                v-model="state"
                                :items="regions"
                                :label="$t(`fields.${'US' === country ? 'state' : 'CA' === country ? 'province' : 'region'}`)"
                                hide-details
                                class="address-field state"
                            >
                                <template #selection="{ item }">{{ item.value }}</template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="flex-md-nowrap">
                        <v-col class="col-sm-auto mt-2">
                            <v-text-field
                                dense
                                outlined
                                persistent-placeholder
                                v-model="postalCode"
                                :label="$t('fields.postalCode')"
                                maxlength="10"
                                @input="(val) => (state = state.toUpperCase())"
                                hide-details
                                class="address-field postalCode"
                            ></v-text-field>
                        </v-col>
                        <v-col class="mt-2">
                            <v-select dense outlined persistent-placeholder v-model="country" :items="countries" :label="$t('fields.country')" @input="() => (state = '')" class="address-field country"></v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="col-sm-6 py-0">
                    <v-text-field dense outlined persistent-placeholder v-model="email" :label="$t('fields.email')" type="email" hide-details></v-text-field>
                    <v-text-field dense outlined persistent-placeholder v-model="phone" :label="$t('fields.phone')" type="tel" v-mask="'###-###-####'" hide-details class="mt-2"></v-text-field>
                    <v-select dense outlined persistent-placeholder v-model="insurance" :items="insurances" :item-text="(item) => $t(`insurances.${item.value}`)" :label="$t('fields.insurance')" class="mt-2"></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <div class="d-flex justify-space-between align-center">
                        <v-checkbox v-model="adopted" :label="$t('fields.adopted')" hide-details class="mt-0 pt-0"></v-checkbox>
                        <Hint ref="adoptedHint" :text="$t('hints.adopted')" :title="$t('fields.adopted')"></Hint>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-1">
                    <v-checkbox v-model="cosanguineousParents" :label="$t('fields.cosanguineousParents')" hide-details class="mt-0 pt-0"></v-checkbox>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import AgeRange from '@/components/AgeRange';
    import DobPicker from '@/components/DobPicker';
    import { insurances, genders, genderIdentities, languages, livings, causesOfDeath, calculateAge, countries, regionsByCountryCode } from '@/common';
    import { get, sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';
    import { FamilyMixin } from '@/components/FamilyMixin';
    import { get as _get } from 'vuex-pathify';
    import { debounce, isArray, isNumber } from 'lodash';

    export default {
        components: { AgeRange, DobPicker },
        mixins: [FamilyMemberMixin, FamilyMixin],
        data: () => ({
            countries: countries.map((country) => ({ value: country.countryShortCode, text: country.countryName })),
            insurances: insurances.map((item) => ({ value: item })),
            genders: genders.map((item) => ({ value: item })),
            genderIdentities: genderIdentities.map((item) => ({ value: item })),
            languages: languages.map((item) => ({ value: item })),
            livings: livings.map((item) => ({ value: item })),
            causesOfDeath: causesOfDeath.map((item) => ({ value: item }))
        }),
        computed: {
            required() {
                return this.$store.get('generalRequired', this.uuid);
            },
            _isValid() {
                return this.$store.get('isGeneralValid', this.uuid);
            },
            userType: _get('userType'),
            nickname: sync('nickname'),
            firstName: sync('firstName'),
            lastName: sync('lastName'),
            gender: sync('gender'),
            genderIdentity: sync('genderIdentity'),
            birthDate: sync('birthDate'),
            age: sync('age'),
            birthDateAge: {
                get() {
                    return { birthDate: this.birthDate, age: this.age };
                },
                set(value) {
                    this.birthDate = value.birthDate;
                    this.age = value.age;
                }
            },
            language: sync('language'),
            living: sync('living'),
            ageAtDeath: sync('ageAtDeath'),
            ageAtDeathUnknown: sync('ageAtDeathUnknown'),
            causeOfDeath: sync('causeOfDeath'),
            adopted: sync('adopted'),
            cosanguineousParents: sync('cosanguineousParents'),
            line: sync('line'),
            city: sync('city'),
            state: sync('state'),
            postalCode: sync('postalCode'),
            country: sync('country'),
            regions() {
                return (regionsByCountryCode[this.country] || []).map((region) => {
                    return { value: region.shortCode, text: region.name };
                });
            },
            phone: sync('phone'),
            email: sync('email'),
            insurance: sync('insurance'),
            conditions: get('conditions'),
            causesOfDeathWithConditions() {
                return this.conditions.map((condition) => ({ value: `condition:${condition.id}` })).concat(this.causesOfDeath);
            },
            isDeceased() {
                return 'Deceased' === this.living;
            }
        },
        watch: {
            adopted(val) {
                if (val) {
                    this.$refs.adoptedHint.trigger();
                }
            },
            age: {
                handler: debounce(function (val) {
                    if (val > 120 && !this.isDeceased) {
                        this.$root.$confirmationDialog
                            .show({ title: this.$i18n.t('prompts.ageGt120.title'), message: this.$i18n.t('prompts.ageGt120.message'), showCancel: false })
                            .then(() => {})
                            .catch(() => {});
                        return;
                    }
                    for (const parent of this.parents(this.person)) {
                        if (parent.age && val && parent.age - 15 < val) {
                            this.$root.$confirmationDialog
                                .show({ title: this.$i18n.t('prompts.ageLt15parent.title'), message: this.$i18n.t('prompts.ageLt15parent.message'), showCancel: false })
                                .then(() => {})
                                .catch(() => {});
                            return;
                        }
                    }
                    for (const child of this.children(this.person)) {
                        if (child.age && val && child.age + 15 > val) {
                            this.$root.$confirmationDialog
                                .show({ title: this.$i18n.t('prompts.ageLt15child.title'), message: this.$i18n.t('prompts.ageLt15child.message'), showCancel: false })
                                .then(() => {})
                                .catch(() => {});
                            return;
                        }
                    }
                }, 3000)
            },
            ageAtDeath(val) {
                if (isNumber(val)) {
                    this.ageAtDeathUnknown = false;
                }
            }
        },
        mounted() {
            if (this.isSelf && !this.country) {
                this.country = 'US';
            }
        },
        methods: {
            isArray,
            ageAtDeathUnknownChange() {
                if (this.ageAtDeathUnknown) {
                    this.ageAtDeath = null;
                }
            },
            languageChanged() {
                if ('patient' === this.userType) {
                    this.$root.$i18n.locale = this.language;
                }
            },
            checkLiving() {
                if (!this.isDeceased) {
                    this.ageAtDeath = null;
                    this.causeOfDeath = null;
                    this.age = this.birthDate ? calculateAge(this.birthDate) : this.age;
                } else {
                    this.age = null;
                }
            },
            causeOfDeathText(item) {
                if (/^condition:/.test(item.value)) {
                    const key = item.value.replace(/^condition:/, '');
                    return this.$i18n.t(`conditions.${key}.name`);
                }
                return this.$i18n.t(`causesOfDeath.${item.value}`);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .address-field {
        &.city,
        &.country,
        &.state,
        &.postalCode {
            min-width: 100px;
        }
        &.state,
        &.postalCode {
            @media screen and (min-width: 600px) {
                max-width: 100px;
            }
        }
    }
</style>
