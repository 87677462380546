<template>
    <v-dialog v-model="dialog" :max-width="800" persistent>
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-toolbar-title v-if="mode">{{ $t(`labels.${mode}Twins`) }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="ok()" v-if="'view' === mode">
                    <v-icon small>fas fa-times</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="px-6 pt-2 pb-0" v-if="'add' === mode">
                <v-row>
                    <v-col cols="6">
                        <v-list subheader rounded>
                            <v-list-item-group v-model="selected" multiple color="primary">
                                <v-virtual-scroll :bench="8" :items="familyList" :item-height="64" :max-height="416">
                                    <template #default="{ item: person }">
                                        <v-list-item :value="person.uuid" :disabled="!personEnabled(person.uuid)">
                                            <template #default="{ active }">
                                                <v-list-item-avatar>
                                                    <v-icon :class="iconClass(person)">{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ person.nickname }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ $t(`relations.${person.relation}`) }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-checkbox :input-value="active"></v-checkbox>
                                                </v-list-item-action>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-virtual-scroll>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-col cols="6">
                        <v-card class="mb-3">
                            <v-toolbar dense dark color="primary">
                                <v-toolbar-title>{{ $t('labels.fraternal') }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn small text outlined class="btn-outline-solid-white" @click="addSelected(fraternal)" :disabled="!selected.length">
                                    <v-icon left small>fas fa-plus</v-icon>
                                    {{ $t('labels.addSelected') }}
                                </v-btn>
                            </v-toolbar>
                            <v-list-item v-for="person in fraternal" :key="person.uuid" :value="person.uuid">
                                <v-list-item-avatar>
                                    <v-icon :class="iconClass(person)">{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ person.nickname }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t(`relations.${person.relation}`) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon small color="primary" @click.stop="removePerson(person, fraternal)"><v-icon small>fas fa-trash</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-card>
                        <v-card class="mb-3">
                            <v-toolbar dense dark color="primary">
                                <v-toolbar-title>{{ $t('labels.identical') }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn small text outlined class="btn-outline-solid-white" @click="addSelected(identical)" :disabled="!identicalValid()">
                                    <v-icon left small>fas fa-plus</v-icon>
                                    {{ $t('labels.addSelected') }}
                                </v-btn>
                            </v-toolbar>
                            <v-list-item v-for="person in identical" :key="person.uuid" :value="person.uuid">
                                <v-list-item-avatar>
                                    <v-icon :class="iconClass(person)">{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ person.nickname }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t(`relations.${person.relation}`) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon small color="primary" @click.stop="removePerson(person, identical)"><v-icon small>fas fa-trash</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="px-6 pt-2 pb-0" v-else>
                <v-row class="justify-center">
                    <v-col cols="6">
                        <v-list subheader>
                            <v-list-item v-for="person in twinsList" :key="person.uuid">
                                <v-list-item-avatar>
                                    <v-icon :class="iconClass(person)">{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ person.nickname }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t(`relations.${person.relation}`) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action-text>
                                    <span class="text-subtitle-1">{{ $t(`labels.${twinType(person.uuid)}`) }}</span>
                                </v-list-item-action-text>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <Hint :text="$t('hints.twinsDialog')"></Hint>
                <v-btn text color="primary" @click="removeTwins()" v-if="'view' === mode">
                    <v-icon left>fas fa-trash</v-icon>
                    {{ $t('labels.removeTwins') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()" :disabled="!valid">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()" v-if="'add' === mode">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { FamilyMixin } from '@/components/FamilyMixin';
    import { cloneDeep, filter, findIndex, includes, uniq } from 'lodash';

    const vaildPairings = [/SELF|NSIS|NBRO/, /HSIS|HBRO/, /DAU|SON/, /GRNDDAU|GRNDSON/, /NMTH|MAUNT|MUNCLE/, /NFTH|PAUNT|PUNCLE/, /NIECE|NEPHEW/, /MCOUSN/, /PCOUSN/];

    export default {
        mixins: [FamilyMixin],
        data: () => ({
            dialog: false,
            mode: null,
            resolve: null,
            reject: null,
            selected: [],
            fraternal: [],
            identical: [],
            multiple: null
        }),
        computed: {
            valid() {
                return 'view' === this.mode || this.fraternal.length > 1 || this.identical.length > 1;
            },
            allUuids() {
                return this.selected.concat(this.fraternal.map((person) => person.uuid)).concat(this.identical.map((person) => person.uuid));
            },
            pairing() {
                if (this.allUuids && this.allUuids.length) {
                    const person = this.getFamilyMember(this.allUuids[0]);
                    for (const paring of vaildPairings) {
                        if (paring.test(person.relation)) {
                            return paring;
                        }
                    }
                }
                return null;
            },
            selectedFamilyMembers() {
                return this.selected.map((uuid) => this.getFamilyMember(uuid));
            },
            familyList() {
                return filter(this.maternalFamily.concat(this.paternalFamily).concat(this.selfFamily), (person) => {
                    return !/^.GR.TH$/.test(person.relation) && !person.multiple;
                });
            },
            twinsList() {
                if (this.multiple) {
                    return this.multiple.fraternal.concat(this.multiple.identical).map((uuid) => this.getFamilyMember(uuid));
                }
                return null;
            }
        },
        methods: {
            show() {
                this.dialog = true;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            add() {
                this.mode = 'add';
                return this.show();
            },
            view(multiple) {
                this.mode = 'view';
                this.multiple = multiple;
                return this.show();
            },
            isChosen(uuid) {
                return findIndex(this.fraternal, (person) => uuid === person.uuid) > -1 || findIndex(this.identical, (person) => uuid === person.uuid) > -1;
            },
            sameParents(uuid) {
                if (this.allUuids && this.allUuids.length) {
                    const personA = this.getFamilyMember(this.allUuids[0]);
                    const personB = this.getFamilyMember(uuid);
                    return personA.mother === personB.mother && personA.father === personB.father;
                }
                return true;
            },
            personEnabled(uuid) {
                return !this.isChosen(uuid) && (this.pairing ? this.sameParents(uuid) : true);
            },
            sameGenderSelected() {
                return uniq(this.selectedFamilyMembers.map((person) => person.gender).concat(this.identical.map((person) => person.gender))).length < 2;
            },
            identicalValid() {
                return this.selected.length && this.sameGenderSelected();
            },
            addSelected(list) {
                this.selected.forEach((uuid) => {
                    list.push(cloneDeep(this.getFamilyMember(uuid)));
                });
                this.selected = [];
            },
            removePerson(person, list) {
                const index = findIndex(list, { uuid: person.uuid });
                if (index !== -1) {
                    list.splice(index, 1);
                }
            },
            twinType(uuid) {
                return includes(this.multiple.fraternal, uuid) ? 'fraternal' : 'identical';
            },
            removeTwins() {
                this.$root.$confirmationDialog
                    .show({ title: this.$i18n.t('prompts.removeTwins.title'), message: this.$i18n.t('prompts.removeTwins.message') })
                    .then(() => {
                        this.$store.commit('removeTwins', this.multiple);
                        this.ok();
                    })
                    .catch(() => {});
            },
            reset() {
                this.selected = [];
                this.fraternal = [];
                this.identical = [];
                this.multiple = null;
            },
            ok() {
                if (this.valid) {
                    this.resolve({ fraternal: this.fraternal, identical: this.identical });
                    this.dialog = false;
                    this.mode = null;
                    this.reset();
                }
            },
            cancel() {
                this.reject();
                this.dialog = false;
                this.mode = null;
                this.reset();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-list-item--disabled {
        opacity: 0.2;
    }
</style>
