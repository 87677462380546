import store from './store';
import axios from 'axios';
import _ from 'lodash';

axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

const api = axios.create({ baseURL: '/api' });
api.interceptors.response.use(
    (response) => {
        const sessionExpires = response.headers['x-session-expires'];
        if (sessionExpires) {
            store.set('sessionExpires', sessionExpires);
        }
        return response;
    },
    (error) => {
        store.set('error', { error });
        return Promise.reject(error);
    }
);

export default api;
