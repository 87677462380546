<template>
    <v-dialog v-model="dialog" :max-width="600" persistent>
        <v-card>
            <v-toolbar dense dark :color="toolbarColor">
                <v-toolbar-title>
                    <v-icon left v-if="toolbarIcon">{{ toolbarIcon }}</v-icon>
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0">
                <p class="text-subtitle-1" v-html="message"></p>
            </v-card-text>
            <v-sheet class="d-flex flex-wrap justify-end pa-3">
                <v-sheet class="me-auto"></v-sheet>
                <v-sheet class="mt-1 ml-1 action-button">
                    <v-btn block :class="okClass" :color="okColor" @click="ok()">
                        <v-icon left v-if="okIcon">{{ okIcon }}</v-icon>
                        {{ $t(okLabel) }}
                    </v-btn>
                </v-sheet>
                <v-sheet class="mt-1 ml-1 action-button" v-if="showCancel">
                    <v-btn block :class="cancelClass" outlined :color="cancelColor" @click="cancel()">
                        <v-icon left v-if="cancelIcon">{{ cancelIcon }}</v-icon>
                        {{ $t(cancelLabel) }}
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        data: () => ({
            dialog: false,
            title: null,
            message: null,
            showCancel: true,
            okLabel: null,
            okColor: null,
            okIcon: null,
            okClass: null,
            cancelLabel: null,
            cancelColor: null,
            cancelIcon: null,
            cancelClass: null,
            toolbarColor: null,
            toolbarIcon: null,
            resolve: null,
            reject: null
        }),
        methods: {
            show({
                title,
                message,
                showCancel = true,
                okLabel = 'common.ok',
                okColor = 'primary',
                okIcon = 'fas fa-check',
                okClass = '',
                cancelLabel = 'common.cancel',
                cancelColor = 'default',
                cancelIcon = 'fas fa-times',
                cancelClass = '',
                toolbarColor = 'primary',
                toolbarIcon = null
            }) {
                this.dialog = true;

                this.title = title;
                this.message = message;
                this.showCancel = showCancel;
                this.okLabel = okLabel;
                this.okColor = okColor;
                this.okIcon = okIcon;
                this.okClass = okClass;
                this.cancelLabel = cancelLabel;
                this.cancelColor = cancelColor;
                this.cancelIcon = cancelIcon;
                this.cancelClass = cancelClass;
                this.toolbarColor = toolbarColor;
                this.toolbarIcon = toolbarIcon;

                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            updateMessage(message) {
                this.message = message;
            },
            ok() {
                if (this.resolve) {
                    this.resolve();
                }
                this.dialog = false;
            },
            cancel() {
                if (this.reject) {
                    this.reject();
                }
                this.dialog = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .me-auto {
        margin-inline-end: auto !important;
    }
    .action-button {
        min-width: 140px;
    }
</style>
