<template>
    <v-list-item-action-text>
        <v-chip label pill small class="ml-2 my-1 pr-0" v-if="`condition:${condition.id}` === person.causeOfDeath">
            {{ $t('fields.abbrev.causeOfDeath') }}
            <v-chip label pill small color="ml-2 primary white--text">{{ person.ageAtDeath }}</v-chip>
        </v-chip>
        <v-chip label pill small class="ml-2 my-1 pr-0" v-for="prop in metaProperties(condition)" :key="prop">
            {{ $t(`fields.abbrev.${prop}`) }}
            <v-chip label pill small color="ml-2 primary white--text" v-if="'other' === prop">
                <span v-if="showOther">{{ condition.meta[prop] }}</span>
                <v-tooltip top v-else>
                    <template #activator="{ on, attrs }">
                        <v-icon x-small v-bind="attrs" v-on="on">fas fa-asterisk</v-icon>
                    </template>
                    <span>{{ condition.meta[prop] }}</span>
                </v-tooltip>
            </v-chip>
            <v-chip label pill small color="ml-2 primary white--text" v-else-if="'bloodClotRisks' === prop">{{ condition.meta[prop].length }}</v-chip>
            <v-chip label pill small color="ml-2 primary white--text" v-else-if="'maxHeight' === prop || 'maxWeight' === prop">{{ valueUnitLabel(condition.meta[prop]) }}</v-chip>
            <v-chip label pill small color="ml-2 primary white--text" v-else-if="'cancerTypes' === prop || 'dvtTypes' === prop">
                <span v-for="(type, index) in selectedTypes(condition.meta[prop])" :key="index"><span v-if="index > 0">, </span>{{ $t(`conditions.${condition.id}.${type.id}`) }}</span>
            </v-chip>
            <v-chip label pill small color="ml-2 primary white--text" v-else-if="'biopsyResult' === prop">{{ $t(`biopsyResults.${condition.meta[prop]}`) }}</v-chip>
            <span v-else-if="'numberOfSkinCancers' === prop"></span>
            <v-chip label pill small color="ml-2 primary white--text" v-else>{{ valueLabel(condition.meta[prop]) }}</v-chip>
        </v-chip>
        <v-chip label pill small class="ml-2 my-1 pr-0">
            {{ $t(ageLabel) }}
            <v-chip label pill small color="ml-2 primary white--text">{{ condition.age }}</v-chip>
        </v-chip>
    </v-list-item-action-text>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';

    export default {
        mixins: [ConditionsMixin],
        props: {
            condition: {
                type: Object,
                required: true
            },
            person: {
                type: Object,
                required: true
            },
            showOther: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ageLabel() {
                if (this.condition.ageLabel) {
                    return this.condition.ageLabel.replace(/^fields\./, 'fields.abbrev.');
                }
                return 'fields.abbrev.onsetAge';
            }
        },
        methods: {
            selectedTypes(types) {
                return types.filter((type) => type.selected);
            }
        }
    };
</script>
