<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <template #default>
                <div class="d-flex justify-space-between align-center">
                    <div class="text-h6 d-flex align-center">
                        <v-icon small left>fas fa-first-aid fa-fw</v-icon>
                        {{ $t('labels.conditions') }}
                    </div>
                    <v-icon small class="mx-2 secondary--text" v-if="isValid">fas fa-check</v-icon>
                    <div class="mx-2 text-right" v-else>
                        <span class="mr-1">{{ $t('common.required') }}:</span>
                        <span class="error--text">{{ $t('labels.addConditions') }}</span>
                        <em class="secondary--text"> {{ $t('common.or') }} </em>
                        <span class="error--text">{{ $t('labels.checkHealthy') }}</span>
                        <em class="secondary--text" v-if="!isSelf"> {{ $t('common.or') }} </em>
                        <span class="error--text" v-if="!isSelf">{{ $t('labels.checkUnknown') }}</span>
                    </div>
                </div>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager class="expansion-panel--padless">
            <div :class="{ 'error-highlight': !isValid }">
                <v-toolbar dense dark flat color="primary">
                    <v-btn small text outlined class="btn-outline-solid-white" @click.stop="addConditions()" :disabled="!!medicalHistory" id="tour-conditions-add">
                        <v-icon left small>fas fa-plus</v-icon>
                        {{ $t('labels.addConditions') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <div id="tour-conditions-healthy">
                        <v-checkbox v-model="medicalHistory" :true-value="'healthy'" :false-value="null" hide-details class="mx-3" small :disabled="person.conditions.length > 0">
                            <template #label>
                                {{ $t('medicalHistories.healthy.label') }}
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-icon small right v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
                                    </template>
                                    <span>{{ $t('medicalHistories.healthy.hint') }}</span>
                                </v-tooltip>
                            </template>
                        </v-checkbox>
                    </div>
                    <v-checkbox v-model="medicalHistory" :true-value="'unknown'" :false-value="null" hide-details class="mx-3" small :disabled="person.conditions.length > 0" v-if="!isSelf">
                        <template #label>
                            {{ $t('medicalHistories.unknown.label') }}
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-icon small right v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
                                </template>
                                <span>{{ $t('medicalHistories.unknown.hint') }}</span>
                            </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-toolbar>
            </div>
            <div v-if="!medicalHistory">
                <v-list class="condition-list" v-if="person.conditions && person.conditions.length">
                    <template v-for="(condition, index) in person.conditions">
                        <v-list-item :key="condition.uuid" @click="update(person, condition)">
                            <v-list-item-action class="my-0 mr-3">
                                <v-btn icon small color="primary" @click.stop="remove(person, condition)"><v-icon small>fas fa-trash</v-icon></v-btn>
                            </v-list-item-action>
                            <v-list-item-content class="py-0">
                                <v-list-item-title>{{ $t(`conditions.${condition.id}.name`) }}</v-list-item-title>
                            </v-list-item-content>
                            <ConditionChips :condition="condition" :person="person" v-if="!condition.skipQuestions"></ConditionChips>
                        </v-list-item>
                        <v-divider :key="index" v-if="index < person.conditions.length - 1"></v-divider>
                    </template>
                </v-list>
            </div>
            <ConditionsSearchDialog ref="conditionsSearchDialog"></ConditionsSearchDialog>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';
    import ConditionChips from '@/components/ConditionChips';
    import ConditionsSearchDialog from '@/components/ConditionsSearchDialog';

    export default {
        mixins: [ConditionsMixin, FamilyMemberMixin],
        components: { ConditionChips, ConditionsSearchDialog },
        computed: {
            isValid() {
                return this.$store.get('isConditionsValid', this.uuid);
            },
            medicalHistory: sync('medicalHistory')
        },
        methods: {
            setDialog(val) {
                if (val) {
                    this.addConditions();
                } else {
                    this.$refs.conditionsSearchDialog.hide();
                }
            },
            addConditions() {
                this.$refs.conditionsSearchDialog.show({ person: this.person });
            }
        }
    };
</script>
