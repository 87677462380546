import Vue from 'vue';
import moment from 'moment';
import numeral from 'numeral';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

Vue.filter('date', (value, format = 'llll') => {
    return moment(value).format(format);
});

Vue.filter('number', (value) => {
    return numeral(value).format('0,0');
});

Vue.filter('phone', (value) => {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('pretty', (value) => {
    return JSON.stringify(value, null, 2);
});

Vue.filter('ago', (value, format = 'mini-minute') => {
    return value ? timeAgo.format(moment(value).toDate(), format) : value;
});
