<template>
    <ConvertField
        :label="$t('fields.weight')"
        :factor="LBS_TO_KG"
        :unit1="{ value: 'lbs', label: $t('units.lbs') }"
        :unit2="{ value: 'kg', label: $t('units.kg') }"
        v-model="modelValue"
        value-unit="unit1"
        :mode.sync="unit"
    ></ConvertField>
</template>

<script>
    import ConvertField from '@/components/ConvertField';
    import { LBS_TO_KG } from '@/common';
    import { round } from 'lodash';

    export default {
        components: { ConvertField },
        props: {
            value: {
                type: Object,
                default: null
            }
        },
        data: () => ({
            LBS_TO_KG,
            unit: 'lbs'
        }),
        computed: {
            modelValue: {
                get() {
                    return this.value?.value;
                },
                set(value) {
                    this.$emit('input', { value: round(value, 0), unit: 'lbs' });
                }
            }
        }
    }
</script>
