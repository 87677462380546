<template>
    <div class="d-flex align-center" v-if="enabled">
        <v-dialog v-model="dialog" :max-width="600">
            <template #activator="{ on, attrs }">
                <v-btn icon small color="primary" v-bind="attrs" v-on="on">
                    <v-icon :size="size">fas fa-question-circle</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-toolbar dense dark color="primary" v-if="title">
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon small>fas fa-question-circle</v-icon>
                </v-toolbar>
                <v-card-text class="pa-5 pb-0">
                    <p class="text-subtitle-1" v-html="text"></p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog = false">
                        <v-icon small left>fas fa-check</v-icon>
                        {{ $t('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { get } from 'vuex-pathify';

    export default {
        props: {
            text: {
                type: String,
                required: true
            },
            title: {
                type: String,
                default: null
            },
            size: {
                type: String,
                default: 'x-large'
            }
        },
        data: () => ({
            dialog: false
        }),
        methods: {
            trigger() {
                this.dialog = true;
            }
        },
        computed: {
            enabled: get('hintsEnabled')
        }
    };
</script>
