import { each, find, filter, includes, reduce, some } from 'lodash';
import { get } from 'vuex-pathify';

import Fuse from 'fuse.js';
const fuseOptions = {
    includeScore: true,
    ignoreLocation: true,
    keys: [
        { name: 'id', weight: 1 },
        { name: 'snomed', weight: 1 },
        { name: 'short', weight: 2 },
        { name: 'name', weight: 3 },
        { name: 'hint', weight: 3 },
        { name: 'keywords', weight: 4 },
        { name: 'categoriesText', weight: 1 }
    ]
};

const IGNORED_SEARCH_STRINGS = ['usually diagnosed with a genetic test', 'condition', 'diagnosed'];

const ConditionsSearchMixin = {
    data: () => ({
        useFuzzy: true,
        alwaysShowOtherConditions: true,
        selectedCategoryIndex: null,
        searchText: ''
    }),
    computed: {
        family: get('family'),
        studyConditions: get('patient/studyConditions'),
        otherConditions() {
            if (!this.alwaysShowOtherConditions) {
                return [];
            }
            return filter(this.studyConditions, (condition) => /^other/.test(condition.id));
        },
        decoratedConditions() {
            return this.studyConditions.map((condition) => {
                const cond = Object.assign(condition, {
                    short: this.$i18n.t(`conditions.${condition.id}.short`),
                    name: this.$i18n.t(`conditions.${condition.id}.name`),
                    hint: this.$i18n.t(`conditions.${condition.id}.hint`),
                    keywords: this.$i18n.t(`conditions.${condition.id}.keywords`),
                    categoriesText: condition.categories.map((category) => this.$i18n.t(`categories.${category}`))
                });
                IGNORED_SEARCH_STRINGS.forEach((word) => {
                    cond.name = cond.name.replace(new RegExp(word, 'ig'), '');
                    cond.hint = cond.hint.replace(new RegExp(word, 'ig'), '');
                });
                return cond;
            });
        },
        familyConditions() {
            let conditions = {};
            each(this.family, (person) => {
                each(person.conditions, (condition) => {
                    if (!conditions[condition.id]) {
                        conditions[condition.id] = [];
                    }
                    if (!find(conditions[condition.id], { uuid: person.uuid })) {
                        conditions[condition.id].push(person);
                    }
                });
            });
            return conditions;
        },
        selectedCategory() {
            return this.selectedCategoryIndex !== null ? this.categories[this.selectedCategoryIndex] : null;
        },
        filteredConditionsBySearchText() {
            if (this.searchText) {
                if (this.useFuzzy) {
                    const fuse = new Fuse(this.decoratedConditions, fuseOptions);
                    const results = fuse.search(this.searchText);
                    return results.map((result) => Object.assign(result.item, { score: 1 - result.score }));
                }

                const regexp = new RegExp(this.searchText, 'i');
                return filter(this.decoratedConditions, (condition) => {
                    return some(condition, (val, key) => {
                        if ('meta' === key) {
                            return false;
                        }
                        return regexp.test(val);
                    });
                });
            }
            return this.studyConditions;
        },
        filteredConditions() {
            let filteredConditions = filter(this.filteredConditionsBySearchText, (condition) => !/^other/.test(condition.id));
            if (this.selectedCategory) {
                filteredConditions = filter(this.filteredConditionsBySearchText, (condition) => {
                    return includes(condition.categories, this.selectedCategory);
                });
            }
            if (filteredConditions && filteredConditions.length) {
                return filteredConditions.concat(this.otherConditions);
            }
            return this.otherConditions;
        }
    },
    watch: {
        useFuzzy() {
            const text = this.searchText;
            this.searchText = this.searchText + ' ';
            this.searchText = text;
        }
    },
    methods: {
        filter(index) {
            if (this.selectedCategoryIndex === index) {
                this.selectedCategoryIndex = null;
            } else {
                this.selectedCategoryIndex = index;
            }
        },
        search() {
            if (this.selectedCategory && !this.countByCategory(this.selectedCategory)) {
                this.selectedCategoryIndex = null; // de-select current selected category if count is zero
            }
        },
        countByCategory(category) {
            return reduce(
                this.filteredConditionsBySearchText,
                (count, condition) => {
                    return count + (includes(condition.categories, category) ? 1 : 0);
                },
                0
            );
        }
    }
};

export { ConditionsSearchMixin };
