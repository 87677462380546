import Vue from 'vue';
import Router from 'vue-router';
import goTo from 'vuetify/es5/services/goto';

import Home from '@/views/Home.vue';
import Profile from '@/views/Profile.vue';
import Family from '@/views/Family.vue';
import Report from '@/views/Report.vue';
import Resource from '@/views/Resource.vue';
import Risk from '@/views/Risk.vue';
import Login from '@/views/Login.vue';

Vue.use(Router);

const nav = (to, from, next) => {
    Vue.prototype.$api.get(`/nav?from=${from.name || ''}&to=${to.name}`).then(() => {});
    next();
};

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    routes: [
        { path: '/', name: 'home', component: Home, beforeEnter: nav },
        { path: '/profile', name: 'profile', component: Profile, beforeEnter: nav },
        { path: '/family', name: 'family', component: Family, beforeEnter: nav },
        { path: '/risk', name: 'risk', component: Risk, beforeEnter: nav },
        { path: '/report', name: 'report', component: Report, beforeEnter: nav },
        { path: '/resource', name: 'resource', component: Resource, beforeEnter: nav },
        { path: '/login', name: 'login', component: Login },
        { path: '*', redirect: '/' }
    ]
});
