<template>
    <v-dialog v-model="dialog" :max-width="600" persistent>
        <v-card v-if="person">
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t('labels.addFamilyMember') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon>{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0">
                <v-text-field dense outlined v-model="person.nickname" :label="$t('fields.nickname')" :hint="$t(`relations.${person.relation}`)" persistent-hint autofocus @keyup.enter="ok()"></v-text-field>
                <div v-if="listParents">
                    <v-alert dense outlined border="left" type="info">
                        <span v-html="$t(`hints.removeParent.${isSibling(person.relation) ? 'sibling' : 'relative'}`)"></span>
                    </v-alert>
                    <v-list subheader>
                        <v-list-item v-for="parent in parents(person)" :key="parent.uuid">
                            <v-list-item-avatar>
                                <v-icon :class="iconClass(parent)">{{ 'male' === parent.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ parent.nickname }}</v-list-item-title>
                                <v-list-item-subtitle>{{ $t(`relations.${parent.relation}`) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="canRemoveParent(person)">
                                <v-btn icon small @click.stop="removeParent(person, parent)"><v-icon small>fas fa-trash</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()" :disabled="!person.nickname">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { FamilyMixin } from '@/components/FamilyMixin';

    export default {
        mixins: [FamilyMixin],
        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            person: null,
            listParents: false
        }),
        methods: {
            show(person) {
                this.dialog = true;
                this.person = person;
                this.listParents = this.canRemoveParent(person);
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            ok() {
                if (this.person.nickname) {
                    this.resolve();
                    this.dialog = false;
                }
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
