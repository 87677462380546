var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":800,"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('admin.editNotes')))])],1),_c('div',{staticClass:"text-center ma-3"},[_c('em',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t('admin.prompts.editNotes')))])]),_c('div',{staticClass:"d-flex align-center ma-3"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","disabled":!_vm.note},on:{"click":function($event){return _vm.addNote()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-plus")]),_vm._v(" "+_vm._s(_vm.$t('admin.addNote'))+" ")],1)],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.notes,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var index = ref.index;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.removeNote(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('admin.deleteNote')))])])]}},{key:"item.timestamp",fn:function(ref){
var note = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(note.timestamp)))])]}},{key:"item.user",fn:function(ref){
var note = ref.item;
return [_c('span',[_vm._v(_vm._s(note.user.name))])]}}],null,false,1000599919)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.ok()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-check")]),_vm._v(" "+_vm._s(_vm.$t('common.ok'))+" ")],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fas fa-times")]),_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }