<template>
    <v-text-field
        dense
        outlined
        :hint="hint"
        :persistent-hint="!!hint"
        :hide-details="!hint"
        :readonly="readonly"
        :persistent-placeholder="persistentPlaceholder"
        :type="type"
        v-ifmask="mask"
        :value="display"
        :label="label"
        @input="input"
        ref="inputField"
    >
        <template #append>
            <div class="d-flex align-center">
                <span class="text-no-wrap text-subtitle-2 primary--text mx-2">{{ altDisplay }}</span>
                <v-btn-toggle active-class="primary white--text" :value="mode" @change="changeMode" mandatory>
                    <v-btn small :value="unit1.value" :tabindex="-1">{{ unit1.label }}</v-btn>
                    <v-btn small :value="unit2.value" :tabindex="-1">{{ unit2.label }}</v-btn>
                </v-btn-toggle>
            </div>
        </template>
    </v-text-field>
</template>

<script>
    import { mask } from 'vue-the-mask';
    import { isNumber, round } from 'lodash';

    export default {
        directives: {
            ifmask: (el, binding) => {
                if (binding.value) {
                    mask(el, binding);
                }
            }
        },
        props: {
            label: {
                type: String,
                required: true
            },
            hint: {
                type: String,
                default: null
            },
            readonly: {
                type: Boolean,
                default: false
            },
            persistentPlaceholder: {
                type: Boolean,
                default: false
            },
            factor: {
                type: Number,
                required: true
            },
            unit1: {
                type: Object,
                required: true
            },
            unit2: {
                type: Object,
                required: true
            },
            value: {
                type: Number,
                default: null
            },
            valueUnit: {
                type: String,
                default: 'unit2'
            },
            mode: {
                type: String,
                required: true
            },
            precision: {
                type: Number,
                default: 0
            },
            type: {
                type: String,
                default: 'tel'
            },
            mask: {
                type: String,
                default: '###'
            },
            parseFn: {
                type: Function,
                default: (val) => parseFloat(val)
            },
            displayFn: {
                type: Function,
                default: (val, precision) => round(val, precision)
            },
            altDisplayFn: {
                type: Function,
                default: () => null
            }
        },
        computed: {
            calculated() {
                if ('unit2' === this.valueUnit) {
                    return this.unit1.value === this.mode ? this.value / this.factor : this.value;
                }
                return this.unit2.value === this.mode ? this.value * this.factor : this.value;
            },
            display() {
                if (this.readonly && !this.value) {
                    return ' ';
                }
                return isNumber(this.value) ? this.displayFn(this.calculated, this.precision) : null;
            },
            altDisplay() {
                return this.altDisplayFn(this.display);
            }
        },
        methods: {
            calculateValue(value) {
                if ('unit2' === this.valueUnit) {
                    return this.unit1.value === this.mode ? value * this.factor : value;
                }
                return this.unit2.value === this.mode ? value / this.factor : value;
            },
            input(val) {
                const value = this.parseFn(val) || null;
                this.$emit('input', isNumber(value) ? this.calculateValue(value) : null);
            },
            changeMode(val) {
                !!this.$refs.inputField && this.$refs.inputField.focus();
                this.$emit('update:mode', val);
            }
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .v-input__append-inner {
        margin-top: 6px !important;
        margin-right: -6px;
    }
    ::v-deep .v-btn {
        text-transform: none;
    }
</style>
