<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <div class="d-flex justify-space-between">
                <div class="text-h6 d-flex align-center">
                    <v-icon small left>fas fa-running fa-fw</v-icon>
                    {{ $t('labels.exercise') }}
                </div>
                <v-icon small class="mx-2 secondary--text" v-if="isValid()">fas fa-check</v-icon>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2 mb-n2 py-3">
            <v-row>
                <v-col class="py-0">
                    <div class="my-3">
                        <v-row no-gutters justify="center">
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.moderateExercise') }}
                                    <Hint :text="$t('hints.moderateExercise')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in week" :key="index" class="mt-0 mb-2 mx-2" hide-details v-model="moderateExercise" :label="value" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.vigorousExercise') }}
                                    <Hint :text="$t('hints.vigorousExercise')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in week" :key="index" class="mt-0 mb-2 mx-2" hide-details v-model="vigorousExercise" :label="value" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.strengthTraining') }}
                                    <Hint :text="$t('hints.strengthTraining')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in week" :key="index" class="mt-0 mb-2 mx-2" hide-details v-model="strengthTraining" :label="value" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';

    export default {
        mixins: [ConditionsMixin, FamilyMemberMixin],
        data: () => ({
            week: [...Array(8).keys()].map((val, idx) => `${idx}`)
        }),
        computed: {
            moderateExercise: sync('moderateExercise'),
            vigorousExercise: sync('vigorousExercise'),
            strengthTraining: sync('strengthTraining')
        },
        methods: {
            isValid() {
                return this.$store.get('isExerciseValid', this.uuid);
            }
        }
    };
</script>
