<template>
    <v-card flat>
        <div class="d-flex align-center mb-6">
            <v-card class="full-width" v-if="filteredSessions.length > 0">
                <v-toolbar dense dark color="primary">
                    <v-toolbar-title>{{ $t('admin.sessions') }}</v-toolbar-title>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn small icon class="mx-1" @click="listSessions()" :disabled="refreshingSessions" v-bind="attrs" v-on="on">
                                <v-icon :class="{ 'fa-spin': refreshingSessions }" small>fas fa-sync</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('admin.refreshData') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-checkbox class="mx-2 mt-0" dark hide-details v-model="sessionAutoRefresh" :label="$t('admin.autoRefresh')"></v-checkbox>
                            </span>
                        </template>
                        <span>{{ $t('admin.autoRefreshHint') }}</span>
                    </v-tooltip>
                </v-toolbar>
                <v-data-table :headers="sessionHeaders" :items="filteredSessions" item-key="key" dense class="mt-3">
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.edit="{ item: session }">
                        <v-tooltip top v-if="isSuperAdmin">
                            <template #activator="{ on, attrs }">
                                <v-btn small icon color="primary" @click.stop="expireSession(session)" :disabled="!isSuperAdmin || session.expiring || session.isCurrentSession" v-bind="attrs" v-on="on">
                                    <v-icon small v-if="!session.expiring">fas fa-times</v-icon>
                                    <v-icon small v-else>fas fa-sync fa-spin</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.expireSession') }}</span>
                        </v-tooltip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.expires="{ item: session }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ session.expiresHuman }}</span>
                            </template>
                            <div class="text-center">{{ session.expires | date }}</div>
                        </v-tooltip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.user.id="{ item: session }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ session.user.id }}</span>
                            </template>
                            <div class="text-center font-weight-bold">{{ $t('admin.columns.username') }}</div>
                            <div class="text-center">{{ session.user.username }}</div>
                        </v-tooltip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.site="{ item: session }">
                        <span v-if="session.user && session.user.patient && session.user.patient.organization">{{ session.user.patient.organization.name }}</span>
                        <span v-else-if="session.user && session.user.organizations && session.user.organizations.length > 0">{{ session.user.organizations[0].name }}</span>
                        <span v-else-if="session.user && session.user.meta && session.user.meta.roles">{{ session.user.meta.roles.join(',') }}</span>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.user.req.browser="{ item: session }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ session.user.req.browser }}</span>
                            </template>
                            <div class="text-center">{{ session.user.req.userAgent }}</div>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card>
        </div>
        <div class="d-flex align-center mb-3">
            <v-spacer></v-spacer>
            <v-fade-transition>
                <div class="mx-3 mb-1" v-if="refreshingData">
                    <v-icon small>fas fa-spinner fa-spin</v-icon>
                </div>
            </v-fade-transition>
            <v-radio-group dense row mandatory hide-details class="ma-0 pb-1" v-model="searchType" @change="refreshDataDebounced()">
                <v-radio v-for="(searchType, index) in searchTypes" :key="index" :label="$t(`admin.search.types.${searchType}`)" :value="searchType"></v-radio>
            </v-radio-group>
            <v-text-field dense outlined prepend-inner-icon="fas fa-search" :placeholder="$t('admin.search.patients.placeholder')" v-model="search" class="search-field" hide-details clearable></v-text-field>
        </div>
        <div v-for="organization in organizations" :key="organization.id">
            <v-card class="mb-6" v-if="!isSuperAdmin || !search || filteredPatients(organization).length > 0">
                <v-toolbar dense dark color="primary">
                    <v-btn-toggle v-model="organization.view" group class="ml-n5 mr-3" v-if="isSuperAdmin">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn text value="patients" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-users-medical</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.patients') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn text value="users" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-users</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.users') }}</span>
                        </v-tooltip>
                    </v-btn-toggle>
                    <v-toolbar-title>{{ organization.name }}</v-toolbar-title>
                    <v-tooltip top v-if="isSuperAdmin">
                        <template #activator="{ on, attrs }">
                            <v-btn small icon class="mx-1" @click="configure(organization)" :disabled="!!search" v-bind="attrs" v-on="on">
                                <v-icon small>fas fa-cog</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('admin.configure') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn small icon class="mx-1" @click="refreshData()" :disabled="refreshingData" v-bind="attrs" v-on="on">
                                <v-icon :class="{ 'fa-spin': refreshingData }" small>fas fa-sync</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('admin.refreshData') }}</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-btn-toggle group color="error" v-model="organization.filter" v-if="organization.pushErrors > 0">
                        <v-btn small value="pushErrors">
                            <v-icon small left>fas fa-exclamation-triangle</v-icon>
                            {{ $t('admin.pushErrors') }}: {{ organization.pushErrors }}
                        </v-btn>
                    </v-btn-toggle>
                    <v-btn small text @click.stop :href="`/api/researcher/patient/download?organizationId=${organization.id}&filename=metree-${snakeCase(organization.name)}-${timestamp()}.csv`" v-if="'patients' === organization.view">
                        <v-icon small left>fas fa-file-csv</v-icon>
                        {{ $t('admin.downloadPatients') }}
                    </v-btn>
                    <v-btn small text @click="uploadPatients(organization)" v-if="'patients' === organization.view">
                        <v-icon small left>fas fa-upload</v-icon>
                        {{ $t('admin.uploadPatients') }}
                    </v-btn>
                    <v-btn small text @click="addPatient(organization)" v-if="'patients' === organization.view">
                        <v-icon small left>fas fa-plus</v-icon>
                        {{ $t('admin.addPatient') }}
                    </v-btn>
                    <v-btn small text @click="addUser(organization)" v-if="'users' === organization.view">
                        <v-icon small left>fas fa-plus</v-icon>
                        {{ $t('admin.addUser') }}
                    </v-btn>
                </v-toolbar>
                <v-data-table
                    :headers="patientHeaders"
                    :items="filteredPatients(organization)"
                    item-key="id"
                    :page.sync="organization.pagination.page"
                    :items-per-page.sync="organization.pagination.itemsPerPage"
                    @click:row="select"
                    dense
                    class="mt-3 clickable-rows"
                    v-if="'patients' === organization.view"
                >
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.edit="{ item: patient }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn small icon color="primary" @click.stop="deletePatient(patient)" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-trash</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.deletePatient') }}</span>
                        </v-tooltip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.user.username="{ item: patient }">
                        <div class="d-flex align-center justify-space-between flex-nowrap" v-if="patient.user">
                            <span class="mr-2">{{ patient.user.username }}</span>
                            <v-tooltip right>
                                <template #activator="{ on, attrs }">
                                    <v-btn small icon color="primary" @click.stop="clipboard(url(`/login?patient=${patient.user.username}`))" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-external-link</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.copyLoginLink') }}<br />{{ url(`/login?patient=${patient.user.username}`) }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.password="{ item: patient }">
                        <div @click.stop v-if="patient.user && patient.user.password">
                            <div v-if="patient.user.revealPassword">
                                {{ patient.user.password }}
                            </div>
                            <div v-else>
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn small icon @click.stop="revealPassword(patient)" v-bind="attrs" v-on="on">
                                            <v-icon small color="primary">fas fa-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('admin.reveal') }}</span>
                                </v-tooltip>
                                <v-icon small>fas fa-ellipsis-h</v-icon>
                                <v-icon small>fas fa-ellipsis-h</v-icon>
                                <v-icon small>fas fa-ellipsis-h</v-icon>
                            </div>
                        </div>
                        <v-btn small text color="primary" @click.stop="resetPassword(patient)" v-else>
                            <v-icon small left>fas fa-redo</v-icon>
                            {{ $t('admin.reset') }}
                        </v-btn>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.tags="{ item: patient }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn small icon color="primary" @click.stop="editTags(patient, $refs.tagsDialog)" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.editTags') }}</span>
                        </v-tooltip>
                        <v-chip v-for="(tag, index) in patient.tags" :key="index" label pill small class="pr-0 mx-1">
                            {{ tag.name }}
                            <v-chip label pill small color="ml-2 primary white--text">{{ tag.value }}</v-chip>
                        </v-chip>
                        <span class="error--text" v-if="missingTags(patient).length > 0">
                            {{ $t('admin.missing') }}: <em>{{ missingTags(patient).join(', ') }}</em>
                        </span>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.studies="{ item: patient }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn small icon color="primary" @click.stop="editMeta(patient, $refs.studyDialog)" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.editStudies') }}</span>
                        </v-tooltip>
                        <v-chip v-for="(study, index) in studies(patient)" :key="index" label pill small class="px-0 ma-1" @click.stop>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-chip label pill small color="accent black--text" v-bind="attrs" v-on="on">
                                        {{ studyDays(study) }}
                                        <v-icon x-small class="ml-1" v-if="isStudyLocked(study)">fas fa-lock</v-icon>
                                    </v-chip>
                                </template>
                                <span>{{ $t('admin.studyDays') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <span class="mx-2">{{ study.id }}</span>
                                        <v-icon x-small class="mr-2" v-if="study.finished">fas fa-check</v-icon>
                                    </div>
                                </template>
                                <div>{{ $t('admin.columns.started') }}: {{ study.started | date }}</div>
                                <div v-if="study.finished">{{ $t('admin.columns.finished') }}: {{ study.finished | date }}</div>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-chip label pill small color="primary white--text" @click.stop="pushData(patient, study)" :disabled="!canPushData(patient, study)" v-bind="attrs" v-on="on">
                                        <v-icon small v-if="patient.activePush && patient.activePush[study.id]">fas fa-spinner fa-spin</v-icon>
                                        <v-icon small v-else>fas fa-upload</v-icon>
                                    </v-chip>
                                </template>
                                <span>{{ $t('admin.pushData') }}</span>
                            </v-tooltip>
                        </v-chip>
                        <div class="error--text text-caption push-error-text" v-if="patient.pushErrors && patient.pushErrors[0] && patient.pushErrors[0].errors">
                            {{ patient.pushErrors[0].errors[0] }}
                        </div>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.notes="{ item: patient }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn small icon color="primary" @click.stop="editMeta(patient, $refs.notesDialog)" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.editNotes') }}</span>
                        </v-tooltip>
                        <v-chip label pill small color="ma-1 accent black--text">{{ notesCount(patient) }}</v-chip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #header.user.lastLogin.timestamp="{ header }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on">fas fa-sign-in</v-icon>
                            </template>
                            <span>{{ header.text }}</span>
                        </v-tooltip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.user.lastLogin.timestamp="{ item: patient }">
                        <v-tooltip top v-if="patient.user && patient.user.lastLogin">
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ patient.user.lastLogin.timestamp | ago }}</span>
                            </template>
                            <div class="text-center font-weight-bold">{{ $t('admin.columns.lastLogin') }}</div>
                            <div class="text-center">{{ patient.user.lastLogin.timestamp | date }}</div>
                            <div class="text-center">{{ patient.user.lastLogin.timestamp | ago('round-minute') }}</div>
                        </v-tooltip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.downloads="{ item: patient }">
                        <div class="d-flex flex-nowrap">
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn small text @click.stop :href="`/api/researcher/patient/pedigree?patientId=${patient.id}&scale=2&filename=pedigree.${patient.id}.png`" :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-file-image</v-icon>
                                        <v-icon small color="primary">fas fa-tree-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.pedigreePng') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn small text @click.stop :href="`/api/researcher/patient/pedigree?patientId=${patient.id}&filename=pedigree.${patient.id}.pdf&type=pdf`" :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-file-pdf</v-icon>
                                        <v-icon small color="primary">fas fa-tree-alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.pedigreePdf') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        text
                                        @click.stop
                                        :href="`/api/researcher/patient/cds?patientId=${patient.id}&pdf=patient_report_en&filename=patient_report.${patient.id}.pdf`"
                                        :disabled="!patient.reportAvailable"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>fas fa-file-pdf</v-icon>
                                        <v-icon small color="primary">fas fa-user</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.patientReport') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        text
                                        @click.stop
                                        :href="`/api/researcher/patient/cds?patientId=${patient.id}&pdf=physician_report&filename=provider_report.${patient.id}.pdf`"
                                        :disabled="!patient.reportAvailable"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>fas fa-file-pdf</v-icon>
                                        <v-icon small color="primary">fas fa-user-md</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.providerReport') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        text
                                        @click.stop
                                        :href="`/api/researcher/patient/cds?patientId=${patient.id}&pdf=patient_no_md_report_en&filename=patient_no_md_report.${patient.id}.pdf`"
                                        :disabled="!patient.reportAvailable"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>fas fa-ban</v-icon>
                                        <v-icon small color="primary">fas fa-user-md</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.noProviderReport') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn small text @click.stop :href="`/api/researcher/patient/cds?patientId=${patient.id}&filename=json_report.${patient.id}.json`" :disabled="!patient.reportAvailable" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-brackets-curly</v-icon>
                                        <v-icon small color="primary">fas fa-users</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.jsonReport') }}</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-btn small text @click.stop :href="`/api/researcher/patient/data?patientId=${patient.id}&filename=raw_data.${patient.id}.json`" v-bind="attrs" v-on="on">
                                        <v-icon small>fas fa-brackets-curly</v-icon>
                                        <v-icon small color="primary">fas fa-database</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('admin.rawData') }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
                <v-data-table
                    :headers="userHeaders"
                    :items="organization.users"
                    item-key="id"
                    :item-class="(item) => (item.enabled ? '' : 'active')"
                    @click:row="selectUser"
                    dense
                    class="mt-3 clickable-rows"
                    v-if="'users' === organization.view"
                >
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.edit="{ item: user }">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn small icon color="primary" @click.stop="editUser(organization, user)" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-user-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.editUser') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn small icon color="primary" @click.stop="deleteUser(organization, user)" v-bind="attrs" v-on="on">
                                    <v-icon small>fas fa-trash</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('admin.deleteUser') }}</span>
                        </v-tooltip>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.enabled="{ item: user }">
                        <v-icon small v-if="user.enabled">fas fa-check-square</v-icon>
                        <v-icon small v-else>far fa-square</v-icon>
                    </template>
                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                    <template #item.password="{ item: user }">
                        <div @click.stop v-if="user && user.password">
                            <div v-if="user.revealPassword">
                                {{ user.password }}
                            </div>
                            <div v-else>
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn small icon @click.stop="revealUserPassword(user)" v-bind="attrs" v-on="on">
                                            <v-icon small color="primary">fas fa-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('admin.reveal') }}</span>
                                </v-tooltip>
                                <v-icon small>fas fa-ellipsis-h</v-icon>
                                <v-icon small>fas fa-ellipsis-h</v-icon>
                                <v-icon small>fas fa-ellipsis-h</v-icon>
                            </div>
                        </div>
                        <v-btn small text color="primary" @click.stop="resetUserPassword(user)" v-else>
                            <v-icon small left>fas fa-redo</v-icon>
                            {{ $t('admin.reset') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </div>
        <div class="d-flex mb-3" v-if="isSuperAdmin">
            <v-card>
                <v-toolbar dense dark color="accent">
                    <v-toolbar-title>{{ $t('admin.studyMetrics') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small text @click="clipboard($refs.studyMetrics.$el.innerHTML)">
                        <v-icon small left>fas fa-copy</v-icon>
                        {{ $t('admin.copyToClipboard') }}
                    </v-btn>
                </v-toolbar>
                <v-simple-table ref="studyMetrics">
                    <template #default>
                        <thead>
                            <tr>
                                <th>{{ $t('admin.columns.site') }}</th>
                                <th>{{ $t('admin.columns.study') }}</th>
                                <th>{{ $t('admin.columns.total') }}</th>
                                <th>{{ $t('admin.columns.locked') }}</th>
                                <th>{{ $t('admin.columns.incompleteFamily') }}</th>
                                <th>{{ $t('admin.columns.reportAvailable') }}</th>
                                <th>{{ $t('admin.columns.logins') }}</th>
                                <th>{{ $t('admin.columns.averageFamilySize') }}</th>
                                <th>{{ $t('admin.columns.largestFamilySize') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="organization in organizations">
                                <tr v-for="(stats, index) in studyMetrics(organization)" :key="`${organization.id}:${index}`">
                                    <td class="text-no-wrap">{{ stats.site }}</td>
                                    <td class="text-no-wrap">{{ stats.study }}</td>
                                    <td class="text-no-wrap">{{ stats.total }}</td>
                                    <td class="text-no-wrap">{{ stats.locked }}</td>
                                    <td class="text-no-wrap">{{ stats.incompleteFamily }}</td>
                                    <td class="text-no-wrap">{{ stats.reportAvailable }}</td>
                                    <td class="text-no-wrap">{{ stats.logins }}</td>
                                    <td class="text-no-wrap">
                                        <span v-if="stats.total - stats.incompleteFamily">{{ (stats.completeTotalFamilySize / (stats.total - stats.incompleteFamily)).toFixed(1) }}</span>
                                        <span v-else>--</span>
                                    </td>
                                    <td class="text-no-wrap">{{ stats.largestFamilySize }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </div>
        <v-file-input ref="fileUpload" accept=".csv" @change="handleUpload" class="d-none"></v-file-input>
        <AddEditUserDialog ref="addEditUserDialog"></AddEditUserDialog>
        <AddPatientDialog ref="addPatientDialog"></AddPatientDialog>
        <ConfigureOrganizationDialog ref="configureOrganizationDialog"></ConfigureOrganizationDialog>
        <PatientDialog ref="patientDialog"></PatientDialog>
        <ResetPasswordDialog ref="resetPasswordDialog"></ResetPasswordDialog>
        <NotesDialog ref="notesDialog"></NotesDialog>
        <StudyDialog ref="studyDialog"></StudyDialog>
        <TagsDialog ref="tagsDialog"></TagsDialog>
    </v-card>
</template>

<script>
    import AddEditUserDialog from '@/components/researcher/AddEditUserDialog';
    import AddPatientDialog from '@/components/researcher/AddPatientDialog';
    import ConfigureOrganizationDialog from '@/components/researcher/ConfigureOrganizationDialog';
    import PatientDialog from '@/components/researcher/PatientDialog';
    import ResetPasswordDialog from '@/components/researcher/ResetPasswordDialog';
    import { ResearcherMixin } from '@/components/researcher/ResearcherMixin';
    import { get, sync } from 'vuex-pathify';
    import { debounce, filter, find, includes } from 'lodash';
    import moment from 'moment';
    import ms from 'ms';

    let sessionAutoRefreshInterval = null;

    export default {
        components: { AddEditUserDialog, AddPatientDialog, ConfigureOrganizationDialog, PatientDialog, ResetPasswordDialog },
        mixins: [ResearcherMixin],
        data() {
            return {
                sessions: [],
                sessionAutoRefresh: false,
                sessionHeaders: [
                    { text: '', value: 'edit', align: 'center', width: '50px', sortable: false, divider: true },
                    { text: this.$i18n.t('admin.columns.session'), value: 'key' },
                    { text: this.$i18n.t('admin.columns.expires'), value: 'expires', width: '90px' },
                    { text: this.$i18n.t('admin.columns.userId'), value: 'user.id', width: '90px' },
                    { text: this.$i18n.t('admin.columns.userType'), value: 'user.type', width: '90px' },
                    { text: this.$i18n.t('admin.columns.name'), value: 'user.name' },
                    { text: this.$i18n.t('admin.columns.site'), value: 'site' },
                    { text: this.$i18n.t('admin.columns.patientId'), value: 'user.patient.id', width: '100px' },
                    { text: this.$i18n.t('admin.columns.study'), value: 'user.patient.meta.selectedStudyId', width: '100px' },
                    { text: this.$i18n.t('admin.columns.ip'), value: 'user.req.ip', width: '100px' },
                    { text: this.$i18n.t('admin.columns.device'), value: 'user.req.device', width: '100px' },
                    { text: this.$i18n.t('admin.columns.browser'), value: 'user.req.browser', width: '100px' },
                    { text: this.$i18n.t('admin.columns.version'), value: 'user.req.version', width: '100px' },
                    { text: this.$i18n.t('admin.columns.os'), value: 'user.req.os', width: '100px' },
                    { text: this.$i18n.t('admin.columns.platform'), value: 'user.req.platform', width: '100px' }
                ],
                refreshingSessions: false,
                refreshingData: false,
                searchTypes: ['startswith', 'contains'],
                patientHeaders: [
                    { text: '', value: 'edit', align: 'center', width: '50px', sortable: false, divider: true },
                    { text: this.$i18n.t('admin.columns.id'), value: 'id', width: '60px' },
                    { text: this.$i18n.t('admin.columns.username'), value: 'user.username' },
                    { text: this.$i18n.t('admin.columns.password'), value: 'password', align: 'center', sortable: false },
                    { text: this.$i18n.t('admin.columns.name'), value: 'fullName' },
                    // { text: this.$i18n.t('admin.columns.email'), value: 'user.email' },
                    { text: this.$i18n.t('admin.columns.tags'), value: 'tags', sortable: false },
                    { text: this.$i18n.t('admin.columns.studies'), value: 'studies', sortable: false },
                    { text: this.$i18n.t('admin.columns.notes'), value: 'notes', width: '100px', sortable: false },
                    { text: this.$i18n.t('admin.columns.lastLogin'), value: 'user.lastLogin.timestamp', width: '70px', sortable: true, divider: true },
                    { text: this.$i18n.t('admin.columns.downloads'), value: 'downloads', align: 'center', sortable: false }
                ],
                userHeaders: [
                    { text: '', value: 'edit', align: 'center', width: '100px', sortable: false, divider: true },
                    { text: this.$i18n.t('admin.columns.id'), value: 'id', width: '60px' },
                    { text: this.$i18n.t('admin.columns.enabled'), value: 'enabled', align: 'center', width: '100px' },
                    { text: this.$i18n.t('admin.columns.username'), value: 'username' },
                    { text: this.$i18n.t('admin.columns.password'), value: 'password', align: 'center', sortable: false },
                    { text: this.$i18n.t('admin.columns.name'), value: 'name' },
                    { text: this.$i18n.t('admin.columns.email'), value: 'email' }
                ]
            };
        },
        computed: {
            user: get('user'),
            isSuperAdmin() {
                return includes(this.user.roles, 'superadmin');
            },
            filteredSessions() {
                return this.sessions.filter((session) => !!session.user);
            },
            search: sync('researcher/search'),
            searchType: sync('researcher/searchType'),
            organizations: get('researcher/organizations')
        },
        watch: {
            sessionAutoRefresh(val) {
                if (val) {
                    sessionAutoRefreshInterval = setInterval(() => {
                        this.listSessions(true);
                    }, 60000);
                } else {
                    if (sessionAutoRefreshInterval) {
                        clearInterval(sessionAutoRefreshInterval);
                    }
                }
            },
            search: {
                handler: debounce(function () {
                    this.refreshData();
                }, 1000)
            }
        },
        mounted() {
            const patient = this.$store.get('researcher/patient');
            if (!!patient) {
                this.select(patient);
            }
            this.listSessions().then(() => {
                this.$root.socket?.on('notify:login', this.listSessions);
                this.$root.socket?.on('notify:logout', this.listSessions);
            });
        },
        unmounted() {
            if (sessionAutoRefreshInterval) {
                clearInterval(sessionAutoRefreshInterval);
            }
            this.$root.socket?.off('notify:login', this.listSessions);
            this.$root.socket?.off('notify:logout', this.listSessions);
        },
        methods: {
            studyMetrics(organization) {
                const stats = {};
                this.filteredPatients(organization).forEach((patient) => {
                    let studies = patient.meta?.studies;
                    if (!studies || 0 === studies.length) {
                        studies = [{ id: '<none>' }];
                    }
                    studies.forEach((study) => {
                        if (!stats[study.id]) {
                            stats[study.id] = {
                                total: 0,
                                locked: 0,
                                reportAvailable: 0,
                                logins: 0,
                                incompleteFamily: 0,
                                completeTotalFamilySize: 0,
                                largestFamilySize: 0
                            };
                        }
                        stats[study.id].total++;
                        if (this.isStudyLocked(study)) {
                            stats[study.id].locked++;
                        }
                        if (patient.reportAvailable) {
                            stats[study.id].reportAvailable++;
                        }
                        if (patient.user?.lastLogin?.timestamp) {
                            stats[study.id].logins++;
                        }
                        if (patient.familySize < 7) {
                            stats[study.id].incompleteFamily++;
                        } else {
                            stats[study.id].completeTotalFamilySize += patient.familySize;
                            if (patient.familySize > stats[study.id].largestFamilySize) {
                                stats[study.id].largestFamilySize = patient.familySize;
                            }
                        }
                    });
                });
                return Object.keys(stats).map((key) => {
                    return Object.assign(stats[key], { site: organization.name, study: key });
                });
            },
            listPatients(organization) {
                if (this.isSuperAdmin) {
                    return organization.patients;
                }
                const regex = new RegExp(`^${this.user.meta?.studies?.join('$|^')}$`);
                if (!regex) {
                    return [];
                }
                return filter(organization.patients, (patient) => {
                    let studies = patient.meta?.studies;
                    if (!studies || 0 === studies.length) {
                        studies = [{ id: '<none>' }];
                    }
                    return find(studies, (study) => {
                        return regex.test(study.id);
                    });
                });
            },
            filteredPatients(organization) {
                return filter(this.listPatients(organization), (patient) => {
                    if ('pushErrors' === organization.filter && !patient.pushErrors) {
                        return false;
                    }
                    return true;
                });
            },
            refreshDataDebounced: debounce(function () {
                this.refreshData();
            }, 1000),
            select(patient) {
                this.$refs.patientDialog.show(patient);
            },
            // eslint-disable-next-line no-unused-vars
            selectUser(user) {
                // TODO
            },
            async listSessions(auto) {
                this.refreshingSessions = true;
                const { data: sessions } = await this.$api.get(`/researcher/listSessions${auto ? '?noroll' : ''}`);
                sessions.forEach((session) => {
                    if (session.isCurrentSession) {
                        session.expires = this.$store.get('sessionExpires');
                        session.expiresInMs = moment(session.expires).diff(moment(), 'milliseconds');
                        session.expiresHuman = ms(session.expiresInMs);
                    }
                });
                this.sessions = sessions;
                this.refreshingSessions = false;
            },
            async expireSession(session) {
                const id = session.key;
                session.expiring = true;
                const { data } = await this.$api.post('/researcher/expireSessions', { id });
                this.listSessions();
            },
            async refreshData() {
                // refresh data
                this.refreshingData = true;
                const { data: organizations } = await this.$api.get(`/researcher${this.search ? `?search=${this.search}${'startswith' === this.searchType ? '&fast' : ''}` : ''}`);
                this.$store.commit('researcher/organizations', organizations);
                this.refreshingData = false;
            },
            uploadPatients(organization) {
                this.$refs.fileUpload.$refs.input.click();
                this.$refs.fileUpload._organization = organization;
            },
            async handleUpload(file) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('organizationId', this.$refs.fileUpload._organization.id);
                const { data } = await this.$api.post('/researcher/patient/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                });
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `metree-${file.name}`);
                document.body.appendChild(link);
                link.click();
                link.remove();

                this.$refs.fileUpload.$refs.input.value = null;
                this.$refs.fileUpload._organization = null;

                this.refreshData();
            },
            addPatient(organization) {
                this.$refs.addPatientDialog
                    .show(organization.id)
                    .then(async (patientData) => {
                        const { data: patient } = await this.$api.post('/researcher/patient/add', patientData);
                        this.$store.commit('researcher/addPatient', patient);
                        setTimeout(() => {
                            patient.user.password = null;
                            this.$store.commit('researcher/updatePatient', patient);
                        }, ms('5m'));
                    })
                    .catch(() => {});
            },
            resetPassword(patient) {
                this.$refs.resetPasswordDialog
                    .show()
                    .then(async (password) => {
                        const { data: _patient } = await this.$api.post(`/researcher/patient/resetPassword?patientId=${patient.id}`, { password });
                        this.$store.commit('researcher/updatePatient', _patient);
                        setTimeout(() => {
                            patient.user.password = null;
                            this.$store.commit('researcher/updatePatient', patient);
                        }, ms('5m'));
                    })
                    .catch(() => {});
            },
            revealPassword(patient) {
                patient.user.revealPassword = true;
                this.$store.commit('researcher/updatePatient', patient);
                setTimeout(() => {
                    patient.user.revealPassword = false;
                    this.$store.commit('researcher/updatePatient', patient);
                }, ms('15s'));
            },
            addUser(organization) {
                this.$refs.addEditUserDialog
                    .show({ organizationId: organization.id, name: '', username: '', password: '', email: '', enabled: true })
                    .then(async (userData) => {
                        const { data: user } = await this.$api.post('/researcher/user/add', userData);
                        this.$store.commit('researcher/addUser', { user, organization });
                        setTimeout(() => {
                            user.password = null;
                        }, ms('5m'));
                    })
                    .catch(() => {});
            },
            editUser(organization, user) {
                this.$refs.addEditUserDialog
                    .show(user)
                    .then(async (userData) => {
                        const { data: _user } = await this.$api.post('/researcher/user/edit', userData);
                        this.$store.commit('researcher/editUser', { user: _user, organization });
                    })
                    .catch(() => {});
            },
            deleteUser(organization, user) {
                this.$root.$confirmationDialog
                    .show({ title: this.$i18n.t('admin.prompts.deleteUser.title'), message: this.$i18n.t('admin.prompts.deleteUser.message') })
                    .then(async () => {
                        await this.$api.delete(`/researcher/user/delete?userId=${user.id}`);
                        this.$store.commit('researcher/deleteUser', { user, organization });
                    })
                    .catch(() => {});
            },
            resetUserPassword(user) {
                this.$refs.resetPasswordDialog
                    .show()
                    .then(async (password) => {
                        const { data: _user } = await this.$api.post(`/researcher/user/resetPassword?userId=${user.id}`, { password });
                        this.$set(user, 'password', _user.password);
                        setTimeout(() => {
                            user.password = null;
                        }, ms('5m'));
                    })
                    .catch(() => {});
            },
            revealUserPassword(user) {
                this.$set(user, 'revealPassword', true);
                setTimeout(() => {
                    user.revealPassword = false;
                }, ms('15s'));
            },
            configure(organization) {
                this.$refs.configureOrganizationDialog
                    .show(organization)
                    .then(async (organizationData) => {
                        const { data: _organization } = await this.$api.post('/researcher/organization', organizationData);
                        this.$store.commit('researcher/updateOrganization', _organization);
                    })
                    .catch(() => {});
            },
            pushData(patient, study) {
                const studyPush = {};
                studyPush[study.id] = true;
                this.$set(patient, 'activePush', studyPush);
                this.$set(patient, 'disablePush', true);
                this.$api
                    .get(`/researcher/patient/push?patientId=${patient.id}&study=${study.id}`)
                    .then(() => {
                        this.$root.$confirmationDialog
                            .show({ title: this.$i18n.t('admin.prompts.push.title'), message: this.$i18n.t('admin.prompts.push.success'), showCancel: false })
                            .then(() => {
                                setTimeout(() => {
                                    patient.disablePush = false;
                                }, 20000);
                            })
                            .finally(() => {
                                patient.activePush[study.id] = false;
                            });
                    })
                    .catch(() => {
                        this.$root.$confirmationDialog
                            .show({ title: this.$i18n.t('admin.prompts.push.title'), message: this.$i18n.t('admin.prompts.push.error'), showCancel: false })
                            .then(() => {
                                setTimeout(() => {
                                    patient.disablePush = false;
                                }, 20000);
                            })
                            .finally(() => {
                                patient.activePush[study.id] = false;
                            });
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .clickable-rows {
        ::v-deep tbody tr {
            cursor: pointer;
        }
    }
    .search-field {
        max-width: 250px;
    }
    .push-error-text {
        max-height: 40px;
        overflow: hidden;
    }
</style>
