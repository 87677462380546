<template>
    <v-card flat>
        <v-expansion-panels v-model="panels">
            <General :uuid="uuid" id="tour-intro-panel"></General>
            <Demographics :uuid="uuid" id="tour-demographics-panel"></Demographics>
            <Conditions :uuid="uuid" ref="conditions" id="tour-conditions-panel"></Conditions>
            <v-expansion-panel v-if="!isFamilyCreated" id="tour-family-panel">
                <v-expansion-panel-header color="grey lighten-3">
                    <div class="d-flex justify-space-between align-center">
                        <div class="text-h6 d-flex align-center">
                            <v-icon small left>fas fa-tags fa-fw</v-icon>
                            {{ $t('labels.familyMemberNames') }}
                        </div>
                        <v-icon small class="mx-2 secondary--text" v-if="isNicknamesValid()">fas fa-check</v-icon>
                        <div class="mx-2 text-right" v-else>
                            <span class="mr-1">{{ $t('common.required') }}:</span>
                            <span class="error--text">{{ $t('labels.allNicknames') }}</span>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager class="mt-4">
                    <v-row id="tour-family-names">
                        <v-col>
                            <v-card outlined>
                                <v-toolbar dense flat class="accent white--text">
                                    <v-toolbar-title>{{ $t('labels.mothersSide') }}</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-text-field dense outlined persistent-placeholder class="mb-n2" v-model="nicknames.MGRMTH" :label="`${$t('relations.MGRMTH')}${!nicknames.MGRMTH ? '*' : ''}`" :error="!nicknames.MGRMTH"></v-text-field>
                                    <v-text-field dense outlined persistent-placeholder class="mb-n2" v-model="nicknames.MGRFTH" :label="`${$t('relations.MGRFTH')}${!nicknames.MGRFTH ? '*' : ''}`" :error="!nicknames.MGRFTH"></v-text-field>
                                    <v-text-field dense outlined persistent-placeholder class="mb-n2" v-model="nicknames.NMTH" :label="`${$t('relations.NMTH')}${!nicknames.NMTH ? '*' : ''}`" :error="!nicknames.NMTH"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card outlined>
                                <v-toolbar dense flat class="secondary white--text">
                                    <v-toolbar-title>{{ $t('labels.fathersSide') }}</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-text-field dense outlined persistent-placeholder class="mb-n2" v-model="nicknames.PGRMTH" :label="`${$t('relations.PGRMTH')}${!nicknames.PGRMTH ? '*' : ''}`" :error="!nicknames.PGRMTH"></v-text-field>
                                    <v-text-field dense outlined persistent-placeholder class="mb-n2" v-model="nicknames.PGRFTH" :label="`${$t('relations.PGRFTH')}${!nicknames.PGRFTH ? '*' : ''}`" :error="!nicknames.PGRFTH"></v-text-field>
                                    <v-text-field dense outlined persistent-placeholder class="mb-n2" v-model="nicknames.NFTH" :label="`${$t('relations.NFTH')}${!nicknames.NFTH ? '*' : ''}`" :error="!nicknames.NFTH"></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div class="my-6 d-flex justify-center">
            <v-btn x-large @click="navigate()" color="primary" class="overlay-btn" :disabled="!canNavigate()" id="tour-intro-done" v-if="!studyLocked">
                {{ $t('common.continue') }}
                <v-icon right>fas fa-arrow-right</v-icon>
                <v-overlay absolute :value="!canNavigate()">
                    <v-icon small>fas fa-lock</v-icon>
                </v-overlay>
            </v-btn>
        </div>
        <Tour :steps="tourSteps" :onDone="() => (panels = null)"></Tour>
    </v-card>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';

    import Conditions from '@/components/Conditions';
    import ConditionsScreening from '@/components/ConditionsScreening';
    import Demographics from '@/components/Demographics';
    import General from '@/components/General';
    import { get } from 'vuex-pathify';

    export default {
        mixins: [ConditionsMixin],
        name: 'Profile',
        components: { Conditions, ConditionsScreening, Demographics, General },
        data() {
            return {
                panels: null,
                nicknames: {
                    NMTH: this.$i18n.t('relations.NMTH'),
                    MGRMTH: this.$i18n.t('relations.MGRMTH'),
                    MGRFTH: this.$i18n.t('relations.MGRFTH'),
                    NFTH: this.$i18n.t('relations.NFTH'),
                    PGRMTH: this.$i18n.t('relations.PGRMTH'),
                    PGRFTH: this.$i18n.t('relations.PGRFTH')
                }
            };
        },
        computed: {
            studyLocked: get('patient/studyLocked'),
            uuid: get('family@0.uuid'),
            person: get('family@0'),
            isSelfValid: get('isSelfValid'),
            isFamilyCreated: get('isFamilyCreated'),
            tourSteps() {
                const setupFn = (panel, conditionsDialog = false) => {
                    if ('conditions' === panel) {
                        panel = 2;
                    } else if ('nicknames' === panel) {
                        panel = 3;
                    }
                    return () => {
                        this.panels = panel;
                        this.$refs.conditions.setDialog(conditionsDialog);
                    };
                };
                const steps = [
                    { text: this.$i18n.t('tour.intro-welcome'), setup: setupFn(null) },
                    { attach: '#tour-intro-nav', text: this.$i18n.t('tour.intro-nav'), setup: setupFn(null) },
                    { attach: '#tour-intro-panel', text: this.$i18n.t('tour.intro-panel'), setup: setupFn(0) },
                    { attach: '#tour-intro-hint', text: this.$i18n.t('tour.intro-hint'), left: true, setup: setupFn(1) },
                    { attach: '#tour-intro-controls', text: this.$i18n.t('tour.intro-controls'), setup: setupFn(1) },
                    { attach: '#tour-conditions-add', text: this.$i18n.t('tour.conditions-add'), top: true, setup: setupFn('conditions') },
                    { attach: '#tour-conditions-search', text: this.$i18n.t('tour.conditions-search'), setup: setupFn('conditions', true) },
                    { attach: '#tour-conditions-filter', text: this.$i18n.t('tour.conditions-filter'), setup: setupFn('conditions', true) },
                    { attach: '#tour-conditions-list', text: this.$i18n.t('tour.conditions-list'), top: true, setup: setupFn('conditions', true) },
                    { attach: '#tour-conditions-info', text: this.$i18n.t('tour.conditions-info'), top: true, setup: setupFn('conditions', true) },
                    { attach: '#tour-conditions-done', text: this.$i18n.t('tour.conditions-done'), left: true, setup: setupFn('conditions', true) },
                    { attach: '#tour-conditions-healthy', text: this.$i18n.t('tour.conditions-healthy'), top: true, left: true, setup: setupFn('conditions', false) }
                ];
                if (!this.isFamilyCreated) {
                    steps.push({ attach: '#tour-family-names', text: this.$i18n.t('tour.family-names'), top: true, setup: setupFn('nicknames') });
                }
                steps.push({ attach: '#tour-intro-done', text: this.$i18n.t('tour.intro-done'), top: true, setup: setupFn(null) });
                return steps;
            }
        },
        methods: {
            isNicknamesValid() {
                return Object.values(this.nicknames).every((val) => !!val);
            },
            canNavigate() {
                return this.isFamilyCreated || (this.isSelfValid && this.isNicknamesValid());
            },
            async navigate() {
                if (this.canNavigate()) {
                    if (!this.isFamilyCreated) {
                        await this.$store.dispatch('createFamily', this.nicknames);
                    }
                    if (!this.$store.get('error')) {
                        await this.$router.push('/family');
                    }
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .prompt-text {
        ::v-deep b {
            color: var(--v-primary-base);
        }
    }
</style>
