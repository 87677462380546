var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.patients,"item-key":"id","item-class":_vm.rowClass,"dense":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"header.fullName",fn:function(){return [_c('v-icon',{staticClass:"hidden",attrs:{"small":"","left":""}},[_vm._v("fas fa-female fa-fw")]),_vm._v(" "+_vm._s(_vm.$t('provider.columns.name'))+" ")]},proxy:true},{key:"item.fullName",fn:function(ref){
var patient = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-"+_vm._s(patient.gender)+" fa-fw")]),_vm._v(" "+_vm._s(patient.fullName)+" ")]}},{key:"item.age",fn:function(ref){
var patient = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc('common.years', patient.age))+" ")]}},{key:"item.reports",fn:function(ref){
var patient = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","href":("/api/provider/patient/cds?patientId=" + (patient.id) + "&pdf=patient_report_en"),"target":"_blank","disabled":!patient.reportAvailable},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-pdf")]),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-user")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('admin.patientReport')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","href":("/api/provider/patient/cds?patientId=" + (patient.id) + "&pdf=physician_report"),"target":"_blank","disabled":!patient.reportAvailable},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-pdf")]),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("fas fa-user-md")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('admin.providerReport')))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }