<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <div class="d-flex justify-space-between">
                <div class="text-h6 d-flex align-center">
                    <v-icon small left>fas fa-utensils fa-fw</v-icon>
                    {{ $t('labels.diet') }}
                </div>
                <v-icon small class="mx-2 secondary--text" v-if="isValid()">fas fa-check</v-icon>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2 mb-n2 py-3">
            <v-row>
                <v-col class="py-0">
                    <div class="my-3">
                        <v-row no-gutters justify="center">
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.fruit') }}
                                    <Hint :text="$t('hints.fruit')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in fruits" :key="index" class="mt-1 mx-2" hide-details v-model="fruit" :label="value" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.vegetable') }}
                                    <Hint :text="$t('hints.vegetable')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in vegetables" :key="index" class="mt-1 mx-2" hide-details v-model="vegetable" :label="value" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.sugar') }}
                                    <Hint :text="$t('hints.sugar')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in sugars" :key="index" class="mt-1 mx-2" hide-details v-model="sugar" :label="value" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.grain') }}
                                    <Hint :text="$t('hints.grain')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in yesNo" :key="index" class="mt-1 mx-2" hide-details v-model="grain" :label="valueLabel(value)" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="12" class="py-4">
                                <div class="text-subtitle-1 text-center primary--text d-flex justify-center">
                                    {{ $t('fields.fat') }}
                                    <Hint :text="$t('hints.fat')" size="medium"></Hint>
                                </div>
                                <div class="d-flex justify-center flex-wrap">
                                    <v-checkbox v-for="(value, index) in yesNo" :key="index" class="mt-1 mx-2" hide-details v-model="fat" :label="valueLabel(value)" :value="value" on-icon="$radioOn" off-icon="$radioOff"></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';
    import { yesNo } from '@/common';

    export default {
        mixins: [ConditionsMixin, FamilyMemberMixin],
        data: () => ({
            yesNo,
            fruits: [...Array(5).keys()].map((val, idx, arr) => {
                if (idx === arr.length - 1) {
                    return `${idx}+`;
                }
                return `${idx}`;
            }),
            vegetables: [...Array(7).keys()].map((val, idx, arr) => {
                if (idx === arr.length - 1) {
                    return `${idx}+`;
                }
                return `${idx}`;
            }),
            sugars: [...Array(11).keys()].map((val, idx, arr) => {
                if (idx === arr.length - 1) {
                    return `${idx}+`;
                }
                return `${idx}`;
            })
        }),
        computed: {
            fruit: sync('fruit'),
            vegetable: sync('vegetable'),
            sugar: sync('sugar'),
            grain: sync('grain'),
            fat: sync('fat')
        },
        methods: {
            isValid() {
                return this.$store.get('isDietValid', this.uuid);
            }
        }
    };
</script>
