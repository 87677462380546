<template>
    <div class="slider-container">
        <input :class="['slider', trackColor]" ref="slider" :style="cssVars" type="range" :min="min" :max="max" :value="value" @input="input" />
        <output class="ticks" :style="cssVars">
            <span :class="['tick', tickColor]" :style="cssVars" v-for="n in tickRange" :key="n">
                <span v-if="'?' !== displayValue && (low === n || high === n)">{{ n }}</span>
            </span>
        </output>
        <output :class="['thumb', color]" :style="cssVars">
            <div :class="['thumb-range', color]" :style="cssVars"></div>
        </output>
        <output :class="['thumb', color]" :style="cssVars">
            <div class="thumb-value" :style="cssVars">{{ displayValue }}</div>
        </output>
    </div>
</template>

<script>
    import { isNumber, range, throttle } from 'lodash';

    export default {
        data: () => ({
            width: 0,
            tickRange: []
        }),
        props: {
            value: {
                type: Number,
                default: 0
            },
            range: {
                type: Number,
                default: 5
            },
            thickness: {
                type: Number,
                default: 20
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            },
            color: {
                type: String,
                default: 'primary white--text'
            },
            trackColor: {
                type: String,
                default: 'grey lighten-2'
            },
            tickColor: {
                type: String,
                default: 'grey primary--text'
            }
        },
        computed: {
            displayValue() {
                return isNumber(this.value) ? this.value : '?';
            },

            low() {
                return this.value - this.range;
            },
            high() {
                return this.value + this.range;
            },

            pct() {
                return (this.value - this.min) / (this.max - this.min);
            },
            pos() {
                return this.pct * this.width;
            },
            center() {
                return this.width / 2;
            },
            fromCenter() {
                return this.pos - this.center;
            },
            pctFromCenter() {
                return this.fromCenter / this.center;
            },
            offset() {
                return this.pctFromCenter * (this.thumbSize / 2);
            },

            thumbCenterOffset() {
                return this.pos - this.offset;
            },
            thumbSize() {
                return this.thickness * 2;
            },

            tickWidth() {
                return this.width / (this.max - this.min);
            },
            rangeWidth() {
                return this.tickWidth * (this.range * 2);
            },

            cssVars() {
                return {
                    '--thumb-center-offset': this.thumbCenterOffset + 'px',
                    '--thumb-width': this.thumbSize + 'px',
                    '--thumb-height': this.thumbSize + 'px',
                    '--slider-height': this.thickness + 'px',
                    '--border-radius': this.thickness / 2 + 'px',
                    '--range-width': this.rangeWidth + 'px'
                };
            }
        },
        mounted() {
            this.setRange();

            const observer = new ResizeObserver(this.setWidth);
            observer.observe(this.$el);
            this.$once('hook:beforeDestroy', () => {
                observer.disconnect();
            });
        },
        watch: {
            min() {
                this.setRange();
            },
            max() {
                this.setRange();
            }
        },
        methods: {
            input(event) {
                this.$emit('input', event.target.valueAsNumber);
            },
            setRange() {
                this.tickRange = range(this.min, this.max + 1);
                this.setWidth();
            },
            setWidth: throttle(function () {
                this.$nextTick(() => {
                    if (this.$refs.slider) {
                        this.width = this.$refs.slider.clientWidth;
                    }
                });
            }, 250),
            displayAge(age) {
                return age < 0 ? 'pre-birth' : age;
            }
        }
    };
</script>

<style scoped>
    .slider-container {
        position: relative;
        width: 100%;
    }
    .slider {
        -webkit-appearance: none;
        width: 100%;
        outline: none;
        height: var(--slider-height);
        border-radius: var(--border-radius);
        opacity: 0.75;
        -webkit-transition: 0.25s;
        transition: opacity 0.25s;
    }
    .slider:hover {
        opacity: 1;
    }
    .slider:focus {
        outline: none;
    }
    .slider::-moz-focus-outer {
        border: 0;
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: var(--thumb-width);
        height: var(--thumb-height);
        background: transparent;
        border: none;
        cursor: pointer;
    }
    .slider::-moz-range-thumb {
        width: var(--thumb-width);
        height: var(--thumb-height);
        background: transparent;
        border: none;
        cursor: pointer;
    }
    .slider::-ms-thumb {
        width: var(--thumb-width);
        height: var(--thumb-height);
        background: transparent;
        border: none;
        cursor: pointer;
    }
    .thumb {
        position: absolute;
        width: var(--thumb-width);
        height: var(--thumb-height);
        border-radius: 50%;
        top: 0;
        left: var(--thumb-center-offset);
        transform: translateX(-50%) translateY(-25%);
        line-height: var(--slider-height);
        font-size: var(--slider-height);
        font-weight: bold;
        text-align: center;
        pointer-events: none;
    }
    .thumb-range,
    .thumb-value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .thumb-range {
        width: var(--range-width);
        height: var(--slider-height);
        border-radius: var(--border-radius);
        opacity: 0.5;
    }
    .ticks {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(var(--slider-height) / 2);
        padding: calc(var(--slider-height) / 4) calc(var(--thumb-width) / 2);
        display: flex;
        justify-content: space-between;
        pointer-events: none;
    }
    .tick {
        position: relative;
        display: flex;
        justify-content: center;
        width: 1px;
        height: calc(var(--slider-height) / 2);
        line-height: calc(var(--slider-height) * 2.5);
        font-size: calc(var(--slider-height) * 0.75);
        font-weight: bold;
    }
</style>
