<template>
    <v-card flat>
        <v-fab-transition>
            <v-card v-if="!selectedFamilyMember">
                <v-toolbar flat color="grey lighten-3">
                    <div class="text-h6 d-flex align-center">
                        <v-icon small left>fas fa-tree-alt fa-fw</v-icon>
                        {{ $t('labels.family') }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn small text outlined class="btn-outline-solid-primary" color="primary" @click.stop="addTwins()" id="tour-family-twins">
                        <v-icon small>fas fa-user</v-icon>
                        <v-icon left small>fas fa-user</v-icon>
                        {{ $t('family.twins') }}
                    </v-btn>
                    <v-divider vertical inset class="mx-3"></v-divider>
                    <v-icon small class="secondary--text" :class="{ 'error--text': !allFamilyMembersValid }">{{ allFamilyMembersValid ? 'fas fa-check' : 'fal fa-users-slash' }} fa-fw</v-icon>
                </v-toolbar>
                <FamilyList></FamilyList>
            </v-card>
            <v-card outlined v-else :key="selectedFamilyMember.uuid" id="tour-family-details-view">
                <v-toolbar flat dark color="primary">
                    <v-toolbar-title>{{ selectedFamilyMember.nickname || selectedFamilyMember.firstName }}</v-toolbar-title>
                    <v-divider vertical inset class="mx-3"></v-divider>
                    <span class="text-subtitle-1">{{ $t(`relations.${selectedFamilyMember.relation}`) }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text outlined class="btn-outline-solid-white" @click="selectedUuid = null" id="tour-family-back"><v-icon left>fas fa-arrow-to-left</v-icon>{{ $t('labels.backToFamily') }}</v-btn>
                    <v-divider vertical inset class="mx-3"></v-divider>
                    <v-icon small class="secondary--text" :class="{ 'error--text': !isValid(selectedFamilyMember) }">{{ isValid(selectedFamilyMember) ? 'fas fa-user-check' : 'far fa-user-slash' }}</v-icon>
                </v-toolbar>
                <v-expansion-panels accordion v-model="panels">
                    <General :uuid="selectedFamilyMember.uuid"></General>
                    <Demographics :uuid="selectedFamilyMember.uuid"></Demographics>
                    <Conditions :uuid="selectedFamilyMember.uuid"></Conditions>
                </v-expansion-panels>
            </v-card>
        </v-fab-transition>
        <div class="my-6 d-flex justify-center" v-if="!selectedFamilyMember">
            <v-btn x-large :to="studyParameters.risk ? '/risk' : '/report'" color="primary" class="overlay-btn" :disabled="!allFamilyMembersValid" v-if="!studyLocked">
                {{ $t('common.continue') }}
                <v-icon right>fas fa-arrow-right</v-icon>
                <v-overlay absolute :value="!allFamilyMembersValid">
                    <v-icon small>fas fa-lock</v-icon>
                </v-overlay>
            </v-btn>
        </div>
        <Tour :steps="tourSteps"></Tour>
    </v-card>
</template>

<script>
    import { FamilyMixin } from '@/components/FamilyMixin';

    import Conditions from '@/components/Conditions';
    import Demographics from '@/components/Demographics';
    import FamilyList from '@/components/FamilyList';
    import General from '@/components/General';

    import { get } from 'vuex-pathify';

    import goTo from 'vuetify/es5/services/goto';

    export default {
        name: 'Family',
        mixins: [FamilyMixin],
        components: { Conditions, Demographics, FamilyList, General },
        data: () => ({
            panels: 0
        }),
        computed: {
            studyLocked: get('patient/studyLocked'),
            allFamilyMembersValid: get('allFamilyMembersValid'),
            studyParameters: get('patient/studyParameters'),
            tourSteps() {
                const setupFn = (details) => {
                    return () => {
                        if (details && !this.selectedUuid) {
                            this.selectedUuid = this.family[1].uuid;
                        } else if (!details && this.selectedUuid) {
                            this.selectedUuid = null;
                        }
                    };
                };
                return [
                    { text: this.$i18n.t('tour.family-intro'), setup: setupFn(false) },
                    { attach: '#tour-family-sides', text: this.$i18n.t('tour.family-sides'), setup: setupFn(false) },
                    { attach: '#tour-family-side-maternal', text: this.$i18n.t('tour.family-side-maternal'), left: true, setup: setupFn(false) },
                    { attach: '#tour-family-side-paternal', text: this.$i18n.t('tour.family-side-paternal'), left: true, setup: setupFn(false) },
                    { attach: '#tour-family-side-self', text: this.$i18n.t('tour.family-side-self'), top: true, left: true, setup: setupFn(false) },
                    { attach: '#tour-family-add-children', text: this.$i18n.t('tour.family-add-children'), top: true, left: true, setup: setupFn(false) },
                    { attach: '#tour-family-twins', text: this.$i18n.t('tour.family-twins'), left: true, setup: setupFn(false) },
                    { attach: '#tour-family-details', text: this.$i18n.t('tour.family-details'), setup: setupFn(false) },
                    { attach: '#tour-family-details-view', text: this.$i18n.t('tour.family-details-view'), setup: setupFn(true) },
                    { attach: '#tour-family-back', text: this.$i18n.t('tour.family-back'), left: true, setup: setupFn(true) },
                    { attach: '#tour-family-check', text: this.$i18n.t('tour.family-check'), left: true, setup: setupFn(false) }
                ];
            }
        },
        watch: {
            selectedUuid(val) {
                this.$nextTick(() => {
                    goTo(0);
                    this.panels = 0;
                });
            }
        }
    };
</script>
