<template>
    <v-card flat outlined class="mx-3 denser">
        <v-toolbar dense flat dark color="primary">
            <div class="d-flex justify-space-between flex-wrap align-center full-width">
                <div class="d-flex align-center">
                    <v-toolbar-title>{{ name }}</v-toolbar-title>
                    <v-divider vertical class="mx-3"></v-divider>
                    <span class="caption label-text">{{ isSelf ? $t('admin.proband') : $t(`relations.${person.relation}`) }}</span>
                    <v-btn small text @click="showTwins = true" class="ml-3" v-if="person.multiple">
                        <v-icon small v-for="(uuid, index) in person.multiple.fraternal.concat(person.multiple.identical)" :key="index">fas fa-user</v-icon>
                    </v-btn>
                </div>
                <div class="d-flex align-center">
                    <span v-if="dob">
                        <span class="caption label-text text-lowercase">{{ $t('common.dob') }}</span>
                        {{ dob }}
                    </span>
                    <v-divider vertical class="mx-3"></v-divider>
                    <span>
                        {{ ageN }}
                        <span class="caption label-text text-lowercase">
                            {{ ageText }}
                            <span v-if="isDeceased">({{ $t('livings.Deceased') }})</span>
                        </span>
                    </span>
                    <v-divider vertical class="mx-3" v-if="person.gender"></v-divider>
                    <v-icon small left v-if="person.gender">{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                    <span v-if="person.gender">{{ $t(`genders.${person.gender}`) }}</span>
                    <span class="ml-1" v-if="person.genderIdentity">
                        <span class="caption label-text">({{ $t('admin.identifies') }}: </span>
                        <span>{{ $t(`genderIdentities.${person.genderIdentity}`) }}</span>
                        <span class="caption label-text">)</span>
                    </span>
                </div>
            </div>
        </v-toolbar>
        <v-card flat tile class="denser">
            <v-toolbar dense flat color="grey lighten-3">
                <div class="d-flex justify-space-between align-center full-width">
                    <div>
                        <v-icon small left>fas fa-user-tag fa-fw</v-icon>
                        {{ $t('labels.general') }}
                    </div>
                    <div class="d-flex align-center" v-if="isDeceased">
                        <span class="caption label-text mr-1">{{ $t('fields.causeOfDeath') }}:</span>
                        {{ causeOfDeathText(person.causeOfDeath) }}
                    </div>
                    <div class="d-flex align-center">
                        <v-checkbox class="mt-0 ml-3 pt-0" dense readonly hide-details v-model="person.adopted" :label="$t('fields.adopted')"></v-checkbox>
                        <v-checkbox class="mt-0 ml-3 pt-0" dense readonly hide-details v-model="person.cosanguineousParents" :label="$t('fields.cosanguineousParents')"></v-checkbox>
                    </div>
                </div>
            </v-toolbar>
            <v-card-text class="pa-0" v-if="details">
                <div class="d-flex flex-wrap mx-2 my-1">
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.firstName || ' '" :label="$t('fields.firstName')" v-if="isSelf"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.lastName || ' '" :label="$t('fields.lastName')" v-if="isSelf"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.nickname || ' '" :label="$t('fields.nickname')" v-if="!isSelf"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.gender ? $t(`genders.${person.gender}`) : ' '" :label="$t('fields.gender')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.genderIdentity ? $t(`genderIdentities.${person.genderIdentity}`) : ' '" :label="$t('fields.genderIdentity')" v-if="isSelf"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.living ? $t(`livings.${person.living}`) : ' '" :label="$t('fields.living')" v-if="!isSelf"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.ageAtDeathUnknown ? $t('common.unknown') : person.ageAtDeath || ' '" :label="$t('fields.ageAtDeath')" v-if="isDeceased"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.causeOfDeath ? causeOfDeathText(person.causeOfDeath) : ' '" :label="$t('fields.causeOfDeath')" v-if="isDeceased"></v-text-field>
                    <div class="ma-1">
                        <DobPicker readonly hide-details :is-self="true" :is-deceased="isDeceased" :value="{ birthDate: person.birthDate || ' ', age: age }" :label="$t('fields.birthDate')"></DobPicker>
                    </div>
                </div>
                <div class="d-flex flex-wrap mx-2 my-1" v-if="isSelf">
                    <v-textarea class="ma-1" dense outlined readonly hide-details :value="person.line || ' '" :label="$t('fields.line')" :rows="rows(person.line)" no-resize></v-textarea>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.city || ' '" :label="$t('fields.city')"></v-text-field>
                    <v-text-field
                        class="ma-1 address-field"
                        dense
                        outlined
                        readonly
                        hide-details
                        :value="person.state ? `${person.state} — ${regionsByCountryCode[person.country].find((region) => region.shortCode === person.state).name}` : ' '"
                        :label="$t(`fields.${'US' === person.country ? 'state' : 'CA' === person.country ? 'province' : 'region'}`)"
                    ></v-text-field>
                    <v-text-field class="ma-1 address-field" dense outlined readonly hide-details :value="person.postalCode || ' '" :label="$t('fields.postalCode')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.country ? `${person.country} — ${countriesByCode[person.country].countryName}` : ' '" :label="$t('fields.country')"></v-text-field>
                </div>
                <div class="d-flex flex-wrap mx-2 my-1" v-if="isSelf">
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.language ? $t(`languages.${person.language}`) : ' '" :label="$t('fields.language')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.email || ' '" :label="$t('fields.email')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.phone || ' '" :label="$t('fields.phone')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.insurance ? $t(`insurances.${person.insurance}`) : ' '" :label="$t('fields.insurance')"></v-text-field>
                </div>
            </v-card-text>
        </v-card>
        <v-card flat tile class="denser">
            <v-toolbar dense flat color="grey lighten-3">
                <div class="d-flex justify-space-between align-center full-width">
                    <div>
                        <v-icon small left>fas fa-id-card fa-fw</v-icon>
                        {{ $t('labels.demographics') }}
                    </div>
                    <div class="d-flex align-center">
                        <span v-if="person.race && person.race.length > 0">{{ person.race.join(', ') }}</span>
                        <v-divider vertical class="mx-3" v-if="person.ethnicity && person.ethnicity.length > 0"></v-divider>
                        <span v-if="person.ethnicity && person.ethnicity.length > 0">{{ person.ethnicity.join(', ') }}</span>
                    </div>
                </div>
            </v-toolbar>
            <v-card-text v-if="details">
                <div class="d-flex flex-wrap">
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.race && person.race.length > 0 ? person.race.join(', ') : ' '" :label="$t('fields.race')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.ethnicity && person.ethnicity.length > 0 ? person.ethnicity.join(', ') : ' '" :label="$t('fields.ethnicity')"></v-text-field>
                </div>
                <div class="d-flex flex-wrap">
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.birthplace || ' '" :label="$t('fields.birthplace')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.education ? $t(`educationLevels.${person.education}`) : ' '" :label="$t('fields.education')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.work && person.work.length > 0 ? person.work.join(', ') : ' '" :label="$t('fields.work')"></v-text-field>
                </div>
                <div class="d-flex flex-wrap" v-if="isSelf">
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.residenceYouth || ' '" :label="$t('fields.residenceYouth')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.residenceLast15Years || ' '" :label="$t('fields.residenceLast15Years')"></v-text-field>
                    <v-text-field class="ma-1" dense outlined readonly hide-details :value="person.income ? $t(`incomeLevels.${person.income}`) : ' '" :label="$t('fields.income')"></v-text-field>
                </div>
            </v-card-text>
        </v-card>
        <v-card flat tile class="denser">
            <v-toolbar dense flat color="grey lighten-3">
                <div class="d-flex justify-space-between align-center full-width">
                    <div>
                        <v-icon small left>fas fa-first-aid fa-fw</v-icon>
                        {{ $t('labels.conditions') }}
                        <span v-if="person.conditions && person.conditions.length">({{ person.conditions.length }})</span>
                    </div>
                    <div class="d-flex align-center">
                        <v-checkbox class="mt-0 ml-3 pt-0" dense readonly hide-details v-model="person.medicalHistory" :true-value="'healthy'" :false-value="null" :label="$t('medicalHistories.healthy.label')"></v-checkbox>
                        <v-checkbox class="mt-0 ml-3 pt-0" dense readonly hide-details v-model="person.medicalHistory" :true-value="'unknown'" :false-value="null" :label="$t('medicalHistories.unknown.label')" v-if="!isSelf"></v-checkbox>
                    </div>
                </div>
            </v-toolbar>
            <v-card-text class="pa-0">
                <div class="d-flex flex-wrap align-center ma-1" v-if="conditions.length">
                    <div class="d-flex align-center condition" v-for="condition in conditions" :key="condition.uuid">
                        {{ $t(`conditions.${condition.id}.name`) }}
                        <ConditionChips :condition="condition" :person="person" :show-other="true" v-if="!condition.skipQuestions"></ConditionChips>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <v-card flat tile class="denser" v-if="isSelf && risk">
            <v-toolbar dense flat color="grey lighten-3">
                <div class="d-flex justify-space-between align-center full-width">
                    <div>
                        <v-icon small left>fas fa-heartbeat fa-fw</v-icon>
                        {{ $t('labels.cardiovascular') }}
                    </div>
                </div>
            </v-toolbar>
            <v-card-text>
                <div class="d-flex flex-wrap align-center">
                    <ConvertField
                        class="metric-field"
                        readonly
                        :mask="null"
                        :label="$t('fields.height')"
                        :factor="IN_TO_CM"
                        :unit1="{ value: 'in', label: $t('units.in') }"
                        :unit2="{ value: 'cm', label: $t('units.cm') }"
                        v-model="person.height"
                        value-unit="unit1"
                        :mode.sync="heightMode"
                        :alt-display-fn="displayFeetInches"
                    ></ConvertField>
                    <ConvertField
                        class="metric-field"
                        readonly
                        :mask="null"
                        :label="$t('fields.weight')"
                        :factor="LBS_TO_KG"
                        :unit1="{ value: 'lbs', label: $t('units.lbs') }"
                        :unit2="{ value: 'kg', label: $t('units.kg') }"
                        v-model="person.weight"
                        value-unit="unit1"
                        :mode.sync="weightMode"
                    ></ConvertField>
                    <ConvertField
                        class="metric-field"
                        readonly
                        :mask="null"
                        :label="$t('fields.waist')"
                        :factor="IN_TO_CM"
                        :unit1="{ value: 'in', label: $t('units.in') }"
                        :unit2="{ value: 'cm', label: $t('units.cm') }"
                        v-model="person.waist"
                        value-unit="unit1"
                        :mode.sync="waistMode"
                    ></ConvertField>
                    <v-text-field dense outlined class="metric-field metric-field-sm" hide-details readonly :label="$t('fields.bpSystolic')" :value="person.bpSystolic"></v-text-field>
                    <v-text-field dense outlined class="metric-field metric-field-sm" hide-details readonly :label="$t('fields.bpDiastolic')" :value="person.bpDiastolic"></v-text-field>
                </div>
                <div class="d-flex flex-wrap align-center">
                    <div>
                        {{ $t('fields.tobacco') }}
                        <strong class="primary--text">{{ person.tobacco || '—' }}</strong>
                    </div>
                    <div class="d-flex flex-wrap align-center" v-if="'Y' === person.tobacco">
                        <div class="ml-3">
                            {{ $t('fields.tobaccoPacksPerDay') }}
                            <strong class="primary--text">{{ person.tobaccoPacksPerDay || '—' }}</strong>
                        </div>
                        <div class="ml-3">
                            {{ $t('fields.tobaccoYears') }}
                            <strong class="primary--text">{{ person.tobaccoYears || '—' }}</strong>
                        </div>
                        <div class="ml-3">
                            {{ $t('fields.tobaccoQuitYear') }}
                            <strong class="primary--text">{{ person.tobaccoQuitYear || '—' }}</strong>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap align-center">
                    <div>
                        {{ $t('fields.alcohol') }}
                        <strong class="primary--text">{{ person.alcohol || '—' }}</strong>
                    </div>
                    <div class="d-flex flex-wrap align-center" v-if="'Y' === person.alcohol">
                        <div class="ml-3">
                            {{ $t('fields.alcoholDrinksPerWeek') }}
                            <strong class="primary--text">{{ person.alcoholDrinksPerWeek || '—' }}</strong>
                        </div>
                        <div class="ml-3">
                            {{ $t('fields.alcoholYears') }}
                            <strong class="primary--text">{{ person.alcoholYears || '—' }}</strong>
                        </div>
                        <div class="ml-3">
                            {{ $t('fields.alcoholQuitYear') }}
                            <strong class="primary--text">{{ person.alcoholQuitYear || '—' }}</strong>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap align-center mt-3">
                    <ConvertField
                        class="metric-field metric-field-lg"
                        readonly
                        :label="$t('fields.ldl')"
                        :factor="MGPERDL_TO_MMOLPERL"
                        :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                        :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                        v-model="person.ldl"
                        value-unit="unit1"
                        :mode.sync="ldlMode"
                        :precision="1"
                        :mask="null"
                    ></ConvertField>
                    <ConvertField
                        class="metric-field metric-field-lg"
                        readonly
                        :label="$t('fields.hdl')"
                        :factor="MGPERDL_TO_MMOLPERL"
                        :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                        :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                        v-model="person.hdl"
                        value-unit="unit1"
                        :mode.sync="hdlMode"
                        :precision="1"
                        :mask="null"
                    ></ConvertField>
                    <ConvertField
                        class="metric-field metric-field-lg"
                        readonly
                        :label="$t('fields.triglycerides')"
                        :factor="MGPERDL_TO_MMOLPERL"
                        :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                        :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                        v-model="person.triglycerides"
                        value-unit="unit1"
                        :mode.sync="triglyceridesMode"
                        :precision="1"
                        :mask="null"
                    ></ConvertField>
                    <ConvertField
                        class="metric-field metric-field-lg"
                        readonly
                        :label="$t('fields.totalCholesterol')"
                        :factor="MGPERDL_TO_MMOLPERL"
                        :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                        :unit2="{ value: 'mmol/L', label: $t('units.mmol_per_L') }"
                        v-model="person.totalCholesterol"
                        value-unit="unit1"
                        :mode.sync="totalCholesterolMode"
                        :precision="1"
                        :mask="null"
                    ></ConvertField>
                </div>
                <div class="d-flex flex-wrap align-center">
                    <div>
                        {{ $t('fields.cholesterolTestYear') }}
                        <strong class="primary--text">{{ person.cholesterolTestYear || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.cholesterolMedication') }}
                        <strong class="primary--text">{{ person.cholesterolMedication || '—' }}</strong>
                    </div>
                </div>
                <div class="d-flex flex-wrap align-center mt-3">
                    <ConvertField
                        class="metric-field metric-field-lg"
                        readonly
                        :mask="null"
                        :label="$t('fields.hsCRP')"
                        :factor="MGPERDL_TO_MGPERL"
                        :unit1="{ value: 'mg/dL', label: $t('units.mg_per_dL') }"
                        :unit2="{ value: 'mg/L', label: $t('units.mg_per_L') }"
                        v-model="person.hsCRP"
                        value-unit="unit2"
                        :mode.sync="hsCRPMode"
                    ></ConvertField>
                    <div class="ml-1">
                        {{ $t('fields.caIMT') }}
                        <strong class="primary--text">{{ person.caIMT || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.cacCT') }}
                        <strong class="primary--text">{{ person.cacCT || '—' }}</strong>
                    </div>
                </div>
                <div class="d-flex flex-wrap align-center">
                    <div>
                        {{ $t('fields.fruit') }}
                        <strong class="primary--text">{{ person.fruit || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.vegetable') }}
                        <strong class="primary--text">{{ person.vegetable || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.sugar') }}
                        <strong class="primary--text">{{ person.sugar || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.grain') }}
                        <strong class="primary--text">{{ person.grain || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.fat') }}
                        <strong class="primary--text">{{ person.fat || '—' }}</strong>
                    </div>
                </div>
                <div class="d-flex flex-wrap align-center">
                    <div>
                        {{ $t('fields.moderateExercise') }}
                        <strong class="primary--text">{{ person.moderateExercise || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.vigorousExercise') }}
                        <strong class="primary--text">{{ person.vigorousExercise || '—' }}</strong>
                    </div>
                    <div class="ml-3">
                        {{ $t('fields.strengthTraining') }}
                        <strong class="primary--text">{{ person.strengthTraining || '—' }}</strong>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog v-model="showTwins" :max-width="480" persistent>
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-toolbar-title>{{ $t('labels.viewTwins') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showTwins = false">
                        <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="px-6 pt-2 pb-0">
                    <v-list subheader>
                        <v-list-item v-for="person in twinsList" :key="person.uuid">
                            <v-list-item-avatar>
                                <v-icon>{{ 'male' === person.gender ? 'fas fa-male' : 'fas fa-female' }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ person.nickname }}</v-list-item-title>
                                <v-list-item-subtitle>{{ $t(`relations.${person.relation}`) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action-text>
                                <span class="text-subtitle-1">{{ $t(`labels.${twinType(person.uuid)}`) }}</span>
                            </v-list-item-action-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="showTwins = false">
                        <v-icon left>fas fa-check</v-icon>
                        {{ $t('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import ConditionChips from '@/components/ConditionChips';
    import ConvertField from '@/components/ConvertField';
    import DobPicker from '@/components/DobPicker';
    import { ResearcherMixin } from '@/components/researcher/ResearcherMixin';
    import { calculateAge, fullName, countriesByCode, regionsByCountryCode } from '@/common';
    import { find, includes } from 'lodash';
    import moment from 'moment';

    export default {
        components: { ConditionChips, ConvertField, DobPicker },
        mixins: [ResearcherMixin],
        props: {
            person: {
                type: Object,
                required: true
            },
            family: {
                type: Array,
                required: true
            },
            details: {
                type: Boolean,
                default: false
            },
            risk: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            showTwins: false,
            countriesByCode,
            regionsByCountryCode
        }),
        computed: {
            isSelf() {
                return 'SELF' === this.person.relation;
            },
            isDeceased() {
                return 'Deceased' === this.person.living;
            },
            name() {
                return this.isSelf ? fullName(this.person) : this.person.nickname;
            },
            age() {
                return this.person.birthDate ? calculateAge(this.person.birthDate) : this.person.age;
            },
            ageLabel() {
                let age = this.age;
                if (this.isDeceased) {
                    age = this.person.ageAtDeath;
                }
                return this.$i18n.tc('common.years', age);
            },
            ageN() {
                if (/\d+ .+/.test(this.ageLabel)) {
                    return this.ageLabel.split(' ')[0];
                }
                return null;
            },
            ageText() {
                if (/\d+ .+/.test(this.ageLabel)) {
                    return this.ageLabel.split(' ')[1];
                }
                return null;
            },
            dob() {
                return this.person.birthDate ? moment(this.person.birthDate).format('l') : null;
            },
            twinsList() {
                if (this.person.multiple) {
                    return this.person.multiple.fraternal.concat(this.person.multiple.identical).map((uuid) => find(this.family, { uuid: uuid }));
                }
                return null;
            },
            conditions() {
                return []
                    .concat(this.person.conditions)
                    .concat(this.person.cancerScreenConditions.filter((condition) => this.risk && 'Y' === condition.yesNo))
                    .concat(this.person.pregnancyScreenConditions.filter((condition) => this.risk && 'Y' === condition.yesNo));
            }
        },
        methods: {
            rows(text) {
                return (text || '').split('\n').length + 1;
            },
            twinType(uuid) {
                return includes(this.person.multiple.fraternal, uuid) ? 'fraternal' : 'identical';
            },
            causeOfDeathText(causeOfDeath) {
                if (/^condition:/.test(causeOfDeath)) {
                    const key = causeOfDeath.replace(/^condition:/, '');
                    return this.$i18n.t(`conditions.${key}.name`);
                }
                return this.$i18n.t(`causesOfDeath.${causeOfDeath}`);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .label-text {
        opacity: 0.75;
    }
    .condition {
        min-height: 40px;
        padding: 0 8px;
        margin: 4px;
        border: thin solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }
    .address-field {
        min-width: 100px;
    }
    .metric-field {
        min-width: 200px;
        max-width: 200px;
        margin-right: 8px;
        margin-bottom: 8px;
        &.metric-field-sm {
            min-width: 75px;
            max-width: 75px;
        }
        &.metric-field-lg {
            min-width: 275px;
            max-width: 275px;
        }
    }
    .denser {
        ::v-deep {
            .v-toolbar,
            .v-toolbar__content {
                height: unset !important;
            }
            .v-toolbar__content {
                padding: 4px 12px;
            }
            .v-toolbar__title {
                font-size: 1rem;
            }
            .v-btn-toggle .v-btn {
                height: 24px !important;
            }
            .v-card__text {
                padding: 8px;
            }
            .v-label {
                font-size: 14px;
            }
            .v-input {
                font-size: 14px;
                .v-icon {
                    font-size: 16px;
                }
                .v-input__append-inner {
                    margin-top: 4px !important;
                }
                .v-input--selection-controls__input {
                    margin-right: 0px;
                }
                .v-input__slot {
                    min-height: 32px !important;
                }
                textarea {
                    line-height: 1;
                    margin-top: 10px !important;
                    margin-bottom: -10px !important;
                }
            }
        }
    }
</style>
