<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <div class="d-flex justify-space-between">
                <div class="text-h6 d-flex align-center">
                    <v-icon small left>{{ icon }}</v-icon>
                    {{ $t(label) }}
                </div>
                <v-icon small class="mx-2 secondary--text" v-if="isValid()">fas fa-check</v-icon>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager class="expansion-panel--padless">
            <div v-if="unionConditions && unionConditions.length">
                <div class="primary white--text text-subtitle-1 text-center">{{ $t('hints.screening') }}</div>
                <v-divider></v-divider>
                <v-list subheader class="condition-list">
                    <template v-for="(condition, index) in unionConditions">
                        <v-list-item :key="condition.id" style="height: 64px">
                            <v-list-item-action class="my-0 mr-3">
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    :class="{ hidden: !hasSnomed(condition) && !infoLink(condition) }"
                                    target="_blank"
                                    :href="infoLink(condition)"
                                    @click.stop="!infoLink(condition) && $root.$medlinePlus.show(condition)"
                                >
                                    <v-icon small>fas fa-info-circle</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-content class="flex-shrink-1">
                                <v-list-item-title class="primary--text">{{ $t(`conditions.${condition.id}.name`) }}</v-list-item-title>
                                <v-list-item-subtitle class="wrap" v-html="$t(`conditions.${condition.id}.hint`)"></v-list-item-subtitle>
                            </v-list-item-content>
                            <ConditionChips :condition="condition" :person="person" v-if="'Y' === condition.yesNo && !condition.skipQuestions"></ConditionChips>
                            <v-list-item-action-text class="my-0 ml-3 flex-shrink-0">
                                <div class="d-flex align-center flex-shrink-0">
                                    <v-btn icon small class="mr-3" color="primary" @click="update(person, condition, property)" v-if="'Y' === condition.yesNo && !condition.skipQuestions">
                                        <v-icon small>fas fa-edit</v-icon>
                                    </v-btn>
                                    <v-radio-group class="mt-1 mb-2" hide-details row v-model="condition.yesNo" @change="toggle(condition)">
                                        <v-radio v-for="(value, index) in yesNo" :key="index" :label="valueLabel(value)" :value="value"></v-radio>
                                    </v-radio-group>
                                </div>
                            </v-list-item-action-text>
                        </v-list-item>
                        <v-divider :key="index" v-if="index < unionConditions.length - 1"></v-divider>
                    </template>
                </v-list>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { FamilyMemberMixin } from '@/components/FamilyMemberMixin';
    import ConditionChips from '@/components/ConditionChips';
    import { yesNo } from '@/common';
    import { filter, sortBy, unionBy } from 'lodash';

    export default {
        mixins: [ConditionsMixin, FamilyMemberMixin],
        components: { ConditionChips },
        props: {
            icon: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            conditions: {
                type: Array,
                required: true
            },
            property: {
                type: String,
                required: true
            },
            isValidMutation: {
                type: String,
                required: true
            }
        },
        data: () => ({
            yesNo
        }),
        computed: {
            filteredConditions() {
                return filter(this.conditions, (condition) => {
                    return condition.hasOwnProperty('gender') ? condition.gender === this.person.gender : true; // eslint-disable-line no-prototype-builtins
                });
            },
            unionConditions() {
                return sortBy(unionBy(this.person[this.property], this.filteredConditions, 'id'), 'order');
            }
        },
        methods: {
            isValid() {
                return this.$store.get(this.isValidMutation, this.uuid);
            },
            toggle(condition) {
                if (!this.has(this.person, condition, this.property)) {
                    condition = this.copy(condition);
                    this.$store.commit('addFamilyMemberCondition', { person: this.person, condition, property: this.property });
                }
                if ('Y' === condition.yesNo) {
                    if (condition.skipQuestions) {
                        this.$store.commit('updateFamilyMemberCondition', { person: this.person, condition, property: this.property });
                    } else {
                        this.update(this.person, condition, this.property, () => {
                            condition.yesNo = null;
                            this.$store.commit('updateFamilyMemberCondition', { person: this.person, condition, property: this.property });
                        });
                    }
                } else {
                    this.$store.commit('updateFamilyMemberCondition', { person: this.person, condition, property: this.property });
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .wrap {
        white-space: normal;
    }
</style>
