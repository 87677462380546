<template>
    <v-dialog v-model="dialog" :max-width="600" persistent>
        <v-card v-if="user">
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ !!user.id ? $t('admin.editUser') : $t('admin.addUser') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0">
                <v-switch inset class="mt-0" v-model="user.enabled" :label="$t('admin.columns.enabled')" v-if="!!user.id"></v-switch>
                <v-text-field dense outlined v-model="user.name" :label="$t('admin.columns.name')" :hint="$t('admin.hints.required')" persistent-hint></v-text-field>
                <v-text-field dense outlined v-model="user.username" :label="$t('admin.columns.username')" :hint="$t('admin.hints.required')" persistent-hint></v-text-field>
                <v-text-field dense outlined v-model="user.password" :label="$t('admin.columns.password')" :hint="$t('admin.hints.leaveBlankToGenerate')" persistent-hint type="password" v-if="!user.id"></v-text-field>
                <v-text-field dense outlined v-model="user.email" :label="$t('admin.columns.email')"></v-text-field>
                <v-card outlined>
                    <v-toolbar dense dark flat color="accent">
                        <v-toolbar-title>{{ $t('admin.columns.studies') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-list dense flat>
                        <v-list-item-group v-model="user.meta.studies" multiple>
                            <v-list-item v-for="(study, index) in studies" :key="index" :value="study">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action class="my-0 mr-2">
                                        <v-icon :color="active ? 'primary' : ''">{{ active ? 'fas fa-check-square' : 'far fa-square' }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t(`studies.${study}`) }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()" :disabled="!isValid">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { studies } from '@/common';
    import { cloneDeep } from 'lodash';

    export default {
        data: () => ({
            dialog: false,
            user: null,
            resolve: null,
            reject: null
        }),
        computed: {
            studies() {
                return studies.concat('<none>');
            },
            isValid() {
                return !!this.user?.username && !!this.user?.name;
            }
        },
        methods: {
            show(user) {
                this.dialog = true;
                this.user = cloneDeep(user);
                if (!this.user.meta) {
                    this.user.meta = {};
                }
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            ok() {
                this.resolve(this.user);
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
