<template>
    <v-dialog v-model="menu" width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-row no-gutters>
                <v-col>
                    <v-text-field
                        ref="dateRef"
                        dense
                        outlined
                        :hint="displayFormat"
                        v-mask="{ mask: '##/##/####', tokens: { '#': { pattern: /\d| / } } }"
                        :value="display"
                        @input="dateInput"
                        @blur="dateBlur"
                        :label="`${label}${error ? '*' : ''}`"
                        :error="error"
                        :disabled="disabled"
                        :readonly="readonly"
                        :hide-details="hideDetails"
                        :persistent-placeholder="persistentPlaceholder"
                    >
                        <template #prepend-inner>
                            <v-icon class="mx-1 primary--text" v-on="on" v-bind="attrs" tabindex="-1" v-ripple>fas fa-calendar-day</v-icon>
                        </template>
                        <template #append v-if="isSelf && !isDeceased">
                            <v-chip label pill small class="pr-0">
                                {{ $t('fields.age') }}
                                <v-chip label pill small color="ml-2 primary white--text">{{ value.age }}</v-chip>
                            </v-chip>
                        </template>
                    </v-text-field>
                </v-col>
                <span class="mx-2 pt-2" v-if="!isSelf && !isDeceased">{{ $t('common.or') }}</span>
                <v-col cols="3" v-if="!isSelf && !isDeceased">
                    <v-text-field
                        dense
                        outlined
                        hide-details
                        :value="value.age"
                        @input="ageInput"
                        :label="`${$t('fields.age')}${error ? '*' : ''}`"
                        :error="error"
                        type="tel"
                        v-mask="'###'"
                        :disabled="ageDisabled"
                        :persistent-placeholder="persistentPlaceholder"
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <v-card>
            <v-toolbar dense dark color="primary">
                <div>{{ $t('fields.birthDate') }}</div>
                <v-spacer></v-spacer>
                <v-btn icon small @click="menu = false">
                    <v-icon small>fas fa-times</v-icon>
                </v-btn>
            </v-toolbar>
            <v-date-picker :active-picker.sync="activePicker" :max="max" :min="min" no-title :value="picker" @input="dateInput"></v-date-picker>
        </v-card>
    </v-dialog>
</template>

<script>
    import { calculateAge } from '@/common';
    import moment from 'moment';

    const DISPLAY_FORMAT = 'MM/DD/YYYY';
    const DATA_FORMAT = 'YYYY-MM-DD';
    const maxMoment = moment();
    const minMoment = moment().subtract(300, 'years');

    export default {
        data: () => ({
            max: maxMoment.toISOString().substring(0, 10),
            min: minMoment.toISOString().substring(0, 10),
            displayFormat: DISPLAY_FORMAT,
            dataFormat: DATA_FORMAT,
            activePicker: null,
            menu: false
        }),
        props: {
            value: {
                type: Object,
                default: null
            },
            isSelf: {
                type: Boolean,
                required: true
            },
            isDeceased: {
                type: Boolean,
                required: true
            },
            label: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            hideDetails: {
                type: Boolean,
                default: false
            },
            persistentPlaceholder: {
                type: Boolean,
                default: false
            },
            error: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            menu(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'));
            }
        },
        computed: {
            ageDisabled() {
                return this.disabled || !!this.value?.birthDate;
            },
            picker() {
                if (this.readonly) {
                    return null;
                }
                return this.value?.birthDate ? moment(this.value.birthDate).format(this.dataFormat) : null;
            },
            display() {
                if (this.readonly && this.value.birthDate && !this.value.birthDate.trim()) {
                    return this.value.birthDate;
                }
                return this.value?.birthDate ? moment(this.value.birthDate).format(this.displayFormat) : null;
            }
        },
        methods: {
            ageInput(val) {
                this.value.birthDate = null;
                this.$emit('input', { birthDate: this.value.birthDate, age: parseInt(val) });
            },
            clear() {
                this.value.age = null;
                this.value.birthDate = null;
                this.$emit('input', { birthDate: this.value.birthDate, age: this.value.age });
            },
            dateBlur() {
                const input = this.$refs.dateRef.$el.getElementsByTagName('input')[0];
                const value = this.display;
                if (input.value !== value) {
                    this.dateInput(null);
                    setTimeout(() => {
                        this.dateInput(value);
                    }, 0);
                }
            },
            dateInput(val) {
                if (typeof val === 'string' && val.length < 10) {
                    if (0 === val.length) {
                        this.clear();
                    }
                    return;
                }

                const momentDate = moment(val, [this.displayFormat, this.dataFormat]);
                if (momentDate.isValid() && momentDate.isBefore(maxMoment) && momentDate.isAfter(minMoment)) {
                    this.value.age = calculateAge(momentDate);
                    this.$emit('input', { birthDate: momentDate.format(this.dataFormat), age: this.value.age });
                    this.menu = false;
                } else {
                    this.clear();
                }
            }
        }
    };
</script>
