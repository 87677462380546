<template>
    <v-dialog v-model="dialog" :max-width="800" persistent>
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t('admin.addPatient') }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-5 pb-0" v-if="patient">
                <v-text-field dense outlined v-model="patient.firstName" :label="$t('admin.columns.firstName')" :hint="$t('admin.hints.required')" persistent-hint></v-text-field>
                <v-text-field dense outlined v-model="patient.lastName" :label="$t('admin.columns.lastName')" :hint="$t('admin.hints.required')" persistent-hint></v-text-field>
                <div class="mb-n2">
                    <DobPicker v-model="birthDateAge" :is-self="true" :is-deceased="true" :label="$t('admin.columns.birthDate')"></DobPicker>
                </div>
                <v-text-field dense outlined v-model="patient.identifier" :label="$t('admin.columns.identifier')" :hint="$t('admin.hints.leaveBlankToGenerate')" persistent-hint></v-text-field>
                <v-text-field dense outlined v-model="patient.username" :label="$t('admin.columns.username')" :hint="$t('admin.hints.leaveBlankToGenerate')" persistent-hint></v-text-field>
                <v-text-field dense outlined v-model="patient.password" :label="$t('admin.columns.password')" :hint="$t('admin.hints.leaveBlankToGenerate')" persistent-hint type="password"></v-text-field>
                <v-text-field dense outlined v-model="patient.email" :label="$t('admin.columns.email')"></v-text-field>
                <v-card outlined v-if="availableStudies.length > 0">
                    <v-toolbar dense dark flat color="accent">
                        <v-toolbar-title>{{ $t('admin.columns.studies') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <span>{{ $t('admin.hints.required') }}</span>
                    </v-toolbar>
                    <v-list dense flat>
                        <v-list-item v-for="(study, index) in patient.studies" :key="index">
                            <template v-slot:default="{ active }">
                                <v-list-item-content class="row">
                                    <div class="col-4">
                                        <v-checkbox dense hide-details v-model="study.selected" :label="$t(`studies.${study.id}`)"></v-checkbox>
                                    </div>
                                    <div v-for="(tag, i) in studyParameters(study.id).requiredTags" :key="`rt-${i}`" class="col-4">
                                        <v-text-field dense outlined v-model="patient.studies[index][tag]" :label="tag" hide-details></v-text-field>
                                    </div>
                                    <div v-for="(tag, i) in studyParameters(study.id).optionalTags" :key="`ot-${i}`" class="col-4">
                                        <v-text-field dense outlined v-model="patient.studies[index][tag]" :label="tag" hide-details></v-text-field>
                                    </div>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()" :disabled="!isValid">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import DobPicker from '@/components/DobPicker';
    import { studies, studyParameters } from '@/common';
    import { get } from 'vuex-pathify';
    import { filter, includes } from 'lodash';

    export default {
        components: { DobPicker },
        data: () => ({
            dialog: false,
            patient: null,
            resolve: null,
            reject: null
        }),
        computed: {
            isValid() {
                if (this.availableStudies.length > 0) {
                    return !!this.patient?.firstName && !!this.patient?.lastName && this.patient?.studies?.filter((study) => study.selected).length > 0;
                }
                return !!this.patient?.firstName && !!this.patient?.lastName;
            },
            birthDateAge: {
                get() {
                    return { birthDate: this.patient.birthDate, age: this.patient.age };
                },
                set(value) {
                    this.patient.birthDate = value.birthDate;
                    this.patient.age = value.age;
                }
            },
            user: get('user'),
            isSuperAdmin() {
                return includes(this.user.roles, 'superadmin');
            },
            availableStudies() {
                if (this.isSuperAdmin) {
                    return studies;
                }
                const regex = new RegExp(`^${this.user.meta?.studies?.join('$|^')}$`);
                if (!regex) {
                    return [];
                }
                return filter(studies, (studyId) => {
                    return regex.test(studyId);
                });
            }
        },
        methods: {
            studyParameters,
            show(organizationId) {
                this.dialog = true;
                this.patient = {
                    organizationId,
                    firstName: '',
                    lastName: '',
                    birthDate: null,
                    age: null,
                    identifier: '',
                    username: '',
                    password: '',
                    email: '',
                    studies: this.availableStudies.map((study) => {
                        const _study = {
                            id: study,
                            selected: 1 === this.availableStudies.length
                        };
                        this.studyParameters(study).requiredTags.forEach((tag) => (_study[tag] = null));
                        this.studyParameters(study).optionalTags.forEach((tag) => (_study[tag] = null));
                        return _study;
                    })
                };
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            ok() {
                this.resolve(this.patient);
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>
