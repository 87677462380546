<template>
    <v-dialog v-model="dialog" :max-width="800" persistent>
        <v-card v-if="dialog">
            <v-toolbar dense dark color="primary">
                <v-toolbar-title>{{ $t('admin.editStudies') }}</v-toolbar-title>
            </v-toolbar>
            <v-data-table :headers="headers" :items="studies" item-key="id" dense hide-default-footer class="mt-3">
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.edit="{ item: study }">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn small icon color="primary" @click.stop="removeStudy(study)" :disabled="!canEdit(study)" v-bind="attrs" v-on="on">
                                <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('admin.deleteStudy') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn small icon color="primary" @click.stop="toggleLock(study)" :disabled="!canEdit(study)" v-bind="attrs" v-on="on">
                                <v-icon small>fas {{ study.locked || isStudyLocked(study) ? 'fa-lock' : 'fa-unlock' }}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ study.locked || isStudyLocked(study) ? $t('admin.unlock') : $t('admin.lock') }}</span>
                    </v-tooltip>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.id="{ item: study }">
                    <span :class="{ 'font-weight-bold': meta.selectedStudyId === study.id }">{{ study.id }}</span>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.started="{ item: study }">
                    <span v-if="study.started">{{ study.started | date }}</span>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.finished="{ item: study }">
                    <span v-if="study.finished">{{ study.finished | date }}</span>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template #item.studyDays="{ item: study }">
                    <v-chip label pill small color="accent black--text">
                        {{ studyDays(study) }}
                        <v-icon x-small class="ml-1" v-if="isStudyLocked(study)">fas fa-lock</v-icon>
                    </v-chip>
                </template>
            </v-data-table>
            <v-card-actions>
                <v-select dense outlined v-model="newStudyId" :items="availableStudies" hide-details class="action-select">
                    <template #append-outer>
                        <v-btn small color="primary" @click="addStudy(newStudyId)" :disabled="!newStudyId">
                            <v-icon left small>fas fa-plus</v-icon>
                            {{ $t('admin.addStudy') }}
                        </v-btn>
                    </template>
                </v-select>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="ok()">
                    <v-icon left>fas fa-check</v-icon>
                    {{ $t('common.ok') }}
                </v-btn>
                <v-btn text @click="cancel()">
                    <v-icon left>fas fa-times</v-icon>
                    {{ $t('common.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { isStudyLocked, studies, studyDays } from '@/common';
    import { get } from 'vuex-pathify';
    import { cloneDeep, difference, filter, findIndex, includes } from 'lodash';

    export default {
        data() {
            return {
                dialog: false,
                meta: null,
                studies: null,
                newStudyId: null,
                headers: [
                    { text: '', value: 'edit', align: 'center', width: '100px', sortable: false, divider: true },
                    { text: this.$i18n.t('admin.columns.id'), value: 'id' },
                    { text: this.$i18n.t('admin.columns.started'), value: 'started' },
                    { text: this.$i18n.t('admin.columns.finished'), value: 'finished' },
                    { text: this.$i18n.t('admin.studyDays'), value: 'studyDays', align: 'center', sortable: false }
                ],
                resolve: null,
                reject: null
            };
        },
        computed: {
            user: get('user'),
            isSuperAdmin() {
                return includes(this.user.roles, 'superadmin');
            },
            availableStudies() {
                const available = difference(
                    studies,
                    this.studies.map((study) => study.id)
                );
                if (this.isSuperAdmin) {
                    return available;
                }
                const regex = new RegExp(`^${this.user.meta?.studies?.join('$|^')}$`);
                if (!regex) {
                    return [];
                }
                return filter(available, (studyId) => {
                    return regex.test(studyId);
                });
            }
        },
        methods: {
            isStudyLocked,
            studyDays,
            show(meta) {
                this.dialog = true;
                this.meta = cloneDeep(meta || {});
                this.studies = cloneDeep(this.meta.studies || []);
                this.newStudyId = null;
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            canEdit(study) {
                if (this.isSuperAdmin) {
                    return true;
                }
                const regex = new RegExp(`^${this.user.meta?.studies?.join('$|^')}$`);
                if (!regex) {
                    return false;
                }
                return regex.test(study.id);
            },
            addStudy(id) {
                this.studies.push({ id, started: new Date(), finished: null });
                if (!this.meta.selectedStudyId) {
                    this.meta.selectedStudyId = id;
                }
                this.newStudyId = null;
            },
            removeStudy(study) {
                const index = findIndex(this.studies, { id: study.id });
                this.studies.splice(index, 1);
                if (this.meta.selectedStudyId === study.id) {
                    this.meta.selectedStudyId = null;
                }
                if (!this.meta.selectedStudyId && this.studies && this.studies.length) {
                    this.meta.selectedStudyId = this.studies[0].id;
                }
            },
            toggleLock(study) {
                this.$set(study, 'locked', !(study.locked || isStudyLocked(study)));
            },
            ok() {
                this.meta.studies = this.studies;
                this.resolve(this.meta);
                this.dialog = false;
            },
            cancel() {
                this.reject();
                this.dialog = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .action-select {
        width: 25%;
        ::v-deep .v-input__append-outer {
            margin-top: 6px !important;
        }
    }
</style>
