<template>
    <v-card flat>
        <v-img class="mt-6" :max-height="200" contain :src="require('@/assets/logo.png')" :alt="$t('common.metree')"></v-img>
        <v-card-text>
            <div class="d-flex justify-center" v-if="!simple">
                <v-radio-group row mandatory v-model="type">
                    <v-radio class="mx-6" value="patient" color="primary">
                        <template #label>
                            <div class="my-3">
                                <v-icon size="96px" color="primary">fas fa-user</v-icon>
                            </div>
                            <div class="radio-label patient" v-html="$t('login.patient')"></div>
                        </template>
                    </v-radio>
                    <v-radio class="mx-6" value="provider" color="secondary">
                        <template #label>
                            <div class="my-3">
                                <v-icon size="96px" color="secondary">fas fa-user-md</v-icon>
                            </div>
                            <div class="radio-label provider" v-html="$t('login.provider')"></div>
                        </template>
                    </v-radio>
                    <v-radio class="mx-6" value="researcher" color="accent">
                        <template #label>
                            <div class="my-3">
                                <v-icon size="96px" color="accent">fas fa-user-tie</v-icon>
                            </div>
                            <div class="radio-label researcher" v-html="$t('login.researcher')"></div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>

            <div v-if="filteredEhrs.length && !simple">
                <div class="text-center text-subtitle-1">{{ $t('login.partner') }}</div>
                <v-card outlined v-for="(ehr, index) in filteredEhrs" :key="index" :href="`/oauth/login/${ehr.slug}?type=${type}`">
                    <v-card-text class="py-3">
                        <div class="d-flex align-center justify-space-between">
                            <v-img :max-width="200" :height="50" contain :src="ehr.organization.logo" :alt="ehr.organization.name"></v-img>
                            <div class="text-subtitle-2">{{ ehr.name }}</div>
                        </div>
                    </v-card-text>
                </v-card>

                <div class="d-flex align-center ma-6 px-12">
                    <v-divider></v-divider>
                    <em class="mx-6">{{ $t('common.or') }}</em>
                    <v-divider></v-divider>
                </div>
            </div>

            <div class="text-center text-subtitle-1">{{ $t('login.credentials') }}</div>
            <v-card outlined class="pa-6">
                <v-form @submit.prevent="submit">
                    <v-text-field ref="username" prepend-inner-icon="fas fa-user" v-model="username" :label="$t('login.username')" v-if="!simple"></v-text-field>
                    <v-text-field ref="password" prepend-inner-icon="fas fa-lock-alt" v-model="password" :label="$t('login.password')" type="password"></v-text-field>
                    <div class="mt-3">
                        <v-btn color="primary" type="submit"><v-icon left small>fas fa-sign-in</v-icon>{{ $t('login.login') }}</v-btn>
                        <span class="px-3 error--text">{{ error }}</span>
                    </div>
                </v-form>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
    import { filter } from 'lodash';

    export default {
        name: 'Login',
        data: () => ({
            type: 'patient',
            ehrs: null,
            simple: false,
            username: '',
            password: '',
            error: ''
        }),
        computed: {
            filteredEhrs() {
                return filter(this.ehrs, { type: this.type, visible: true });
            }
        },
        methods: {
            submit() {
                this.$store.commit('busy', true);
                this.error = '';
                this.$api
                    .post('/login', { username: this.username, password: this.password })
                    .then(async () => {
                        await this.$store.dispatch('init');
                        await this.$router.push('/');
                    })
                    .catch((error) => {
                        this.error = error.response.data;
                    })
                    .finally(() => {
                        this.$store.commit('busy', false);
                    });
            }
        },
        async mounted() {
            if (this.$route.query.patient) {
                this.type = 'patient';
                this.username = this.$route.query.patient;
                this.simple = true;
                this.$refs.password.focus();
                return;
            }

            try {
                const { data: ehrs } = await this.$api.get('/oauth/list');
                this.ehrs = ehrs;
            } catch (error) {
                this.ehrs = null;
            }
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .radio-label {
        &.patient b {
            color: var(--v-primary-base);
        }
        &.provider b {
            color: var(--v-secondary-base);
        }
        &.researcher b {
            color: var(--v-accent-base);
        }
    }
    ::v-deep .v-radio {
        display: block;
        text-align: center;
        .v-input--selection-controls__input,
        .v-label {
            display: block;
        }
        .v-input--selection-controls__input {
            margin: 0 auto;
        }
    }
</style>
