<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <div class="d-flex justify-space-between align-center">
                <div class="text-h6 d-flex align-center">
                    <v-icon small left>fas fa-id-card fa-fw</v-icon>
                    {{ $t('labels.demographics') }}
                </div>
                <v-icon small class="mx-2 secondary--text" v-if="isValid">fas fa-check</v-icon>
                <div class="mx-2 text-right" v-else>
                    <span>{{ $t('common.required') }}:</span>
                    <span v-for="(field, index) in required" :key="index">
                        <em v-if="index > 0">,</em>
                        <span class="ml-1">
                            <span v-if="isArray(field)">
                                <span v-for="(f, fIndex) in field" :key="fIndex">
                                    <em class="secondary--text" v-if="fIndex > 0"> {{ $t('common.or') }} </em>
                                    <span class="error--text">{{ $t(`fields.${f}`) }}</span>
                                </span>
                            </span>
                            <span class="error--text" v-else>{{ $t(`fields.${field}`) }}</span>
                        </span>
                    </span>
                </div>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager class="mt-6 py-3">
            <v-row>
                <v-col class="py-0">
                    <v-select
                        dense
                        outlined
                        persistent-placeholder
                        v-model="race"
                        :items="races"
                        :item-text="(item) => $t(`races.${item.value}`)"
                        :label="`${$t('fields.race')}${required.indexOf('race') > -1 ? '*' : ''}`"
                        :error="required.indexOf('race') > -1"
                        multiple
                    >
                        <template v-slot:selection="{ item, index }">
                            <span class="text-truncate" v-if="1 === race.length">{{ $t(`races.${item.value}`) }}</span>
                            <span class="text-truncate" v-else-if="0 === index">{{ $tc('common.selected', race.length) }}</span>
                        </template>
                        <template #append-outer>
                            <Hint :text="$t('hints.race')" :title="$t('fields.race')" id="tour-intro-hint"></Hint>
                        </template>
                    </v-select>
                    <v-select
                        dense
                        outlined
                        persistent-placeholder
                        v-model="ethnicity"
                        :items="ethnicities"
                        :item-text="(item) => $t(`ethnicities.${item.value}`)"
                        :label="`${$t('fields.ethnicity')}${required.indexOf('ethnicity') > -1 ? '*' : ''}`"
                        :error="required.indexOf('ethnicity') > -1"
                        multiple
                    >
                        <template v-slot:selection="{ item, index }">
                            <span class="text-truncate" v-if="1 === ethnicity.length">{{ $t(`ethnicities.${item.value}`) }}</span>
                            <span class="text-truncate" v-else-if="0 === index">{{ $tc('common.selected', ethnicity.length) }}</span>
                        </template>
                    </v-select>
                    <v-text-field dense outlined persistent-placeholder v-model="birthplace" :label="$t('fields.birthplace')"></v-text-field>
                    <v-text-field dense outlined persistent-placeholder v-model="residenceYouth" :label="$t('fields.residenceYouth')" v-if="isSelf"></v-text-field>
                    <v-text-field dense outlined persistent-placeholder v-model="residenceLast15Years" :label="$t('fields.residenceLast15Years')" v-if="isSelf"></v-text-field>
                    <v-select dense outlined persistent-placeholder v-model="education" :items="educationLevels" :item-text="(item) => $t(`educationLevels.${item.value}`)" :label="$t('fields.education')"></v-select>
                    <v-select dense outlined persistent-placeholder v-model="income" :items="incomeLevels" :item-text="(item) => $t(`incomeLevels.${item.value}`)" :label="$t('fields.income')" v-if="isSelf"></v-select>
                    <v-select dense outlined persistent-placeholder v-model="work" :items="typesOfWork" :item-text="(item) => $t(`typesOfWork.${item.value}`)" :label="$t('fields.work')" multiple @change="checkOtherWork()">
                        <template v-slot:selection="{ item, index }">
                            <span class="text-truncate" v-if="1 === work.length">{{ $t(`typesOfWork.${item.value}`) }}</span>
                            <span class="text-truncate" v-else-if="0 === index">{{ $tc('common.selected', work.length) }}</span>
                        </template>
                        <template #append-outer>
                            <Hint :text="$t('hints.work')" :title="$t('fields.work')"></Hint>
                        </template>
                    </v-select>
                    <v-text-field dense outlined v-model="otherWork" :label="$t('fields.otherWork')" v-if="includesOtherWork"></v-text-field>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { races, ethnicities, educationLevels, incomeLevels, typesOfWork } from '@/common';
    import { includes, isArray } from 'lodash';
    import { sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';

    export default {
        mixins: [FamilyMemberMixin],
        data: () => ({
            races: races.map((item) => ({ value: item })),
            ethnicities: ethnicities.map((item) => ({ value: item })),
            educationLevels: educationLevels.map((item) => ({ value: item })),
            incomeLevels: incomeLevels.map((item) => ({ value: item })),
            typesOfWork: typesOfWork.map((item) => ({ value: item }))
        }),
        computed: {
            required() {
                return this.$store.get('demographicsRequired', this.uuid);
            },
            isValid() {
                return this.$store.get('isDemographicsValid', this.uuid);
            },
            race: sync('race'),
            ethnicity: sync('ethnicity'),
            birthplace: sync('birthplace'),
            residenceYouth: sync('residenceYouth'),
            residenceLast15Years: sync('residenceLast15Years'),
            education: sync('education'),
            income: sync('income'),
            work: sync('work'),
            otherWork: sync('otherWork'),
            includesOtherWork() {
                return includes(this.work, 'Other');
            }
        },
        methods: {
            isArray,
            checkOtherWork() {
                if (!this.includesOtherWork) {
                    this.otherWork = '';
                }
            }
        }
    };
</script>
