<template>
    <v-card flat>
        <v-expansion-panels v-model="panels">
            <ConditionsScreening :uuid="uuid" icon="fas fa-ribbon fa-fw" label="labels.cancer" :conditions="cancerScreenConditions" property="cancerScreenConditions" is-valid-mutation="isCancerValid"></ConditionsScreening>
            <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-3">
                    <div class="d-flex justify-space-between">
                        <div class="text-h6 d-flex align-center">
                            <v-icon small left>fas fa-heartbeat fa-fw</v-icon>
                            {{ $t('labels.cardiovascular') }}
                        </div>
                        <v-icon small class="mx-2 secondary--text" v-if="isCardiovascularValid()">fas fa-check</v-icon>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 accent">
                    <v-expansion-panels v-model="cardiovascularPanels">
                        <Metrics :uuid="uuid"></Metrics>
                        <Labs :uuid="uuid"></Labs>
                        <Diet :uuid="uuid"></Diet>
                        <Exercise :uuid="uuid"></Exercise>
                    </v-expansion-panels>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <ConditionsScreening
                :uuid="uuid"
                icon="fas fa-baby fa-fw"
                label="labels.pregnancy"
                :conditions="pregnancyScreenConditions"
                property="pregnancyScreenConditions"
                is-valid-mutation="isPregnancyValid"
                v-if="'female' === gender"
            ></ConditionsScreening>
        </v-expansion-panels>
        <div class="my-6 d-flex justify-center" v-if="'patient' === userType">
            <v-btn x-large to="/report" color="primary" :disabled="!allFamilyMembersValid" v-if="!studyLocked">
                {{ $t('common.continue') }}
                <v-icon right>fas fa-arrow-right</v-icon>
            </v-btn>
        </div>
        <Tour :steps="tourSteps"></Tour>
    </v-card>
</template>

<script>
    import ConditionsScreening from '@/components/ConditionsScreening';
    import Diet from '@/components/Diet';
    import Exercise from '@/components/Exercise';
    import Labs from '@/components/Labs';
    import Metrics from '@/components/Metrics';
    import { cancerScreenConditions, pregnancyScreenConditions } from '@/common';
    import { get } from 'vuex-pathify';

    export default {
        name: 'Risk',
        components: { ConditionsScreening, Diet, Exercise, Labs, Metrics },
        data: () => ({
            panels: null,
            cardiovascularPanels: null,
            cancerScreenConditions,
            pregnancyScreenConditions
        }),
        computed: {
            studyLocked: get('patient/studyLocked'),
            userType: get('userType'),
            uuid: get('family@0.uuid'),
            gender: get('family@0.gender'),
            allFamilyMembersValid: get('allFamilyMembersValid'),
            tourSteps() {
                return [
                    { text: this.$i18n.t('tour.risk-intro') }
                ];
            }
        },
        methods: {
            isCardiovascularValid() {
                return this.$store.get('isCardiovascularValid', this.uuid);
            }
        }
    };
</script>
